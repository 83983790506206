import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./TeensFeedback.css";
import { Typography } from "@mui/material";
import waves from "../../Assets/Images/More/waves.png";
import guest from "../../Assets/Images/teensfeedback/guest1.png";
import star from "../../Assets/Images/teensfeedback/start.png";
import teensBanner from "../../Assets/Images/teensfeedback/teens-banner.png";
import teensBannerSm from "../../Assets/Images/teensfeedback/teens-banner-sm.png";
import whyteens1 from "../../Assets/Images/teensfeedback/whyteens1.png";
import whyteens2 from "../../Assets/Images/teensfeedback/whyteens2.png";
import whyteens3 from "../../Assets/Images/teensfeedback/whyteens3.png";
import whyteens4 from "../../Assets/Images/teensfeedback/whyteens4.png";
import whyteens5 from "../../Assets/Images/teensfeedback/whyteens5.png";
import whyteens6 from "../../Assets/Images/teensfeedback/whyteens6.png";
import whyteens7 from "../../Assets/Images/teensfeedback/whyteens7.png";
import whyteens8 from "../../Assets/Images/teensfeedback/whyteens8.png";
import whyteens9 from "../../Assets/Images/teensfeedback/whyteens9.png";
import whyteens10 from "../../Assets/Images/teensfeedback/whyteens10.png";
import invertedCommas from "../../Assets/Images/teensfeedback/invertedCommas.png";
import invertedCommas2 from "../../Assets/Images/teensfeedback/invertedCommas2.png";
import Slider from "react-slick";
import element2 from "../../Assets/Images/home/element4.png";
import element1 from "../../Assets/Images/home/element2.png";

// import "../Home/Home2";
import "../Home/Animation.css";
import { Reveal, Tween, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const TeensFeedback = () => {
  var settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    // autoplaySpeed:0,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settings1 = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 8000,
    autoplaySpeed: 0,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
  };

  var settings2 = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 8000,
    autoplaySpeed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
  };

  var settings3 = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 8000,
    autoplaySpeed: 450,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
  };
  let settings4 = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 8000,
    autoplaySpeed: 150,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function animateFrom(elem, direction) {
    // console.log("Animation Effects");
    direction = direction || 1;
    var x = 0,
      y = direction * 100;
    if (elem.classList.contains("gs_reveal_fromLeft")) {
      x = -100;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
      x = 100;
      y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 5,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }

  useEffect((elem) => {
    // window.scrollTo(0, 0);
    // gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view
      ScrollTrigger.create({
        trigger: elem,
        once: true,
        onEnter: function () {
          animateFrom(elem);
        },
      });
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Teen Feedback | GenWE</title>
      </Helmet>
      <section className="header">
        <div className="container">
          <Header />
        </div>
      </section>

      <section className="learn_together  feedback ">
        <div className="container p-sm-0 my-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="   title  custom_nopadding ampersand">
                {/* <Typography align="left" variant="p" className="feedback" p={0} m={0}> */}
                Teens Feeedback
                {/* </Typography> */}
              </div>
            </div>
            <div className="col-lg-12 text-center">
              <div className=" text-center title display_flex custom_responsive custom_nopadding">
                <img className="img-fluid  waves " src={waves} alt="" />
                <div>
                  <div className="feedback">What we are
                    learning together on <span className="Gen custom_gen"> Gen</span>
                    <span className="we custom_we">WE</span>
                  </div>
                </div>
                <img
                  className="img-fluid  waves d-xs-none d-sm-block"
                  src={waves}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="  banner-image-lg justify-content-center gs_reveal gs_reveal_fromLeft">
            <img src={teensBanner} alt="" width={900} />
          </div>
          <div className="  banner-image-sm justify-content-center gs_reveal gs_reveal_fromLeft">
            <img src={teensBannerSm} alt="" width={600} />
          </div>
        </div>
      </section>

      <section className="">
        <div className="container my-3">
          <div className="row">
            <div className="col-lg-12 text-center my-5">
              <div className=" text-center title display_flex custom_responsive custom_nopadding mb-4 ampersand">
                Guest Speaker Feedback
              </div>
            </div>
            <div className="guest_feedback p-md-5 p-xs-2 gs_reveal gs_reveal_fromLeft">
              <div className="feedback-element">
                <img src={element1} alt="feedback-element" height={140} />
              </div>
              <div className="inverted-top-element">
                <img src={invertedCommas} alt="element" height={60} />
              </div>
              <div className="inverted-bottom-element">
                <img src={invertedCommas2} alt="element" height={60} />
              </div>

              <div className="d-lg-flex p-md-3">
                <div className="guest-img mb-5">
                  <img
                    src={guest}
                    alt="guest img"
                    srcset=""
                    height={"100%"}
                    width={"100%"}
                    className="d-flex justify-content-center"
                  />
                </div>
                <div className="guest-details mx-lg-4 p-lg-3">
                  <div>
                    <Typography
                      variant="p"
                      fontSize={{ xs: 20, md: 27 }}
                      fontWeight={500}
                      textAlign={{ xs: "center", md: "left" }}
                    >
                      How to free yourself from being stuck in your ways by
                      seeking discomfort
                    </Typography>
                  </div>
                  <div className="my-4">
                    <Typography
                      variant="p"
                      fontSize={{ xs: 20, md: 27 }}
                      fontWeight={600}
                      fontStyle={"italic"}
                    >
                      - Oliver Schutt, 19 years old,
                      <br /> NYU student, TEDx speaker and music producer
                    </Typography>
                  </div>
                  <div className="star my-4">
                    {[0, 1, 2, 3, 4].map((dt, id) => (
                      <span className="mx-1" key={id}>
                        <img src={star} alt="" />
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="comments">
                <Typography variant="h4">Comments</Typography>
                <div style={{ position: "relative" }} className="">
                  <Slider {...settings} speed={5000} autoplay={false}>
                    {comments.map((ct, id) => (
                      <div
                        key={id}
                        className="col-md-4 col-sm-12 p-3 px-4"
                        style={{ position: "relative", width: "250px" }}
                      >
                        <div
                          className="what_is_genwe p-4"
                          style={{ background: `${ct.bgColor}` }}
                        >
                          <div className="py-4">
                            <Typography
                              sx={{
                                fontSize: "1.05rem",
                                letterSpacing: "1px",
                                fontWeight: "600",
                                fontFamily: "Montserrat",
                                // width: { lg: "80%" },
                              }}
                            >
                              {ct.msg}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                  <div className="comment-element">
                    <img src={element2} alt="comment-element" height={80} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div
            className="col-lg-12 col-sm-12 col-md-12 col-xl-12 order_2"
            id="tab_view_section_4"
          >
            <div className=" text-center-mobile Sub_title display_flex">
              <div>
                <div className="feedback">
                  Why Teens Are Joining{" "}
                  <span className="Gen custom_gen"> Gen</span>
                  <span className="we custom_we">WE</span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-evenly align-items-center flex-wrap why_teens">
              <div className="mx-1">
                <img src={whyteens1} alt="" srcset="" />
              </div>
              <div className="mx-1">
                <img src={whyteens2} alt="" srcset="" />
              </div>
              <div className="mx-1">
                <img src={whyteens3} alt="" srcset="" />
              </div>
              <div className="mx-1">
                <img src={whyteens4} alt="" srcset="" />
              </div>
              <div className="mx-1">
                <img src={whyteens5} alt="" srcset="" />
              </div>
              <div className="mx-1">
                <img src={whyteens6} alt="" srcset="" />
              </div>
              <div className="mx-1">
                <img src={whyteens7} alt="" srcset="" />
              </div>
              <div className="mx-1">
                <img src={whyteens8} alt="" srcset="" />
              </div>
              <div className="mx-1">
                <img src={whyteens9} alt="" srcset="" />
              </div>
              <div className="mx-1">
                <img src={whyteens10} alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="my-5">
        <div className="container-md">
          <div
            className="col-lg-12 col-sm-12 col-md-12 col-xl-12 order_2 difference-section"
            id="tab_view_section_4"
          >
            <div
              className=" text-center-mobile Sub_title display_flex feedback"
              style={{ width: "80%" }}
            >
              <div>
                <div className="feedback">
                  The Difference Between{" "}
                  <span className="Gen custom_gen"> Gen</span>
                  <span className="we custom_we">WE</span> And Other Apps
                </div>
              </div>
            </div>

            <div className=" scroll-cards-lg">
              <div className="  diff-col py-lg-5">
                <Slider {...settings1} >
                  <div>
                    <div
                      className="diff-card "
                      style={{ backgroundColor: "#BEA8FF80" }}
                    >
                      “GenWE connects you with people who are similar yet different to you GenWE actually allows you to connect with new people in a safe and non judgmental environment."
                    </div>
                  </div>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#FF718380" }}
                    >
                      “GenWE helps us to be comfortable with others and help us
                      to learn many more things. Other social media apps are not
                      like that they are just for our entertainment purpose.”
                    </div>
                  </div>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#FFDCCF80" }}
                    >
                      “Here you can connect with same age group people
                      especially to share your knowledge and skills whereas it
                      is not included in other social media apps.”
                    </div>
                  </div>
                </Slider>
              </div>
              <div className=" diff-col py-lg-5">
                <Slider {...settings2}>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#AFFAFF80" }}
                    >
                      GenWE allows me to be my authentic self. It’s not just a
                      highlight reel of my life."
                    </div>
                  </div>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#FFC38C80" }}
                    >
                      “The constant need for validation, that you try acting
                      like someone u aren't but that isn't the case of GenWE.”
                    </div>
                  </div>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#F6FF9480" }}
                    >
                      “GenWE helps to learn life values from other people and
                      bring the world closer in a positive way.”
                    </div>
                  </div>
                </Slider>
              </div>
              <div className=" diff-col py-lg-5">
                <Slider {...settings3}>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#DFB7FF80" }}
                    >
                      “Every minute spent on GenWe helps me grow as an
                      individual whereas other social media platforms are time
                      killers most of the time.”
                    </div>
                  </div>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#FFBCFC80" }}
                    >
                      “GenWe is more productive than other platforms.”
                    </div>
                  </div>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#A8FFE580" }}
                    >
                      “It provides various activities for you to do yourself and
                      with your pair to make it more interactive.”
                    </div>
                  </div>
                </Slider>
              </div>
            </div>

            <div className="scroll-cards-sm">
              <div className="  diff-row py-lg-5">
                <Slider {...settings4}>
                  <div>
                    <div
                      className="diff-card "
                      style={{ backgroundColor: "#BEA8FF80" }}
                    >
                      “GenWE connects you with people who are similar yet different to you GenWE actually allows you to connect with new people in a safe and non judgmental environment.”
                    </div>
                  </div>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#FF718380" }}
                    >
                      “GenWE helps us to be comfortable with others and help us
                      to learn many more things. Other social media apps are not
                      like that they are just for our entertainment purpose.”
                    </div>
                  </div>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#FFDCCF80" }}
                    >
                      “Here you can connect with same age group people
                      especially to share your knowledge and skills whereas it
                      is not included in other social media apps.”
                    </div>
                  </div>
                </Slider>
              </div>
              <div className=" diff-row py-lg-5">
                <Slider {...settings4} autoplaySpeed={50}>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#AFFAFF80" }}
                    >
                      GenWE allows me to be my authentic self. It’s not just a
                      highlight reel of my life."
                    </div>
                  </div>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#FFC38C80" }}
                    >
                      “The constant need for validation, that you try acting
                      like someone u aren't but that isn't the case of GenWE.”
                    </div>
                  </div>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#F6FF9480" }}
                    >
                      “GenWE helps to learn life values from other people and
                      bring the world closer in a positive way.”
                    </div>
                  </div>
                </Slider>
              </div>
              <div className=" diff-row py-lg-5">
                <Slider {...settings4} autoplaySpeed={100}>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#DFB7FF80" }}
                    >
                      “Every minute spent on GenWe helps me grow as an
                      individual whereas other social media platforms are time
                      killers most of the time.”
                    </div>
                  </div>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#FFBCFC80" }}
                    >
                      “GenWe is more productive than other platforms.”
                    </div>
                  </div>
                  <div>
                    <div
                      className="diff-card"
                      style={{ backgroundColor: "#A8FFE580" }}
                    >
                      “It provides various activities for you to do yourself and
                      with your pair to make it more interactive.”
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </>
  );
};

export default TeensFeedback;

const comments = [
  {
    msg: "Amazing session. Talking to a student is very relatable and really helps",
    bgColor: "#F2F0FF",
  },
  {
    msg: "I loved the session, not only was it very helpful, it was also effective coming from someone closer to our age. Looking forward to more sessions like these :)",
    bgColor: "#FDFFF1",
  },
  {
    msg: "I enjoyed learning about how to change and simplify my life. ",
    bgColor: "#E8FEF6",
  },
  {
    msg: "It was a really good session. I could reflect upon how I could bring about change in my life and make it better. I could ponder over my discomforts and I have decided to implement the idea of journaling.",
    bgColor: "#F2F0FF",
  },
  {
    msg: "I loved the ways which was suggested to overcome my anxiety and seek discomfort when stuck at any point.",
    bgColor: "#FDFFF1",
  },
];
