import React, { useState, useEffect, useRef } from "react";
// @import "~video-react/styles/scss/video-react";
// import "/video-react/dist/video-react.css"; // import css
import { Player } from "video-react";
import "react-awesome-slider/dist/styles.css";
import PosterImage from "../Assets/Images/poster.png";
import playIcon from "../Assets/Images/play.png";
import ReactPlayer from "react-player";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircle";
// import PlayCircleIcon from '@mui/icons-material/PlayCircle';

function Video() {
  return (
    <>
      {/* <PlayCircleOutlineIcon /> */}
      <ReactPlayer
        // url="https://www.youtube.com/embed/YVkYf8aQv2k?rel=0&autoplay=1"
        url="https://genweappassets.s3.ap-south-1.amazonaws.com/GenWE+Introduction.mp4"
        width="100%"
        height="300px"
        playing
        playIcon={<PlayCircleOutlineIcon height={400} />}
        light={PosterImage}
        controls={true}
        className="custom_Iframe"
      />
    </>
  );
}

export default Video;
