import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Licence.css";
import { Link } from "react-router-dom";
import pdf from "../../Assets/Images/Footer/parental-consent-form.pdf";

const DeskTopLicenceAgreement = () => {
  return (
    <div>
      <div className="container MainTagSpace">
        <div>
          <Header />
          <div className="container">
            <div className="row">
              <div className=" col-lg-12 pt-4">
                <div className="pt-4">
                  {" "}
                  <h2> END USER LICENSE AGREEMENT/TERMS OF USE</h2>{" "}
                </div>
                <div className="py-2">
                  IMPORTANT— PLEASE READ THESE TERMS OF USE (“AGREEMENT”)
                  CAREFULLY BEFORE USING THE SERVICES OFFERED BY GenWE INC.
                  (“GenWE” OR “WE” OR “US”). THIS IS A BINDING AGREEMENT BETWEEN
                  YOU AND GenWE. BY ACCESSING OR USING THE SERVICES IN ANY
                  MANNER, YOU AGREE THAT YOU HAVE READ AND AGREE TO BE BOUND BY
                  AND A PARTY TO THE TERMS AND CONDITIONS OF THIS AGREEMENT TO
                  THE EXCLUSION OF ALL OTHER TERMS. IF THE TERMS OF THIS
                  AGREEMENT ARE CONSIDERED AN OFFER, ACCEPTANCE IS EXPRESSLY
                  LIMITED TO SUCH TERMS. IF YOU DO NOT UNCONDITIONALLY AGREE TO
                  ALL THE TERMS AND CONDITIONS OF THE AGREEMENT, YOU MAY NOT USE
                  THE SERVICES.{" "}
                </div>
                <div className="py-2"> What is this saying? </div>
                <div className="py-2">
                  {" "}
                  By using GenWE services, you represent, acknowledge and agree
                  that you are at least 18 years of age. If you are between 13
                  and 18 years, read the Privacy Policy and Terms of Service
                  with your parents or legal guardian and agree before use.
                  Where requested by us, ask them to provide their consent to
                  use the services at friends@genwe.today.
                  <br />
                  <br />
                  If you are not at least 13 years old, you may not use GenWE
                  services at any time or in any manner or submit any
                  information to the GenWE via its website.{" "}
                </div>
                <div className="py-2"> Will these terms ever change? </div>
                <div className="py-2">
                  We constantly try to improve our Services, so we may update
                  this Agreement as we offer new and improved Services. We may
                  modify this Agreement at any time, but we will do our best to
                  bring this to your attention by posting a notice on the
                  Services or notifying you by email or by some other means. If
                  you don’t agree with the new terms, you may reject them but
                  unfortunately you will no longer be able to use the Services.
                  Your use of the Services in any way following notification by
                  GenWE constitutes your acceptance of the terms and conditions
                  as modified. We may suspend or discontinue any part of the
                  Services, or we may introduce new features, impose limits on
                  certain features or restrict access to parts or all of the
                  Services at any time. We’ll do our best to provide you notice
                  when we make a material change to the Services that would
                  adversely affect you.
                </div>
                <div className="py-2">
                  {" "}
                  You represent and warrant to GenWE that:{" "}
                </div>
                <div className="py-2">
                  1. If you are the child entering into this agreement then you
                  are at least 18 years old and if not, you represent and
                  warrant that you are the parent of the child registering your
                  child for the programme or a lawful guardian having authority
                  to enter into this contract; 2. all registration information
                  you submit is accurate and truthful; 3. you will maintain the
                  accuracy of such information; 4. if you’re agreeing to this
                  Agreement on behalf of an organization or entity, you
                  represent and warrant that you are authorized to agree to this
                  Agreement on the organization or entity’s behalf and bind them
                  to this Agreement (in which case, the references to “you” and
                  “your” in this clause refers to that organization or entity.
                  GenWE acknowledges that in the event an organisation executes
                  this Agreement, it is only representing and warranting that it
                  has the agreement of its employees/parents or adult teenagers
                  to be bound by the terms and conditions of this Agreement that
                  otherwise apply to them and that any representations,
                  warranties, covenants, liabilities, and all other terms and
                  conditions are applicable to such parent/adult teenager. The
                  organisation is simply a facilitating party). You also certify
                  that you are legally permitted to use and access the Services
                  and take full responsibility for the selection, use of, and
                  access to the Services, including ensuring that you have
                  permission to communicate with anyone you choose to interact
                  with through the Services. This Agreement is void where
                  prohibited by law, and the right to access the Services is
                  revoked in such jurisdictions.
                </div>
                <div className="py-2"> What is this saying? </div>
                <div className="py-2">
                  {" "}
                  We work hard to improve the GenWE experience for you, so our
                  terms may change from time to time. We will update you when we
                  make significant changes. Before continuing to use GenWE, it
                  will be important to review and agree to any changes. Fees and
                  payment; Responsibility for usage charges
                  <br />
                  Through GenWE, you may be able to access and use products or
                  services operated by other individuals or companies who are
                  not employed or controlled by GenWE (such as, a payment
                  platform to request and/or make payments). If you use any of
                  these products or services, you must review and agree to the
                  additional terms and conditions and privacy policy, as well as
                  pay any applicable fees required to use those products. Any
                  information you agree to provide will be governed by that
                  business’ privacy policy. Note that, by using the Services,
                  you may receive e-mail or text messages on your phone or
                  mobile device, which may cause you to incur usage charges or
                  other fees or costs in accordance with your wireless or data
                  service plan. Any and all such charges, fees, or costs are
                  your sole responsibility. You should consult with your
                  wireless carrier to determine what rates, charges, fees, or
                  costs may apply to your use of the Services.{" "}
                </div>
                <div className="py-2"> Registration and security </div>
                <div className="py-2">
                  {" "}
                  As a condition to using Services, you may be required to
                  register with GenWE by selecting a password and providing your
                  name and a valid email address. You will provide GenWE with
                  accurate, complete, and updated registration information. You
                  may not:
                  <br />
                  <br />
                  Select or use the name or email of another person with the
                  intent to impersonate that person; or
                  <br />
                  Use a name subject to any rights of any person other than you
                  without appropriate authorization.
                  <br />
                  GenWE reserves the right to refuse registration of or to
                  cancel a GenWE account in its sole discretion.
                  <br />
                  You will be responsible for maintaining the confidentiality of
                  your account and password. You may not transfer your account
                  to anyone without express prior written consent of GenWE.
                  Violation of any of the foregoing may result in immediate
                  termination of your account, revocation of your ability to use
                  the Services, and may subject you to state and federal
                  penalties and other legal consequences. GenWE reserves the
                  right, but will have no obligation, to investigate your use of
                  the Services in order to determine whether a violation of the
                  Agreement has occurred or to comply with any applicable law,
                  regulation, legal process or governmental request. What is
                  this saying? The security of your account is very important to
                  us. Please keep your password safe, use your real name and
                  create your own account before using GenWE.
                </div>
                <div className="py-2">
                  {" "}
                  <b>User Content</b>{" "}
                </div>
                <div className="py-2">
                  Some areas of the Service may, either now or later, allow
                  Users to post content such as chat content, profile
                  Information, videos, comments, questions, and other content or
                  Information or any such materials a User submits, posts,
                  displays, or otherwise makes available on the Service ("
                  <b>User Content</b>"). We claim no ownership rights over User
                  Content created by You; the User Content You create remains
                  Yours. However, by sharing User Content through the Service,
                  You agree to allow Us, authorised Institutions or other
                  authorised persons (in accordance with your settings and this
                  Agreement) to view, edit, and/or share Your User Content.
                  GenWE has the right (but not the obligation) in its sole
                  discretion to remove any User Content that is shared via the
                  Service.
                  <br />
                  <br />
                  You agree not to post or transmit User Content that:
                  <ol style={{ listStyleType: "upper-roman" }}>
                    <li>
                      may create a risk of harm, loss, physical or mental
                      injury, emotional distress, death, disability,
                      disfigurement, or physical or mental illness to You, to
                      any other person, or to any animal;
                    </li>
                    <li>
                      may create a risk or any other loss or damage to any
                      person or property;
                    </li>
                    <li>
                      seeks to harm or exploit children by exposing them to
                      inappropriate content, asking for personally identifiable
                      details or otherwise;
                    </li>
                    <li>may constitute or contribute to a crime or tort;</li>
                    <li>
                      contains any Information or content that we deem to be
                      unlawful, harmful, abusive, racially or ethnically
                      offensive, defamatory, infringing, invasive of personal
                      privacy or publicity rights, harassing, humiliating to
                      other people (publicly or otherwise), libelous,
                      threatening, profane, or otherwise objectionable;
                    </li>
                    <li>
                      contains any Information or content that is illegal
                      (including, without limitation, the disclosure of insider
                      Information under securities law or of another party's
                      trade secrets);
                    </li>
                    <li>
                      contains any Information or content that You do not have a
                      right to make available under any law or under contractual
                      or fiduciary relationships;
                    </li>
                    <li>
                      contains any Information or content that You know is not
                      correct and current;
                    </li>
                    <li>
                      violates any school, Institution or other applicable
                      policy, including those related to cheating or ethics;
                    </li>
                    <li>
                      interferes with other Users of the Service including,
                      without limitation, disrupting the normal flow of dialogue
                      in an interactive area of the Service and deleting or
                      revising any content posted by another person or entity;
                    </li>
                    <li>
                      except where expressly permitted, post or transmit charity
                      requests, petitions for signatures, franchise
                      arrangements, distributorship arrangements, sales
                      representative agency arrangements or other business
                      opportunities (including offers of employment or
                      contracting arrangements), club memberships, chain letters
                      or letters relating to pyramid schemes, any advertising or
                      promotional materials, solicit Service letters or
                      certificates, or any other solicitation of other users to
                      use goods or services except in those areas (e.g., a
                      classified bulletin board) that are designated for such
                      purpose.
                    </li>
                  </ol>
                </div>
                <div className="py-2">
                  {" "}
                  <b>Copyrights and Intellectual Property Rights</b>{" "}
                </div>
                <div className="py-2">
                  You may not copy, share, or use personal identifying or
                  business contact Information about other Users without their
                  express permission. You agree that any User Content that You
                  post does not and will not violate third-party rights of any
                  kind, including without limitation any Intellectual Property
                  Rights (as defined below) or rights of privacy. GenWE reserves
                  the right, but is not obligated, to reject and/or remove any
                  User Content that GenWE believes, in its sole discretion,
                  violates these provisions.
                  <br />
                  <br />
                  For the purposes of this Agreement, "
                  <b>Intellectual Property Rights</b>" means all patent rights,
                  copyright rights, mask work rights, moral rights, rights of
                  publicity, trademark, trade dress and service mark rights,
                  goodwill, trade secret rights and other intellectual property
                  rights as may now exist or hereafter come into existence, and
                  all applications therefore and registrations, renewals and
                  extensions thereof, under the laws of any state, country,
                  territory or other jurisdiction.
                  <br />
                  <br />
                  In order to honor and protect the GenWE intellectual
                  properties, You expressly agree not to disclose or communicate
                  any proprietary Information about the GenWE practice,
                  materials, or methods to any third parties. The obligations
                  under this Clause survive the termination of the GenWE Service
                  or Your relationship with GenWE;
                  <br />
                  <br />
                  In connection with your User Content, You affirm, represent
                  and warrant the following:
                  <ul>
                    <li>
                      You have the consent of each and every identifiable
                      natural person in the User Content to use such a person's
                      name or likeness, and each such person has released You
                      from any liability that may arise in relation to such use
                      of such User Content.
                    </li>
                    <li>
                      Your User Content and GenWE's use thereof as contemplated
                      by this Agreement and the Service will not violate any law
                      or infringe any rights of any third-party, including but
                      not limited to any Intellectual Property Rights and
                      privacy rights. GenWE takes no responsibility and assumes
                      no liability for any User Content that You or any other
                      User or third-party posts or sends over the Service. You
                      shall be solely responsible for Your User Content and the
                      consequences of posting or publishing it, and You agree
                      that We are only acting as a passive conduit for Your
                      online distribution and publication of Your User Content.
                      You understand and agree that in spite of Our best efforts
                      You may be exposed to User Content that is inaccurate,
                      objectionable, inappropriate for children, or otherwise
                      unsuited to Your purpose, and You agree that GenWE shall
                      not be liable for any damages or injury or losses You
                      allege to incur as a result of User Content.
                    </li>
                  </ul>
                </div>

                <div className="py-2">
                  <b>User Content License Grant</b>
                </div>

                <div className="py-2">
                  By posting any User Content on the Service, for use in
                  connection with the Service You expressly grant, and You
                  represent and warrant that You have all rights necessary to
                  grant, to GenWE a royalty-free, sublicensable, transferable,
                  perpetual, irrevocable, non-exclusive, worldwide license to
                  use, reproduce, modify, publish, list Information regarding,
                  edit, translate, distribute, syndicate, publicly perform,
                  publicly display, and make derivative works of all such User
                  Content and Your name, voice, and/or likeness as contained in
                  Your User Content, in whole or in part, and in any form, media
                  or technology, whether now known or hereafter developed
                  subject to the Privacy Policy.
                </div>
                <div className="py-2">
                  {" "}
                  <b>Use of User Content</b>{" "}
                </div>
                <div className="py-2">
                  GenWE conducts research on Our global platform. All data
                  including User Content if any is anonymized before use for
                  research purposes. This research looks at how different
                  activities on Mobile Software impact mental well-being
                  measures. GenWE may use User Content and other data collected
                  from the User in accordance with the Agreement for the purpose
                  of this research. Research and outcomes are key to helping
                  Users make real change and progress in their lives, and we are
                  grateful for the support You provide towards this mission by
                  sharing Your data.
                </div>
                <div className="py-2">
                  {" "}
                  Our restrictions and responsibilities{" "}
                </div>
                <div className="py-2">
                  You warrant, represent and agree that you will not contribute
                  any Content or otherwise use (or encourage anyone else to use)
                  the Services in a manner that infringes or violates the
                  intellectual property rights or proprietary rights, rights of
                  publicity or privacy, or other rights of any other third
                  party; violates any law, statute, ordinance or regulation; is
                  harmful, fraudulent, threatening, abusive, harassing,
                  tortuous, defamatory, vulgar, obscene, libellous, or otherwise
                  objectionable as reasonably determined by GenWE; jeopardizes
                  the security of your account in any way, such as allowing
                  someone else access to your account or password; attempts, in
                  any manner, to obtain the password, account, or other security
                  information from any other user; violates the security of any
                  computer network, or cracks any passwords or security
                  encryption codes; runs Maillots, Listserv, any form of
                  auto-responder or “spam” on the Services, or any processes
                  that run or are activated while you are not logged into the
                  Services, or that otherwise interfere with the proper working
                  of the Services (including by placing an unreasonable load on
                  the Services’ infrastructure); “crawls,” “scrapes,” or
                  “spiders” any page, data, or portion of or relating to the
                  Services or Content (through use of manual or automated
                  means); copies or stores any significant portion of the
                  Content; decompiles, reverse engineers, or otherwise attempts
                  to obtain the source code or underlying ideas or information
                  of or relating to the Services (or the products of GenWE’s
                  third party service providers).
                  <br />
                  <br />
                  You warrant, represent and agree that you will not contribute
                  any Content or otherwise use (or encourage anyone else to use)
                  the Services in a manner that infringes or violates the
                  intellectual property rights or proprietary rights, rights of
                  publicity or privacy, or other rights of any other third
                  party; violates any law, statute, ordinance or regulation; is
                  harmful, fraudulent, threatening, abusive, harassing,
                  tortuous, defamatory, vulgar, obscene, libellous, or otherwise
                  objectionable as reasonably determined by GenWE; jeopardizes
                  the security of your account in any way, such as allowing
                  someone else access to your account or password; attempts, in
                  any manner, to obtain the password, account, or other security
                  information from any other user; violates the security of any
                  computer network, or cracks any passwords or security
                  encryption codes; runs Maillots, Listserv, any form of
                  auto-responder or “spam” on the Services, or any processes
                  that run or are activated while you are not logged into the
                  Services, or that otherwise interfere with the proper working
                  of the Services (including by placing an unreasonable load on
                  the Services’ infrastructure); “crawls,” “scrapes,” or
                  “spiders” any page, data, or portion of or relating to the
                  Services or Content (through use of manual or automated
                  means); copies or stores any significant portion of the
                  Content; decompiles, reverse engineers, or otherwise attempts
                  to obtain the source code or underlying ideas or information
                  of or relating to the Services (or the products of GenWE’s
                  third party service providers).
                  <br />
                  <br />
                  GenWE reserves the right to terminate access of any
                  third-party application or service to our published or
                  unpublished APIs. GenWE reserves the right (but does not have
                  the obligation) to remove any Content from the Services at any
                  time, for any reason (including, but not limited to, if we
                  receive claims or allegations from individuals, companies or
                  authorities relating to that Content or if GenWE is concerned
                  that you may have breached the foregoing restrictions), or for
                  no reason at all.
                  <br />
                  <br />
                  You, not GenWE, remain solely responsible for all Content that
                  you upload, post, email, transmit, or otherwise provide using,
                  or in connection with, the Services, and you warrant that you
                  possess all rights necessary to provide such Content to GenWE
                  and to grant GenWE the rights to use such information as
                  provided herein. You will be responsible for obtaining and
                  maintaining any equipment or ancillary services needed to
                  connect to, access, or otherwise use the Services, including,
                  without limitation, modems, hardware, software, and long
                  distance or local telephone service. You will be responsible
                  for ensuring that such equipment or ancillary services are
                  compatible with the Services. You will be responsible for
                  withholding, filing, and reporting all taxes, duties and other
                  governmental assessments associated with your activity in
                  connection with the Services.
                </div>
                <div className="py-2"> What is this saying? </div>
                <div className="py-2">
                  {" "}
                  We are serious about safety. If you don’t follow our
                  Guidelines or these Terms of Use, we may need to remove any
                  inappropriate content or deactivate your account. If you see
                  someone violating our Guidelines or Terms of Use, please
                  contact us immediately at friends@genwe.today We need your
                  help to keep the GenWE community safe!{" "}
                </div>
                <div className="py-2"> Data download and security </div>
                <div className="py-2">
                  {" "}
                  You, based on your choice and permission from the other party
                  on the session, shall be allowed to download the data on your
                  systems for future reference and records for yourself.
                  However, you are not allowed to use such data for any public
                  use. Such data shall not be published or used on any
                  third-party portal or applications or on any offline medium by
                  you or anyone else. If such data is found to be released to
                  public than it shall be your responsibility and GenWE shall
                  not be held liable for the same. However, GenWE reserves the
                  right to delete your account, if you are found to be breaching
                  any of the GenWE’s policies. This clause shall be read with
                  the GenWE’s Privacy Policy.{" "}
                </div>
                <div className="py-2"> Copyright dispute policy </div>
                <div className="py-2">
                  Under the Digital Millennium Copyright Act (the “DMCA”),
                  online service providers such as GenWE have the right, but not
                  the obligation, to remove material that allegedly violates
                  someone’s copyright. We respect others’ intellectual property
                  rights, and we reserve the right to delete or disable Content
                  alleged to be infringing, and to terminate the accounts of
                  repeat alleged infringers.
                </div>
                <div className="py-2"> Third party service providers </div>
                <div className="pt-2">
                  The Services may contain links to third party websites,
                  products or services that are not owned or controlled by GenWE
                  (“Third Party Services”). When you access Third Party
                  Services, you do so at your own risk. GenWE we encourage you
                  to be aware when you leave the Services and to read the terms
                  and conditions and privacy policy of each Third-Party Service
                  that you visit or use. While GenWE has no control over, it and
                  assumes no responsibility for, the content, accuracy, privacy
                  policies, or practices of or opinions expressed in any
                  Third-Party Services. In addition, GenWE will monitor, verify
                  or censor and if possible edit the content of any Third-Party
                  Services so as to make it appropriate for the teenagers. If
                  there is a dispute between GenWE users, or between GenWE users
                  and any third party, GenWE shall facilitate resolution of such
                  disputes but GenWE is under no obligation to become involved.
                  In the event that you have a dispute with one or more other
                  users, you hereby release GenWE, its officers, employees,
                  agents, and successors in rights from claims, demands, and
                  damages (actual and consequential) of every kind or nature,
                  known or unknown, suspected or unsuspected, disclosed or
                  undisclosed, arising out of or in any way related to such
                  disputes and/or our service. For avoidance of doubt, such
                  release of GenWE’s liability is only in respect of disputes
                  between users and not between GenWE and users.
                </div>

                <div className="pt-2">
                  {" "}
                  <b>Termination</b>{" "}
                </div>
                <div className="pb-2">
                  We may, without prior notice, change the Service; stop
                  providing the Service or features of the Service, to You or to
                  Users generally; or create usage limits for the Service. We
                  may permanently or temporarily terminate or suspend Your
                  access to the Service without notice and liability for any
                  reason, including if in Our sole determination You violate any
                  provision of the Agreement, or for no reason. Upon termination
                  for any reason or no reason, You continue to be bound by the
                  Agreement. You are responsible for all the mobile data usage
                  resulting from the use of the Service. Consult Your mobile
                  operator concerning your plan, data rate charges and limits.
                </div>
                <div className="py-2">
                  <b>Indemnity</b>
                </div>
                <div className="py-2">
                  You agree to defend, indemnify and hold harmless GenWE and its
                  subsidiaries, employees, officers and directors, agents,
                  licensors, managers, and other affiliated companies, and their
                  employees, contractors, agents, officers and directors, from
                  and against any and all claims, damages, injury, obligations,
                  losses, liabilities, costs or debt, and expenses (including
                  but not limited to attorney's fees) arising from: (i) Your use
                  of and access to the Service, including any data or content
                  transmitted or received by You; (ii) Your violation of any
                  term of this Agreement, including without limitation Your
                  breach of any of the representations and warranties; (iii)
                  Your violation of any third-party right, including without
                  limitation any right of privacy or Intellectual Property
                  Rights; (iv) Your violation of any applicable law, rule or
                  regulation; (v) any claim or damages that arise as a result of
                  any of Your User Content or any that is submitted via Your
                  account; or (vi) any other party's access and use of the
                  Service with Your unique username, PIN, password or other
                  appropriate security code.
                  <br />
                  <br />
                  You are solely responsible for Your interactions with other
                  GenWE Users. We reserve the right, but have no obligation, to
                  monitor disputes between You and other Users. GenWE shall have
                  no liability for Your interactions with other Users, or for
                  any User's action or inaction.
                </div>
                <div className="py-2"> Choice of law and arbitration</div>
                <div className="pt-2 pb-4">
                  This Agreement will be governed by and construed in accordance
                  with the laws of the Delaware, USA without regard to the
                  conflict of laws provisions thereof, unless a state’s law’s
                  explicitly require that any legal disputes are governed by the
                  laws of that state. Any dispute arising from or relating to
                  the subject matter of this Agreement will be finally settled
                  in small claims court or by arbitration in USA or in a
                  location that is reasonably convenient for you and GenWE,
                  using the English language in accordance with the Arbitration
                  Rules and Procedures.
                </div>
                <div className="py-2"> Miscellaneous</div>
                <div className="py-2">
                  The failure of either party to exercise, in any respect, any
                  right provided for herein will not be deemed a waiver of any
                  further rights hereunder. GenWE will not be liable for any
                  failure to perform its obligations hereunder where such
                  failure results from any cause beyond GenWE’s reasonable
                  control, including, without limitation, mechanical, electronic
                  or communications failure or degradation. If any provision of
                  this Agreement is found to be unenforceable or invalid, that
                  provision will be limited or eliminated to the minimum extent
                  necessary so that this Agreement will otherwise remain in full
                  force and effect and enforceable. This Agreement is not
                  assignable, transferable or sub licensable by you except with
                  GenWE’s prior written consent. GenWE may transfer, assign or
                  delegate this Agreement and its rights and obligations without
                  consent. Both parties agree that this Agreement is the
                  complete and exclusive statement of the mutual understanding
                  of the parties and supersedes and cancels all previous written
                  and oral agreements, communications and other understandings
                  relating to the subject matter of this Agreement, and that all
                  modifications must be in a writing signed by both parties,
                  except as otherwise provided herein. This Agreement and any
                  subsequent versions of this Agreement posted to the Services
                  will be deemed a writing signed by both parties. No agency,
                  partnership, joint venture, or employment is created as a
                  result of this Agreement and you do not have any authority of
                  any kind to bind GenWE in any way whatsoever.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="FooterMargin">
        {" "}
        <Footer />
      </div>
    </div>
  );
};

export default DeskTopLicenceAgreement;
