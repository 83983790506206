import React, { useState, useEffect, useRef } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Licence.css"
import { motion } from "framer-motion";

import "./test.css";

const Disclaimer = () => {



  return (
    <div>
      <div className="container MainTagSpace">
        <div>
          {/* <Header /> */}
          <div className="container">
            <div className="row">
              <div className=" col-lg-12 pt-4">
                <div className="pt-4">
                  {" "}
                  <h2> DISCLAIMER POLICY</h2>{" "}
                </div>
                {/* <div className="py-2 h5">
                  <b>Website Disclaimer</b>
                </div> */}

                <div className="py-2">
                  The information provided by Service Provider (“Company”, “we”, “our”, “us”) is on GenWE Inc (the “Site”) is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site. Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided on the site. Your use of the site and your reliance on any information on the site is solely at your own risk.
                </div>


                <div className="py-2 h5">
                  <b>EXTERNAL LINKS DISCLAIMER</b>
                </div>


                <div className="py-2">
                  The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features. . We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.
                </div>



                <div className="py-2 h5">
                  <b>PROFESSIONAL DISCLAIMER</b>
                </div>



                <div className="py-2">
                  In no event, shall Site be liable for any special, incidental, indirect or consequential damages of any kind arising out of or in connection with the use of the articles or other material derived from the site or users, whether or not advised of the possibility of damage, and on any theory of liability.
                </div>

                <div className="py-2">
                  Descriptions of, or references to, products or publications does not imply endorsement of that product or publication. The Site is under continuous development and changes may be made in these video publications, Site and programs at any time.
                </div>
                <div className="py-2">
                  The Site cannot and does not contain legal advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of legal advice.<br />
                  Content published on GenWE’s applications and other portals is intended to be used and must be used for informational purposes only. It is very important to do your own analysis before making any decision based on your own personal circumstances. The use or reliance of any information contained on this site is solely at your own risk.
                </div>


                <div className="py-2 h5">
                  <b>AFFILIATES DISCLAIMER</b>

                </div>


                <div className="py-2">
                  The Site may contain links to affiliate websites, and we may receive an affiliate commission for any purchases or actions made by you on the affiliate websites using such links.
                </div>

                <div className=" py-2 h5">
                  <b>TESTIMONIALS DISCLAIMER</b>
                </div>


                <div className="py-2">
                  The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume that all users will have the same experiences. Your individual results may vary.<br />The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity, where the full testimonial contained extraneous information not relevant to the general public. The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions.
                </div>


                <div className="py-2 h5">
                  <b>ERRORS AND OMISSIONS DISCLAIMER</b>
                </div>

                <div className="py-2">
                  In no event, will Service, its related partnerships or corporations, or the partners, agents or employees thereof be liable to you or anyone else for any decision made or action taken in reliance on the information in this Site or for any consequential, special or similar damages, even if advised of the possibility of such damages.
                </div>
                <div className="py-2 h5">
                  <b>GUEST CONTRIBUTORS DISCLAIMER</b>
                </div>

                <div className="py-2">
                  This Site include content from guest contributors and any views or opinions expressed in such posts are personal and do not represent those of Service or any of its staff or affiliates unless explicitly stated.
                </div>

                {/* <div className="py-2 h5">
                  <b>Logos And Trademark Disclaimer</b>
                </div>
                <div className="py-2">
                  All logos and trademarks of third parties referenced on GenWE.today are the trademarks and logos of their respective owners. Any inclusion of such trademarks or logos does not imply or constitute any approval, endorsement or sponsorship of Service by such owners.
                </div> */}

                <div className="py-2 h5">
                  <b>CONTACT US</b>
                </div>

                <div className="py-2">
                  Should you have any feedback, comments, requests for technical support or other inquiries, please contact us by email:<b>friends@genwe.today</b>.
                </div>
                <div className="py-2">
                  The opinions or views expressed on GenWE Inc on social media platforms, including, but not limited to, blogs, Facebook and Twitter pages, represent the thoughts of individual users and online communities, and not necessarily those of GenWE Inc or any of its corporate partners, affiliates or any of their respective directors, officers, employees, staff or members of its respective board of directors. The opinions and views expressed on these pages do not in any way reflect the views of the site they are posted on, other sites affiliated with the site, the GenWE Inc staff involved with maintaining the site or any members of the site. By submitting content (defined as materials, advice, representations, opinions and views) to any of GenWE Inc’s sites or platforms, you understand and acknowledge that this information is available to the public, and that you are allowing GenWE Inc, and its member clubs, to have a permanent right to use your comments for GenWE Inc business purposes including but not limited to internal and external promotions. This could include using, republishing, reproducing, distributing, changing or displaying the comments across GenWE Inc brand products and other websites without restriction from time of submission in perpetuity except where a user has specifically requested its deletion.
                </div>

                <div className="py-2">
                  GenWE Inc:
                  <ul>
                    <li>Does not control or guarantee the accuracy, relevance, timeliness or completeness of information contained on a linked website. Content is provided “AS IS.”</li>
                    <li>Does not endorse the organizations sponsoring linked websites or the views they express or the products/services they offer.<br />Cannot and does not authorize the use of copyrighted materials contained in linked websites. Users must request such authorization from the sponsor of the linked website. You must obtain consent from every identifiable person who appears in your posted materials or post in a manner in which faces are blurred.</li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="FooterMargin">
        {" "}
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Disclaimer;
