import React, { useState, useEffect, useRef } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Licence.css"
import { motion } from "framer-motion";

import "./test.css";

const DexContestTermsAndCondition = () => {



 return (
  <div>
   <div className="container MainTagSpace">
    <div>
     <Header />
     <div className="container">
      <div className="row">
       <div className=" col-lg-12 pt-4">
        <div className="pt-4 text-center">
         {" "}
         <h2> Contest Terms of Use</h2>{" "}
        </div>


        <div className="py-2">
         <ol>
          <li className="text-short">These terms and conditions apply to all competitions, and prize draws, featured in any publications and online services that are organised by GenWE INC (“GenWE” OR “WE” OR “US”).</li><br />
          <li className="text-short">By entering the contest, you accept these terms and conditions together with any specific instructions and terms for such contest which may be mentioned in any electronic messages, or on the website, or communicated to you in any other way (“contest information. </li>
          <li className="text-short">The Contest is valid from ________________ until ______________.The Organizers may, in their absolute discretion, curtail or extend the Challenge Period, as they may deem necessary without any liability whatsoever.  </li>
          <li className="text-short">GenWE may cancel or amend any contest information, or these terms and conditions without prior notice. Any changes will be posted either within the contest information or these terms and conditions. </li>
          <li className="text-short">Where you require the details of your parent/guardian to be entered, GenWE may ask for proof of age and in all contest evidence to verify your identity at any time, and may use any channels and methods available to carry out checks of any details provided. You may only enter the contest in your own name. </li>
          <li className="text-short">By entering the contest, you warrant that all information submitted by you is true, current, and complete. </li>
          <li className="text-short">Registration <br />Registration is free and open to all, from age of 13 years to 19th years. you must visit the Mobile Application and complete the registration form. For this purpose, you must open a user account on the GenWE app and indicate the following:
           <ul>
            <li>Last name </li>
            <li>First Name</li>
            <li>Email address</li>
            <li>Country, city </li>
            <li>Gender </li>
            <li>Date of birth </li>
            <li>School name, school type </li>
            <li>Hobbies and interests </li>
            <li>Social passions </li>
            <li>What you want to learn </li>
            <li>What you are good at </li>
            <li>A password </li>
           </ul>

          </li>
          <li className="text-short">Flow of Contest:
           <ul>
            <li>GUTWC will be open for <b>6 weeks from July 01 2023 to August 15 2023.</b> </li>
            <li>Students <b>spend just 1 hour a week</b> to unite the world by: </li>
            <li>Downloading GenWE app and filling in their hobbies, interests, social passions. </li>
            <li>Through our unique app, they get paired with a student of the <b>same gender from different background</b> (country or culture or socio-economic). </li>
            <li>Pairs will have conversations with their new friend to learn about  each other. </li>
            <li>They will complete valuable life-skill webinars hosted by GenWE. </li>
            <li>For every activity they complete pairs earn points. <b>At the end of 6 weeks, the pairs with the highest points will get attractive prizes mentioned below.</b></li>
           </ul>
          </li>
          <li className="text-short">Point System: <br />You will earn points in the following ways –
           <ul>
            <li>Every connect through the GenWE app has points
             <ul>
              <li>Text – 5 points for every text </li>
              <li>Phone call – 10 points for every second </li>
              <li>Video call – 20 points for every second </li>
             </ul>
            </li>
            <li>They will also earn bonus points for every activity they complete. Activities will be announced every week. They could win bonus points if the activity is a competition. This will be part of the announcement. </li>
           </ul>
          </li>
          <li className="text-short">Prizes to be Won: <br />We have exciting prizes lined up.  <br />Teens could win
           <ul>
            <li>Gift coupons of $ 200 of brand of your choice  </li>
            <li>Recommendation letter  </li>
            <li>Internships </li>
            <li>Exclusive virtual meeting Indra Nooyi </li>
            <li>Name / photo on social media feeds </li>
           </ul>
          </li>
          <li className="text-short">Decisions of GenWE will be final and binding with regard to the Challenge.  </li>
          <li className="text-short">The prizes are personal to the winners and are not transferrable and cannot be sold or cashed under any circumstances.  </li>
          <li className="text-short">GenWE may in its sole discretion refuse to provide a prize, or seek its recovery, in the event of non-entitlement under these terms or your breach of these terms, GenWE’s website terms of use, fraud, dishonesty, or other inappropriate or improper conduct including but not limited to the use of technology which enables an entrant to evade charges or entry requirements. </li>
          <li className="text-short">GenWE will not accept responsibility or liability for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft, destruction, alteration of, or unauthorised access to entries, or entries lost or delayed whether or not arising during operation or transmission as a result of server functions (including but not limited to security functions by software used by GenWE or any third party), virus, bugs, or other causes outside its control. </li>
          <li className="text-short">When you enter or attempt to enter the contest in a manner, which in GenWE’s determination is contrary to these terms and conditions or by its nature is unfair to others, you may be removed from the contest at GenWE’s sole discretion. </li>
          <li className="text-short">You acknowledge that certain information disclosed by GenWE to you during the contest, may be proprietary to the GenWE or any of their affiliates and confidential in nature (“Confidential Information”). To this extent, you agree that for the duration of the contest and for a further period of one year, they shall not disclose to any third party, and shall refrain from using, except as may be needed for participating in the contest, any confidential and proprietary information of GenWE, or any of their affiliates. You shall not print, reproduce or otherwise disclose to any third party, or use for the benefit of itself or anybody else, or for any purpose other than for the purpose of participating in the contest.  </li>
          <li className="text-short">It is strictly prohibited to upload content (picture/text/any other content) of any kind that contains expressions of hate, abuse, offensive images or conduct, obscenity, pornography, sexually explicit or against any religious sentiments or against any caste, creed or race or any material which is offensive to any person or any material that could give rise to any civil or criminal liability under applicable law or regulations or that otherwise may be in conflict with these Terms and Conditions or any other applicable law. It is also clarified that any entry which is in the reasonable opinion of GenWE is found to be obscene, offending and hurtful shall be rejected immediately, you will be disqualified from the contest; and appropriate action will be taken against you.  </li>
          <li className="text-short">The contest is completely remote and done digitally. By participating, you are confirming their awareness of this fact, and are agreeing to use a digital device upon which the GenWE application is supported. GenWE cannot provide devices to anyone. </li>
          <li className="text-short">GenWE will not be liable for any loss of data due to server issues or other technical faults. </li>
          <li className="text-short">The contest may use your/your child’s/your students’ photos, interviews, and videos pertaining to this contest on the GenWE’s official website, posters, and/or brochures, in order to encourage and motivate other students for the upcoming events. Moreover, upon joining a GenWE’s public session, such as but not included to GenWE’s Webinars, Zoom Sessions, Live Sessions via social media platforms, or any other event connected to contest, the participant agrees to share their data for internal marketing purposes. This data may be used by GenWE for further event creation, marketing, data analysis, and other purposes directly connected to the events. </li>
          <li className="text-short">Participation in the contest requires the communication of your personal data, which can be modified at any time by the you. </li>
          <li className="text-short">Concerning the possible installation of cookies (or witnesses of connection) on the your computer during your browsing on GenWE websites, you are invited to consult the concerned website and the conditions he/she has to accept previously during the opening of an account necessary to participate in the Challenge </li>
          <li className="text-short">Send us an email at friends@genwe.today and all your personal information will be erased within 48 hours. </li>
          <li className="text-short">These terms and conditions and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of Delaware, USA.  </li>
          <li className="text-short">After the end of the contest you can continue using GenWE app and stay connected to the paired buddy. You should accept and agree to the Privacy Policy and the Terms of Use to continue using GenWE.  </li>

         </ol>
        </div>



       </div>
      </div>
     </div>
    </div>
   </div>
   <div className="FooterMargin">
    {" "}
    <Footer />
   </div>
  </div>
 );
};

export default DexContestTermsAndCondition;
