import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Licence.css";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="container MainTagSpace">
        <div>
          {/* <Header /> */}
          <div className="container">
            <div className="row">
              <div className=" col-lg-12 pt-4">
                <div className="pt-4">
                  {" "}
                  <h1> PRIVACY POLICY</h1>{" "}
                </div>
                <div className="py-2">
                  Welcome to our Service. Service (“us”, “we”, or “our”)
                  operates GenWE Inc (hereinafter referred to as “Service”). Our
                  Privacy Policy governs your visit to GenWE applications, and
                  explains how we collect, safeguard and disclose information
                  that results from your use of our Service and we will always
                  comply with all applicable laws in respect of your personal or
                  sensitive personal data.{" "}
                </div>

                <div className="py-2">
                  {" "}
                  If you are between 13 and 18 years, read the Privacy Policy
                  and Terms of Service with your parents or legal guardian and
                  agree before use. Where requested by us, ask them to provide
                  their consent to use the services at friends@genwe.today.{" "}
                </div>

                <div className="py-2">
                  We use your data to provide and improve Service. By using
                  Service, you agree to the collection and use of information in
                  accordance with this policy. By using the Services, you agree
                  to the terms of this Privacy Policy. You shouldn’t use the
                  Services if you don’t agree with this Privacy Policy or any
                  other agreement that governs your use of the Services.
                </div>

                <div className="py-2">
                  Your data is stored in databases maintained by us and third
                  parties located in countries other than your country of
                  residence. Some of them may have data protection laws that may
                  be less stringent than those in your country.
                </div>

                <div className="py-2">
                  <h3>
                    <b>1. What Data We Get</b>{" "}
                  </h3>
                  We collect certain data from you directly, like information
                  you enter yourself, data about your participation in courses,
                  and data from third-party platforms you connect with GenWE. We
                  also collect some data automatically, like information about
                  your device and what parts of our Services you interact with
                  or spend time using.
                </div>

                <div className="py-2">
                  <h5> 1.1 Data You Provide to Us</h5>
                  We may collect different data from or about you depending on
                  how you use the Services. Below are some examples to help you
                  better understand the data we collect. When you create an
                  account, and use the Services, including through a third-party
                  platform, we collect any data you provide directly, including:
                  <ol className="alpha">
                    <li>
                      <b>Account Data</b>
                      <br />
                      In order to use certain features (like enrolling in a
                      course), you need to create a user account. When you
                      create, or update your account, we collect and store the
                      data you provide, like your email address, password,
                      gender, and date of birth, and assign you a unique
                      identifying number (“Account Data”).
                    </li>
                    <li>
                      <b>Profile Data</b>
                      <br />
                      You can also choose to provide profile information like a
                      photo, headline, website link, social media profiles, or
                      other data. Your Profile Data will be publicly viewable by
                      others.
                    </li>
                    <li>
                      <b>Shared Content</b>
                      <br />
                      Parts of the Services let you interact with other users or
                      share content publicly, including by posting reviews on a
                      course page, asking or answering questions, sending
                      messages to trainees or trainers, or posting photos or
                      other work you upload. Such shared content may be publicly
                      viewable by others depending on where it is posted.
                    </li>
                    <li>
                      <b>Payment Data</b>
                      <br />
                      If you make purchases, we collect certain data about your
                      purchase (such as your name and zip code) as necessary to
                      process your order. You must provide certain payment and
                      billing data directly to our payment processing partners,
                      including your name, credit card information, billing
                      address, and zip code. For security, GenWE does not
                      collect or store sensitive cardholder data, such as full
                      credit card numbers or card authentication data
                    </li>
                    <li>
                      <b>Trainer Payment Data</b>
                      <br />
                      If you are an trainer, you can link your PayPal, Payoneer,
                      or other payment account to the Services to receive
                      payments. When you link a payment account, we collect and
                      use certain information, including your payment account
                      email address, account ID, physical address, or other data
                      necessary for us to send payments to your account. In
                      order to comply with applicable laws, we also work with
                      trusted third parties who collect tax information as
                      legally required. This tax information may include
                      residency information, tax identification numbers,
                      biographical information, and other personal information
                      necessary for taxation purposes. For security, GenWE does
                      not collect or store sensitive bank account information.
                      The collection, use, and disclosure of your payment,
                      billing, and taxation data is subject to the privacy
                      policy and other terms of your payment account provider.
                    </li>
                    <li>
                      <b>Data About Your Accounts on Other Services</b>
                      <br />
                      We may obtain certain information through your social
                      media or other online accounts if they are connected to
                      your GenWE account. If you login to GenWE via Facebook or
                      another third-party platform or service, we ask for your
                      permission to access certain information about that other
                      account. For example, depending on the platform or service
                      we may collect your name, profile picture, account ID
                      number, login email address, location, physical location
                      of your access devices, gender, birthday, and list of
                      friends or contacts. Those platforms and services make
                      information available to us through their APIs. The
                      information we receive depends on what information you
                      (via your privacy settings) or the platform or service
                      decide to give us. If you access or use our Services
                      through a third-party platform or service, or click on any
                      third-party links, the collection, use, and sharing of
                      your data will also be subject to the privacy policies and
                      other agreements of that third party.
                    </li>
                    <li>
                      <b>Communications and Support</b>
                      <br />
                      The content, communications and other information you
                      provide when you use the Service, including when you sign
                      up for an account, create or share content, and message or
                      communicate with others. This can include information in
                      or about the content you provide (such as metadata), such
                      as the location of a photo or the date a file was created.
                      The content, communications and information that other
                      people provide when they use the Service. This can include
                      information about you, such as when they share or comment
                      on a photo of you, send a message to you, or upload, sync
                      or import your contact information. All communications
                      with other users of the Services. User communications,
                      feedback, suggestions and ideas sent to you or by you.
                      <br /> If you contact us for support or to report a
                      problem or concern (regardless of whether you have created
                      an account), we collect and store your contact
                      information, messages, and other data about you like your
                      name, email address, location, operating system, IP
                      address, and any other data you provide or that we collect
                      through automated means (which we cover below). We use
                      this data to respond to you and research your question or
                      concern, in accordance with this Privacy Policy
                    </li>
                  </ol>
                </div>

                <div className="py-2">
                  The data listed above is stored by us and associated with your
                  account.
                </div>

                <div className="py-2">
                  <h5> 1.2 Data We Collect through Automated Means</h5>
                  When you access the Services (including surfing through our
                  website), we collect certain data by automated means,
                  including:
                  <br />
                  <ol className="alpha">
                    <li>
                      <b>System Data</b>
                      <br />
                      Technical data about your computer or device, like your IP
                      address, device type, operating system type and version,
                      unique device identifiers, browser, browser language,
                      domain and other systems data, and platform types (“System
                      Data”).
                    </li>
                    <li>
                      <b>Usage Data</b>
                      <br />
                      Usage statistics about your interactions with the
                      Services, including courses accessed, time spent on pages
                      or the Service, pages visited, features used, your search
                      queries, click data, date and time, and other data
                      regarding your use of the Services (“Usage Data”).
                    </li>
                    <li>
                      <b>Approximate Geographic Data</b>
                      <br />
                      An approximate geographic location, including information
                      like country, city, and geographic coordinates, calculated
                      based on your IP address.
                    </li>
                  </ol>
                </div>

                <div className="py-2">
                  The data listed above is collected through the use of server
                  log files and tracking technologies and it is stored by us and
                  associated with your account.
                </div>
                <div className="py-2">
                  <h3> 2. How We Get Data About You </h3>
                  We use tools like cookies, web beacons, analytics services,
                  and advertising providers to gather the data listed above.
                  Some of these tools offer you the ability to opt out of data
                  collection.
                </div>

                <div className="py-2">
                  <h5> 2.1 Cookies and Data Collection Tools</h5>
                  As detailed in our Cookie Policy, GenWE and service providers
                  acting on our behalf (like Google Analytics and third party
                  advertisers) use server log files and automated data
                  collection tools like cookies, tags, scripts, customized
                  links, device or browser fingerprints, and web beacons
                  (together, “Data Collection Tools”) when you access and use
                  the Services. These Data Collection Tools automatically track
                  and collect certain System Data and Usage Data (as detailed in
                  Section 1) when you use the Services. In some cases, we tie
                  data gathered through those Data Collection Tools to other
                  data that we collect as described in this Privacy Policy. We
                  use cookies (small files that websites send to your device to
                  uniquely identify your browser or device or to store data in
                  your browser) for things like analyzing your use of the
                  Services, personalizing your experience, making it easier to
                  log into the Services, and recognizing you when you return. We
                  use web beacons (small objects that allow us to measure the
                  actions of visitors and users using the Services) for things
                  like identifying whether a page was visited, identifying
                  whether an email was opened, and advertising more efficiently
                  by excluding current users from certain promotional messages
                  or identifying the source of a new mobile app download
                </div>

                <div className="py-2">
                  GenWE uses the following types of cookies:
                </div>
                <ul>
                  <li>
                    {" "}
                    Preferences: cookies that remember data about your browser
                    and preferred settings that affect the appearance and
                    behavior of the Services (like your preferred language).
                  </li>
                  <li>
                    Security: cookies used to enable you to log in and access
                    the Services; protect against fraudulent logins; and help
                    detect and prevent abuse or unauthorized use of your
                    account.
                  </li>
                  <li>
                    Functional: cookies that store functional settings (like the
                    volume level you set for video/audio call playback).
                  </li>
                  <li>
                    Session State: cookies that track your interactions with the
                    Services to help us improve the Services and your browsing
                    experience, remember your login details, and enable
                    processing of your course purchases. These are strictly
                    necessary for the Services to work properly, so if you
                    disable them then certain functionalities will break or be
                    unavailable. <br />
                    You can set your web browser to alert you about attempts to
                    place cookies on your computer, limit the types of cookies
                    you allow, or refuse cookies altogether. If you do, you may
                    not be able to use some or all features of the Services, and
                    your experience may be different or less functional.
                  </li>
                </ul>

                <div className="py-2">
                  Some of the third-party partners who provide certain features
                  on our site may also use Local Storage Objects (also known as
                  flash cookies or LSOs) to collect and store data.
                </div>

                <div className="py-2">
                  <h5>2.2 Analytics</h5>
                  We use third-party browser and mobile analytics services.
                  These services use Data Collection Tools to help us analyze
                  your use of the Services, including information like the
                  third-party website you arrive from, how often you visit,
                  events within the Services, usage and performance data, and
                  where the application was downloaded from. We use this data to
                  improve the Services, better understand how the Services
                  perform on different devices, and provide information that may
                  be of interest to you.
                </div>

                <div className="py-2">
                  <h5> 2.3 Online Advertising </h5>
                  We use third-party advertising services like ad networks and
                  ad servers to deliver advertising about our Services on other
                  websites and applications you use. The ads may be based on
                  things we know about you, like your Usage Data and System Data
                  and things that these ad service providers know about you
                  based on their tracking data. The ads can be based on your
                  recent activity or activity over time and across other sites
                  and services, and may be tailored to your interests. Depending
                  on the types of advertising services we use, they may place
                  cookies or other tracking technologies on your computer,
                  phone, or other device to collect data about your use of our
                  Services, and may access those tracking technologies in order
                  to serve these tailored advertisements to you. To help deliver
                  tailored advertising, we may provide these service providers
                  with a hashed, anonymized version of your email address (in a
                  non-human-readable form) and content that you share publicly
                  on the Services.
                </div>

                <div className="py-2">
                  <h3> 3. What We Use Your Data For </h3>
                  communicate with you, troubleshoot issues, secure against
                  fraud and abuse, improve and update our Services, analyze how
                  people use our Services, serve personalized advertising, and
                  as required by law or necessary for safety and integrity.
                </div>

                <div className="py-2">
                  We use the data we collect through your use of the Services
                  to:
                </div>
                <ul>
                  <li>
                    Provide and administer the Services, including to display
                    customized content and facilitate communication with other
                    users;
                  </li>
                  <li>
                    {" "}
                    Process your requests and orders for courses, products,
                    specific services, information, or features;
                  </li>
                  <li>
                    Communicate with you about your account by:
                    <ul>
                      <li>Responding to your questions and concerns;</li>
                      <li>
                        Sending you administrative messages and information,
                        including messages from trainers and teaching
                        assistants, notifications about changes to our Service,
                        and updates to our agreements;
                      </li>
                      <li>
                        Sending you information and in-app messages about your
                        progress in courses, rewards programs, new services, new
                        features, promotions, newsletters, and other available
                        courses (which you can opt out of at any time);
                      </li>
                      <li>
                        Sending push notifications to your wireless device to
                        provide updates and other relevant messages (which you
                        can manage from the “options” or “settings” page of the
                        mobile app);
                      </li>
                    </ul>
                  </li>
                  <li>Manage your account preferences;</li>
                  <li>
                    Facilitate the Services’ technical functioning, including
                    troubleshooting and resolving issues, securing the Services,
                    and preventing fraud and abuse;
                  </li>
                  <li>Solicit feedback from users;</li>
                  <li>
                    Market and administer surveys and promotions administered or
                    sponsored by GenWE;
                  </li>
                  <li>
                    Learn more about you by linking your data with additional
                    data through third-party data providers or analyzing the
                    data with the help of analytics service providers;
                  </li>
                  <li>Identify unique users across devices;</li>
                  <li>Tailor advertisements across devices;</li>
                  <li>
                    Improve our Services and develop new products, services, and
                    features;
                  </li>
                  <li>
                    Analyze trends and traffic, track purchases, and track usage
                    data;
                  </li>
                  <li>
                    Advertise the Services on third-party websites and
                    applications
                  </li>
                  <li>As required or permitted by law; or</li>
                  <li>
                    Enhancing the security and safety of the Service for you and
                    other users, such as by investigating suspicious activity or
                    violations of applicable terms or policies; GenWE uses such
                    data only to keep a scrutiny and does not share this
                    information with any third party.
                  </li>
                  <li>
                    As we, in our sole discretion, otherwise determine to be
                    necessary to ensure the safety or integrity of our users,
                    employees, third parties, the public, or our Services.
                  </li>
                </ul>

                <div className="py-2">
                  <h3>4. Who We Share Your Data With </h3>
                  We share certain data about you with trainers, other trainees,
                  companies performing services for us, our business partners,
                  analytics and data enrichment providers, your social media
                  providers, companies helping us run promotions and surveys,
                  and advertising companies who help us promote our Services. We
                  may also share your data as needed for security, legal
                  compliance, or as part of a corporate restructuring. Lastly,
                  we can share data in other ways if it is aggregated or
                  de-identified or if we get your consent.
                </div>
                <div className="py-2">
                  We may share your data with third parties under the following
                  circumstances or as otherwise described in this Privacy
                  Policy:
                </div>
                <ul>
                  <li>
                    With Your trainer: We share data that we have about you
                    (except your email address) with trainers or teaching
                    assistants for courses you enrol in or request information
                    about, so they can improve their courses for you and other
                    trainees. This data may include things like your city,
                    country, browser language, operating system, device
                    settings, the site that brought you to GenWE, and your
                    activities on GenWE. If we collect additional data about you
                    (like age or gender), we may share that too. We will not
                    share your email address with trainers or teaching
                    assistants. We also enable our trainers to implement Google
                    Analytics on their course pages to track sources of traffic
                    to their courses and optimize their course pages.
                  </li>
                  <li>
                    With Other trainee and trainers: Depending on your settings,
                    your shared content and profile data may be publicly
                    viewable, including to other trainees and trainers. If you
                    ask a question to a trainer or teaching assistant, your
                    information (including your name) may also be publicly
                    viewable by other users depending on your settings.
                  </li>
                  <li>
                    With Service Providers, Contractors, and Agents: We share
                    your data with third-party companies who perform services on
                    our behalf, like payment processing, data analysis,
                    marketing and advertising services (including retargeted
                    advertising), email and hosting services, and customer
                    services and support. These service providers may access
                    your personal data and are required to use it solely as we
                    direct, to provide our requested service.
                  </li>
                  <li>
                    With Business Partners: We have agreements with other
                    websites and platforms to distribute our Services and drive
                    traffic to GenWE. Depending on your location, we may share
                    your data with these partners.
                  </li>
                  <li>
                    With Analytics and Data Enrichment Services: As part of our
                    use of third-party analytics tools like Google Analytics and
                    data enrichment services like Clearbit, we share certain
                    contact information, Account Data, System Data, Usage Data
                    (as detailed in Section 1), or de-identified data as needed.
                    De-identified data means data where we’ve removed things
                    like your name and email address and replaced it with a
                    token ID. This allows these providers to provide analytics
                    services or match your data with publicly-available database
                    information (including contact and social information from
                    other sources). We do this to communicate with you in a more
                    effective and customized manner.
                  </li>
                  <li>
                    To Power Social Media Features: The social media features in
                    the Services (like the Facebook Like button) may allow the
                    third-party social media provider to collect things like
                    your IP address and which page of the Services you’re
                    visiting, and to set a cookie to enable the feature. Your
                    interactions with these features are governed by the
                    third-party company’s privacy policy.
                  </li>
                  <li>
                    To Administer Promotions and Surveys: we may share your data
                    as necessary to administer, market, or sponsor promotions
                    and surveys you choose to participate in, as required by
                    applicable law (like to provide a winners list or make
                    required filings), or in accordance with the rules of the
                    promotion or survey.
                  </li>
                  <li>
                    For Advertising: If we decide to offer advertising in the
                    future, we may use and share certain System Data and Usage
                    Data with third-party advertisers and networks to show
                    general demographic and preference information among our
                    users. We may also allow advertisers to collect System Data
                    through Data Collection Tools (as detailed in Section 2.1),
                    and to use this data to offer you targeted ad delivery to
                    personalize your user experience (through behavioural
                    advertising) and undertake web analytics. Advertisers may
                    also share with us the data they collect about you. To learn
                    more or opt out from participating ad networks’ behavioural
                    advertising, see Section 6.1 (Your Choices About the Use of
                    Your Data) below. Note that if you opt out, you’ll continue
                    to be served generic ads.
                  </li>
                  <li>
                    For Security and Legal Compliance: We may disclose your data
                    to third parties if we (in our sole discretion) have a good
                    faith belief that the disclosure is:
                    <ul>
                      <li>Permitted or required by law;</li>
                      <li>
                        Requested as part of a judicial, governmental, or legal
                        inquiry, order, or proceeding;
                      </li>
                      <li>
                        Reasonably necessary as part of a valid subpoena,
                        warrant, or other legally-valid request;
                      </li>
                      <li>
                        Reasonably necessary to enforce our Terms of Use,
                        Privacy Policy, and other legal agreements;
                      </li>
                      <li>
                        Required to detect, prevent, or address fraud, abuse,
                        misuse, potential violations of law (or rule or
                        regulation), or security or technical issues; or
                      </li>
                      <li>
                        Reasonably necessary in our discretion to protect
                        against imminent harm to the rights, property, or safety
                        of GenWE, our users, employees, members of the public,
                        or our Services.
                      </li>
                      <li>
                        We may also disclose data about you to our auditors and
                        legal advisors in order to assess our disclosure
                        obligations and rights under this Privacy Policy.
                      </li>
                    </ul>
                  </li>
                  <li>
                    During a Change in Control: If GenWE undergoes a business
                    transaction like a merger, acquisition, corporate
                    divestiture, or dissolution (including bankruptcy), or a
                    sale of all or some of its assets, we may share, disclose,
                    or transfer all of your data to the successor organization
                    during such transition or in contemplation of a transition
                    (including during due diligence).
                  </li>
                  <li>
                    After Aggregation/De-identification: we can disclose or use
                    aggregate or de-identified data for any purpose.
                  </li>
                  <li>
                    With Your Permission: with your consent, we may share data
                    to third parties outside the scope of this Privacy Policy.
                  </li>
                </ul>

                <div className="pt-2">
                  <h3>5. Security</h3>
                </div>
                <div className="pb-2">
                  We use appropriate security based on the type and sensitivity
                  of data being stored. As with any internet-enabled system,
                  there is always a risk of unauthorized access, so it’s
                  important to protect your password and to contact us if you
                  suspect any unauthorized access to your account.
                </div>

                <div className="py-2">
                  GenWE takes appropriate security measures to protect against
                  unauthorized access, alteration, disclosure, or destruction of
                  your personal data that we collect and store. These measures
                  vary based on the type and sensitivity of the data.
                  Unfortunately, however, no system can be 100% secured, so we
                  cannot guarantee that communications between you and GenWE,
                  the Services, or any information provided to us in connection
                  with the data we collect through the Services will be free
                  from unauthorized access by third parties. Your password is an
                  important part of our security system, and it is your
                  responsibility to protect it. You should not share your
                  password with any third party, and if you believe your
                  password or account has been compromised, you should change it
                  immediately and contact friends@genwe.today with any concerns.
                </div>

                <div className="py-2">
                  <h3>6. Your Rights</h3>
                  You have certain rights around the use of your data, including
                  the ability to opt out of promotional emails, cookies, and
                  collection of your data by certain analytics providers. You
                  can update or terminate your account from within our Services,
                  and can also contact us for individual rights requests about
                  your personal data. Parents who believe we’ve unintentionally
                  collected personal data about their underage child should
                  contact us for help deleting that information.
                </div>
                <div className="py-2">
                  {" "}
                  <h5>6.1 Your Choices About the Use of Your Data</h5>
                  You can choose not to provide certain data to us, but you may
                  not be able to use certain features of the Services.
                </div>

                <ul>
                  <li>
                    To stop receiving promotional communications from us, you
                    can opt out by using the unsubscribe mechanism in the
                    promotional communication you receive or by changing the
                    email preferences in your account. Note that regardless of
                    your email preference settings, we will send you
                    transactional and relationship messages regarding the
                    Services, including administrative confirmations, order
                    confirmations, important updates about the Services, and
                    notices about our policies.
                  </li>
                  <li>
                    The browser or device you use may allow you to control
                    cookies and other types of local data storage. Your wireless
                    device may also allow you to control whether location or
                    other data is collected and shared.
                  </li>
                </ul>

                <div className="py-2">
                  If you have any questions about your data, our use of it, or
                  your rights, contact us at friends@genwe.today
                </div>

                <div className="py-2">
                  <h5>
                    {" "}
                    6.2 Accessing, Updating, and Deleting Your Personal Data
                  </h5>
                </div>

                <div className="py-2">
                  You can access and update your personal data that GenWE
                  collects and maintains as follows:
                </div>
                <ul>
                  <li>
                    To update data you provide directly, log into your account
                    and update your account at any time.
                  </li>
                  <li>
                    To terminate your account:
                    <ul>
                      <li>
                        Please note: even after your account is terminated, some
                        or all of your data may still be visible to others,
                        including without limitation any data that has been (a)
                        copied, stored, or disseminated by other users
                        (including in course comment); (b) shared or
                        disseminated by you or others (including in your shared
                        content); or (c) posted to a third-party platform. Even
                        after your account is terminated, we retain your data
                        for as long as we have a legitimate purpose to do so
                        (and in accordance with applicable law), including to
                        assist with legal obligations, resolve disputes, and
                        enforce our agreements. We may retain and disclose such
                        data pursuant to this Privacy Policy after your account
                        has been terminated.
                      </li>
                    </ul>
                  </li>
                  <li>
                    To request to access, correct, or delete your personal data,
                    email friends@genwe.today or write to us at GenWE,2902
                    Marina Plaza, Dubai, UAE. Please allow up to 30 days for a
                    response. For your protection, we may require that the
                    request be sent through the email address associated with
                    your account, and we may need to verify your identity before
                    implementing your request. Please note that we retain
                    certain data where we have a lawful basis to do so,
                    including for mandatory record-keeping and to complete
                    transactions.
                  </li>
                </ul>

                <div className="py-2">
                  <h5> 6.3 Our Policy Concerning Children</h5>
                </div>
                <div className="py-2 ">
                  The App is intended for a general audience and is not directed
                  to or intended to be used by children under the age of
                  thirteen years. GenWE does not take responsibility for any
                  misrepresentation of age and use.
                </div>

                <div className="py-2">
                  There is a special necessity to protect children's privacy on
                  the App. We do not knowingly collect any personal data from
                  children.
                </div>

                <div className="py-2">
                  Write to us if you think we have collected any personal data
                  of your child. We will respond to you within one calendar
                  month from verification. We may at times be unable to address
                  your request, if we are unable to correctly identify the user.
                  We will deactivate the child’s account, if we find we have
                  been collecting personal data from your child. Upon
                  identification, we will take reasonable measures to promptly
                  delete such personal data from our records.
                </div>

                <div className="py-2">
                  We encourage parents and legal guardians to monitor their
                  children’s Internet usage. To help enforce our Privacy Policy
                  by instructing their children to not provide any personal data
                  without their permission. Do not share your credit/debit card
                  or other payment instrument with your child to make any in-app
                  purchase.
                </div>

                <div className="py-2 ">
                  <h5> 6.4 Users Rights in California</h5>
                </div>
                <div className="py-2 ">
                  There are certain disclosures required by the California
                  Consumer Privacy Act (or “CCPA”) and California Privacy Rights
                  Act (“CPRA”). This section supplements this Privacy Policy and
                  applies to users who reside in the State of California.
                </div>
                <div className="py-2">
                  Much of the Personal Information that We collect when you use
                  our Services is not subject to the CCPA (CPRA). Personal
                  Information for the purposes of the CCPA (CPRA) does not
                  include protected health information (PHI or electronic PHI)
                  that is subject to the HIPAA or medical information as defined
                  in the California Confidentiality of Medical Information Act
                  (CMIA), clinical trial data, or publicly available information
                  from government records. Personal Information also does not
                  include de-identified or aggregated user data.
                </div>
                <div className="py-2">
                  The following is the list of personal Information types which
                  may have been collected from you within the twelve (12) months
                  prior to the last updated date of this Privacy Policy.
                </div>
                <ul>
                  <li>
                    Identifiers: such as android or apple identifier, nick name
                    or any remaining identifiers voluntarily shared with us.
                  </li>
                  <li>
                    Conversation data: any residual identifiers remaining in
                    your text messages post our adequate removal measures.
                  </li>
                  <li>
                    Communication data: such as name, email identifiers when you
                    write to us.
                  </li>
                  <li>
                    Network data: such as IP address or information about your
                    interactions with our website or Apps.
                  </li>
                  <li>
                    Promotion event data: such as any email identifiers when you
                    enrolled.
                  </li>
                  <li>
                    App Usage data: such as inferences and reports drawn from
                    the above data types about you reflecting your preferences,
                    characteristics, trends, or behaviour.
                  </li>
                  <li>
                    Safety Plan data: any inadvertent identifiers shared within
                    the safety resources when you build or maintain a safety
                    plan for your use in time of need.
                  </li>
                  <li>
                    Institution or Subscriber provided data: identifiers (such
                    as email identifiers, phone numbers) shared when you
                    subscribe on behalf of your institution or group to our
                    campaigns, promotions, product and services.
                  </li>
                  <li>
                    Business data: such as name, email identifiers and contact
                    information shared and processed for lead generation,
                    business development, account management and marketing.
                  </li>
                </ul>

                <div className="py-2 ">
                  We obtain the personal information types listed above from the
                  following sources- when you use our App and Services, directly
                  provided by you, as received from your Institution (where
                  required), when you visit our website, from social networks
                  where you participate, other third-party sources such as
                  partners or recruiters. We will use your data for the
                  processing purposes outlined in earlier sections of this
                  Privacy Policy. We will not collect additional personal data
                  types or use them for any materially different, unrelated, or
                  incompatible purposes without providing you a privacy notice.
                </div>

                <div className="py-2">
                  During the twelve (12) month period prior to the last updated
                  date of this Privacy Policy, we may have shared your Personal
                  Information with the following categories of third-parties-
                  GenWE affiliates and subsidiaries, service providers under
                  adequate data protection contracts.
                </div>

                <div className="py-2 ">
                  Your data provided during use of the App will be retained as
                  per our retention policy mentioned{" "}
                  <a href="https://legal.wysa.io/privacy-policy#retainData">
                    here
                  </a>
                  .
                </div>
                <div className="py-2 ">
                  Your data provided during the study will always be kept
                  secure.
                </div>
                <div className="py-2">
                  You can request a list of third parties with whom we share
                  personal data for direct marketing purposes. Please note that
                  GenWE does not share or sell your personal data with third
                  parties as a matter of policy. Subject to certain exceptions,
                  you can write to us to know about the personal information you
                  shared and also exercise your data protection rights mentioned
                  <a href="https://legal.wysa.io/privacy-policy#choices">
                    here
                  </a>
                  . You can request to delete your personal information, or to
                  not be discriminated against by writing to us at
                  friends@genwe.today.
                </div>
                <div className="py-2">
                  We will respond to your request within 45 calendar days of
                  verification. We may at times be unable to address your
                  request, if we are unable to correctly identify you. We may be
                  unable to address your request due to any of the limitations
                  and exceptions provided within CCPA.
                </div>

                <div className="py-2">
                  <h3>7. Updates & Contact Info</h3>
                </div>
                <div className="py-2">
                  When we make a material change to this policy, we’ll notify
                  users via email, in-product notice, or another mechanism
                  required by law. Changes become effective the day they’re
                  posted. Please contact us via email or postal mail with any
                  questions, concerns, or disputes.
                </div>

                <div className="py-2">
                  <h5> 7.1 Modifications to This Privacy Policy</h5>
                </div>
                <div className="py-2 ">
                  From time to time, we may update this Privacy Policy. If we
                  make any material change to it, we will notify you via email,
                  through a notification posted on the Services, or as required
                  by applicable law. We will also include a summary of the key
                  changes. Unless stated otherwise, modifications will become
                  effective on the day they are posted
                </div>

                <div className="py-2 ">
                  If the change in Privacy Policy is materially adverse to you
                  then you will have the right to terminate the Agreement in
                  which case you will no longer be able to use the Services and
                  we will refund you the proportionate amount of fees.
                </div>

                <div className="py-2">
                  As permitted by applicable law, if you continue to use the
                  Services after the effective date of any change, then your
                  access and/or use will be deemed an acceptance of (and
                  agreement to follow and be bound by) the revised Privacy
                  Policy. The revised Privacy Policy supersedes all previous
                  Privacy Policies.
                </div>

                <div className="py-2">
                  <h5> 7.2 Interpretation</h5>
                </div>
                <div className="py-2">
                  Any capitalized terms not defined in this policy are defined
                  as specified in GenWE's Terms of Use. Any version of this
                  Privacy Policy in a language other than English is provided
                  for convenience. If there is any conflict with a non-English
                  version, you agree that the English language version will
                  control.
                </div>
                <div className="py-2">
                  <h5> 7.3 Questions</h5>
                </div>

                <div className="py-2">
                  If you have any questions, concerns, or disputes regarding our
                  Privacy Policy, please feel free to contact our privacy team
                  (including our designated personal information protection
                  manager) at friends@genwe.today. You can also send postal mail
                  to us at GenWE 2902 Marina Plaza, Dubai, UAE.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="FooterMargin">
        {" "}
        <Footer />
      </div> */}
    </div>
  );
};

export default PrivacyPolicy;
