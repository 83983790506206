import React, { useEffect } from "react";
import Header from "../Header/Header";
import "./genweArchitects.css";

import stringsvg from "../../Assets/Images/GenweArchitects/backSVG/Vector 4.svg";

import firstImg from "../../Assets/Images/GenweArchitects/ItsHerWay.png";
import samaa from "../../Assets/Images/GenweArchitects/2.png";
import nish from "../../Assets/Images/GenweArchitects/3.png";
import mahima from "../../Assets/Images/GenweArchitects/4.png";
import aman from "../../Assets/Images/GenweArchitects/5.png";
import andrew from "../../Assets/Images/GenweArchitects/6.png";
import jude from "../../Assets/Images/GenweArchitects/7.png";
import namashvi from "../../Assets/Images/GenweArchitects/8.png";
import harshin from "../../Assets/Images/GenweArchitects/9.png";
import justin from "../../Assets/Images/GenweArchitects/10.png";
import bhavi from "../../Assets/Images/GenweArchitects/11.png";
import anastasiya from "../../Assets/Images/GenweArchitects/12.png";
import atika from "../../Assets/Images/GenweArchitects/13.png";
import nitya from "../../Assets/Images/GenweArchitects/14.png";
import somesh from "../../Assets/Images/GenweArchitects/15.png";
import shraddha from "../../Assets/Images/GenweArchitects/16.png";
import Footer from "../Footer/Footer";

import { Reveal, Tween, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";
gsap.registerPlugin(ScrollTrigger);

const GenweArchitects = () => {
  useEffect((elem) => {
    // window.scrollTo(0, 0);
    // gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () {
          animateFrom(elem);
        },
        once: true,
      });
    });
  }, []);
  function animateFrom(elem, direction) {
    console.log("Animation Effects");
    direction = direction || 1;
    var x = 0,
      y = direction * 100;
    if (elem.classList.contains("gs_reveal_fromLeft")) {
      x = -100;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
      x = 100;
      y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 2.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  }
  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }
  return (
    <>
      <Helmet>
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-MZ2V2SV5D7"
        />
        <script>
          {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
         
           gtag('config', 'G-MZ2V2SV5D7');
          `}
        </script> */}
        <title>GenWE Architects | GenWE</title>
      </Helmet>
      {/* <section className="header">
        <div className="container">
          <Header />
        </div>
      </section> */}

      <section className="text-center">
        <div className="col-lg-12 col-xs-12">
          <div className="Sub_title">
            The <span className="Gen custom_gen">Gen</span>
            <span className="we custom_we">WE</span> Global Village
          </div>
          <div className="custom_subpara_container">
            <strong className="text_pink custom_global_subPara">
              GenWE has been very fortunate. People from different
              nationalities, age, gender have contributed to the thought
              process, design and launch of GenWE. Here is a glimpse of the
              extended team who played a role in the entire process.
            </strong>
          </div>
        </div>
      </section>

      <section className="needbackground">
        <section className="custom_genwe_wall">
          <div className="svgContainer">
            <img src={stringsvg} alt="" />
          </div>

          <div className="row custom_cards_row">
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_1_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_1stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_orange_back_theme">
                    <img src={firstImg} alt="Avatar" />
                    <h2>Armin Jamula</h2>
                  </div>
                  <div className="Customflip-card-back custom_orange_back_theme">
                    <p>Marketing Strategy</p>
                    <hr />
                    <p>Hopeless romantic and a lover of the power of PINK</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_2_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_2stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_Purple_back_theme">
                    <img src={samaa} alt="Avatar" />
                    <h2>Samaa Burte Nadkarni</h2>
                  </div>
                  <div className="Customflip-card-back custom_Purple_back_theme">
                    <p>
                      I wrote blogs for the website and I made a short
                      promotional film.
                    </p>
                    <hr />
                    <p>I love music and writing</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_3_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_3stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_Blue_back_theme">
                    <img src={nish} alt="Avatar" />
                    <h2>Nish Bhutani</h2>
                  </div>
                  <div className="Customflip-card-back custom_Blue_back_theme">
                    <p>From start to nearly launch</p>
                    <hr />
                    <p>
                      Learned Hindustani classical singing as a child and later
                      as an adult
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_4_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_4stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_pink_back_theme">
                    <img src={mahima} alt="Avatar" />
                    <h2>Mahima Goyal</h2>
                  </div>
                  <div className="Customflip-card-back custom_pink_back_theme">
                    <p>Video Editing</p>
                    <hr />
                    <p>I'm a dancer and love to paint.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="custom_genwe_wall" style={{ marginTop: "-10vh" }}>
          <div className="svgContainer">
            <img src={stringsvg} alt="" />
          </div>
          <div className="row custom_cards_row">
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_5_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_5stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_pink_back_theme">
                    <img src={aman} alt="Avatar" />
                    <h2>Aman Bhutani</h2>
                  </div>
                  <div className="Customflip-card-back custom_pink_back_theme">
                    <p>Research and user induction program</p>
                    <hr />
                    <p>I can solve the Rubik’s cube in less than 10 seconds.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_6_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_6stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_Green_back_theme">
                    <img src={andrew} alt="Avatar" />
                    <h2>Andrew Wilson</h2>
                  </div>
                  <div className="Customflip-card-back custom_Green_back_theme">
                    <p>Crafted exciting copies</p>
                    <hr />
                    <p>I love finding fun ways to do serious stuff</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_7_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_7stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_orange_back_theme">
                    <img src={jude} alt="Avatar" />
                    <h2>Jude Ephson</h2>
                  </div>
                  <div className="Customflip-card-back custom_orange_back_theme">
                    <p>Animation and FAQ's</p>
                    <hr />
                    <p>
                      Interested in boring and exciting stuff from politics to
                      entertainment.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_8_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_8stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_Purple_back_theme">
                    <img src={namashvi} alt="Avatar" />
                    <h2>Namashvi Desai</h2>
                  </div>
                  <div className="Customflip-card-back custom_Purple_back_theme">
                    <p>Designed epic creatives</p>
                    <hr />
                    <p>can make a great conversation with anyone</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="custom_genwe_wall" style={{ marginTop: "-10vh" }}>
          <div className="svgContainer">
            <img src={stringsvg} alt="" />
          </div>
          <div className="row custom_cards_row">
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_9_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_9stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_DarkBlue_back_theme">
                    <img src={harshin} alt="Avatar" />
                    <h2>Harshin Shah</h2>
                  </div>
                  <div className="Customflip-card-back custom_DarkBlue_back_theme">
                    <p>Building the We-communication.</p>
                    <hr />
                    <p>I am an introverted extrovert.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_10_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_10stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_Blue_back_theme">
                    <img src={justin} alt="Avatar" />
                    <h2>Justine May</h2>
                  </div>
                  <div className="Customflip-card-back custom_Blue_back_theme">
                    <p>Created the elearning modules on the GenWE app</p>
                    <hr />
                    <p>
                      Growing up I lived in 4 countries and went to 10 different
                      schools.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_11_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_11stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_pink_back_theme">
                    <img src={bhavi} alt="Avatar" />
                    <h2>Bhavi Joshi</h2>
                  </div>
                  <div className="Customflip-card-back custom_pink_back_theme">
                    <p>Helped with Legal bit.</p>
                    <hr />
                    <p>Into travelling, painting and musical instruments.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_12_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_12stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_Green_back_theme">
                    <img src={anastasiya} alt="Avatar" />
                    <h2>Anastasiya Surnina</h2>
                  </div>
                  <div className="Customflip-card-back custom_Green_back_theme">
                    <p>
                      Helped to set up operations and officially register GenWE
                      in the UAE!
                    </p>
                    <hr />
                    <p>adventurer and passionate equestrian 🐴</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="custom_genwe_wall"
          style={{ paddingBottom: "20vh", marginTop: "-10vh" }}
        >
          <div className="svgContainer">
            <img src={stringsvg} alt="" />
          </div>
          <div className="row custom_cards_row">
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_13_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_13stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_Green_back_theme">
                    <img src={atika} alt="Avatar" />
                    <h2>Atika Malik</h2>
                  </div>
                  <div className="Customflip-card-back custom_Green_back_theme">
                    <p>Imagine. Strategise. Market</p>
                    <hr />
                    <p>I love mountains, farming and music festivals</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_14_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_14stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_Purple_back_theme">
                    <img src={nitya} alt="Avatar" />
                    <h2>Nitya</h2>
                  </div>
                  <div className="Customflip-card-back custom_Purple_back_theme">
                    <p>Intern for the app </p>
                    <hr />
                    <p>Loves playing with dogs and reading </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_15_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_15stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_DarkBlue_back_theme">
                    <img src={somesh} alt="Avatar" />
                    <h2>Somesh Poonia</h2>
                  </div>
                  <div className="Customflip-card-back custom_DarkBlue_back_theme">
                    <p>Advertisement Production and Research</p>
                    <hr />
                    <p>
                      I love travelling and have a passion for cinematography
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 custom_card_container card_16_container">
              <div className="custom_pin"></div>
              <div className="Customflip-card custom_16stCard_rotation">
                <div className="Customflip-card-inner">
                  <div className="Customflip-card-front custom_orange_back_theme">
                    <img src={shraddha} alt="Avatar" />
                    <h2>Shraddha Padhye</h2>
                  </div>
                  <div className="Customflip-card-back custom_orange_back_theme">
                    <p>Executing few creatives for Gen We</p>
                    <hr />
                    <p>
                      Good sense of humour. Also, I mimic people quite well.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* <section>
        <Footer />
      </section> */}
      <div>
        <a href="https://link-to.app/genWE" target="_blanck" id="download_button_click">
          <button className="ReadOurStory custom_download_app" >
            Download the App
          </button>
        </a>
      </div>
    </>
  );
};

export default GenweArchitects;
