import React, { useEffect } from "react";
import Header from "../Header/Header";
import "./More.css";
import "../../Assets/css/style.css";
import "../responsive.css";
import Virtual from "../../Assets/Images/More/Virtual.png";
import Passion from "../../Assets/Images/More/Passion.png";
import Guest from "../../Assets/Images/More/Guest.png";
import blog from "../../Assets/Images/More/blog.png";
import waves from "../../Assets/Images/More/waves.png";
import Footer from "../Footer/Footer";

import { Reveal, Tween, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";
gsap.registerPlugin(ScrollTrigger);

const More = () => {
  useEffect((elem) => {
    // window.scrollTo(0, 0);
    // gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        once: true,
        onEnter: function () {
          animateFrom(elem);
        },
        // assure that the element is hidden when scrolled into view
      });
    });
  }, []);

  function animateFrom(elem, direction) {
    console.log("Animation Effects");
    direction = direction || 1;
    var x = 0,
      y = direction * 100;
    if (elem.classList.contains("gs_reveal_fromLeft")) {
      x = -100;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
      x = 100;
      y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 2.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }
  return (
    <>
      <Helmet>
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-MZ2V2SV5D7"
        />
        <script>
          {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
         
           gtag('config', 'G-MZ2V2SV5D7');
          `}
        </script> */}
        <title>WE Are More | GenWE</title>
      </Helmet>
      <section className="header">
        <div className="container">
          <Header />
        </div>
      </section>

      <section className="para_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className=" text-center title display_flex custom_responsive custom_nopadding">
                <img
                  className="img-fluid  d-xs-block d-sm-block"
                  src={waves}
                  alt=""
                />
                <div>
                  <div className='title custom_nopadding'>
                    Gain New
                    Skills
                  </div>
                  <div>
                    <p style={{ fontSize: "1.3rem", letterSpacing: "0" }}>
                      Through expert sessions, guest speakers and much more
                    </p>
                  </div>
                </div>
                <img
                  className="img-fluid   d-xs-block d-sm-block"
                  src={waves}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="para_padding">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-sm-12 col-md-12 col-xl-6 gs_reveal gs_reveal_fromRight order_2"
              id="tab_view_more_2"
            >
              <div className="VirtualGames">
                <h2 className="small_title">Expert Sessions</h2>
              </div>
              <div className="VirtualGamesText">
                <p className="text_short  text_center_mobile">
                  WE invite different experts to host sessions based on your
                  topics that you request. Some sessions that have been hosted
                  are:
                  <ol>
                    <li className="text_short  text_center_mobile">
                      Self awareness, facilitated by Pravah <br /> Rating  <i data-star="4.9" style={{fontSize:'1.7rem'}}></i>
                    </li>
                    <li className="text_short  text_center_mobile">
                      Time management, facilitated by Pravah <br /> Rating  <i data-star="4.8" style={{fontSize:'1.7rem'}}></i>
                    </li>
                    <li className="text_short  text_center_mobile">
                      Conflict management, facilitated by Pravah <br /> Rating <i data-star="4.8" style={{fontSize:'1.7rem'}}></i>
                    </li>
                  </ol>
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-12 col-md-12 col-xl-6  gs_reveal gs_reveal_fromLeft order_1"
              id="tab_view_more_1"
            >
              <div className="VirtualPic">
                <img className="img-fluid" src={Virtual} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="para_padding">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 col-sm-12 col-md-12 col-xl-6 col-12"
              id="tab_view_more_3"
            >
              <div className="VirtualPicEven  gs_reveal gs_reveal_fromLeft">
                <img className="img-fluid" src={Passion} />
              </div>
            </div>
            <div
              className="col-lg-7 col-sm-12 col-md-12 col-xl-6 gs_reveal gs_reveal_fromRight"
              id="tab_view_more_4"
            >
              <div className="Passionproject text_center_mobile">
                <h2 className="small_title ">Passion project</h2>
              </div>
              <div className="VirtualGamesText">
                <p className="text_short  text_center_mobile ">
                  This is your chance to contribute and make a difference. We
                  pick projects linked to{" "}
                  <strong>
                    {" "}
                    United Nations Sustainable Development Goals{" "}
                  </strong>
                  and we want to hear how you and your friend would solve these
                  challenges. And guess what the{" "}
                  <strong> best ideas get amazing bonus rewards!</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="para_padding">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-sm-12 col-md-12 col-xl-6 gs_reveal gs_reveal_fromRight order_2"
              id="tab_view_more_6"
            >
              <div className="GuestSpeaker ">
                <h2 className="small_title">Guest speakers</h2>
              </div>
              <div className="VirtualGamesText">
                <p className="text_short  text_center_mobile">
                  {/* <strong>
                    {" "}
                    We invite expert guest speakers and the topics are chosen by
                    you!{" "}
                  </strong>
                  Do you want to know how to handle a cyber bully, or manage
                  money, improve your self-confidence or how to become a
                  youtuber … your pick! */}
                  We also invite youth guest speakers who have overcome
                  challenges and made an impact so that you can hear from them. <br />
                  Sessions we will be hosting on popular request are
                  <ol>
                    <li className="text_short  text_center_mobile">
                      How to stop procrastinating.
                    </li>
                    <li className="text_short  text_center_mobile">
                      Dealing with rejection
                    </li>
                    <li className="text_short  text_center_mobile">
                      Overcoming fear of public speaking
                    </li>
                    <li className="text_short  text_center_mobile">
                      How to handle cyber bullying
                    </li>
                    <li className="text_short  text_center_mobile">
                      Building a resume
                    </li>
                  </ol>
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-12 col-md-12 col-xl-6  justify-content-center align-items-center  gs_reveal gs_reveal_fromLeft order_1"
              id="tab_view_more_5"
            >
              <div className="VirtualPic">
                <img className="img-fluid" src={Guest} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="para_padding">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 col-sm-12 col-md-12 col-xl-5"
              id="tab_view_more_7"
            >
              <div className="VirtualPicEven  gs_reveal gs_reveal_fromLeft">
                <img className="img-fluid" src={blog} />
              </div>
            </div>
            <div
              className="col-lg-7  col-sm-12 col-md-12 col-xl-7 gs_reveal gs_reveal_fromRight"
              id="tab_view_more_8"
            >
              <div className="Blog ">
                <h2 className="small_title">Blogs and Videos</h2>
              </div>
              <div className="VirtualGamesText">
                <p className="text_short  text_center_mobile">
                  GenWE has a variety of interesting topics for you to browse
                  through. You could also <strong> watch the recording </strong>
                  of the guest speakers if you missed it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="para_padding pb-0">
        <Footer />
      </section>
      <div>
        <a
          href="https://link-to.app/genWE"
          target="_blanck"
          id="download_button_click"
        >
          <button className="ReadOurStory custom_download_app">
            Download the App
          </button>
        </a>
      </div>
    </>
  );
};

export default More;
