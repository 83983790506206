import React, { useState, useEffect, useRef } from "react";
// @import "~video-react/styles/scss/video-react";
// import "/video-react/dist/video-react.css"; // import css
import { Player } from "video-react";
import "react-awesome-slider/dist/styles.css";
import videoThumbnail from "../Assets/Images/videoThumbnail.png";
import playIcon from "../Assets/Images/play.png";
import ReactPlayer from "react-player";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircle";
// import PlayCircleIcon from '@mui/icons-material/PlayCircle';

function Video2() {
  return (
    <>
      {/* <PlayCircleOutlineIcon /> */}
      <ReactPlayer
        // url="https://www.youtube.com/embed/ESYWflmjASo?rel=0&autoplay=1"
        // url="https://genweappassets.s3.ap-south-1.amazonaws.com/GenWE+Explainer+VDO.mp4"
        url="https://genweappassets.s3.ap-south-1.amazonaws.com/GenWE+Explainer+VDO_new.mp4"
        width="100%"
        height="85vh"
        playing={true}
        playIcon={<PlayCircleOutlineIcon height={555} />}
        light={videoThumbnail}
        controls={true}
        className="custom_Iframe"
      />
    </>
  );
}

export default Video2;
