import React from "react";
import Slider from "react-slick";

import "./one.css";
import "./two.css";

// all images
import firstBanner from "../../Assets/Images/MainSlider/firstBanner.webp";
import secondBanner from "../../Assets/Images/MainSlider/thirdBanner.webp";
import thirdBanner from "../../Assets/Images/MainSlider/firstBanner1.webp";
import fourthBanner from "../../Assets/Images/MainSlider/secondBanner1.png";
import fifthBanner from "../../Assets/Images/MainSlider/thirdBanner1.png";
import sixthBanner from "../../Assets/Images/MainSlider/secondBanner.webp";
import TestBack from "../../Assets/Images/TestBack.png";
import TestForward from "../../Assets/Images/TestForward.png";

export default function SimpleSlider() {
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img style={{ width: "100%", height: "100%" }} src={TestBack} />{" "}
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img style={{ width: "100%", height: "100%" }} src={TestForward} />{" "}
      </div>
    );
  }
  var settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  return (
    <Slider {...settings}>
      <div className="firstBanner">
        <img src={firstBanner} alt="" />
        <h3>GenWE - New friend. New skills. New you.</h3>
      </div>
      <div className="secondBanner">
        <img src={secondBanner} alt="" />
        <h3>Get paired with a new friend from a different background.</h3>
      </div>
      <div className="thirdBanner">
        <img src={thirdBanner} alt="" />
        <h3>
          Share your interests & thoughts​ with a fellow teen, ​ in a safe
          space.
        </h3>
      </div>
      <div className="fourthBanner">
        <img src={fourthBanner} alt="" />
        <h3>
          Attend life skill sessions by experts,​ on topics like confidence,
          handling pressure, & more.
        </h3>
      </div>
      <div className="secondBanner">
        <img src={fifthBanner} alt="" />
        <h3>
          Earn points with every activity.​ Get access to internships,
          certificates, and more.
        </h3>
      </div>
      <div className="thirdBanner">
        <img src={sixthBanner} alt="" />
        <h3>Join the movement to become a global leader.</h3>
      </div>
    </Slider>
  );
}
