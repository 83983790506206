import React, { useState, useEffect, useRef } from "react";
import "./Animation.css";
import { Reveal, Tween, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Intersect from "../../Assets/Images/Intersect.png";
import SlideOne from "../../Assets/Images/SlideOne.png";
import SlideTwo from "../../Assets/Images/SlideTwo.png";
import SlideThree from "../../Assets/Images/SlideThree.png";
import RevolvingText from "../../Assets/Images/RevolvingText.png";
import TestimonialGif from "../../Assets/Images/TestimonialGif.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Testimonial from "../../Assets/Images/Testimonial.png";
import testimonialimg from "../../Assets/Images/Testimonial_avatar.png";
import TestimonialPhone from "../../Assets/Images/TestimonialPhone.png";
// import genWeVideo from '../../Assets/Video/GenWE-Explainer-Short-Version-4K.mp4'

import "./Home.css";
import "../Contest/Contest.css";
import "../responsive.css";
import "../../Assets/css/style.css";
import OurPurpose from "../../Assets/Images/OurPurpose.png";
import SimpleImageSlider from "react-simple-image-slider";
import Boys from "../../Assets/Images/Boys.png";
import BoysPhone from "../../Assets/Images/BoysPhone.png";
import AnimationOne from "../../Assets/Images/AnimationOne.png";
import Curiosity from "../../Assets/Images/OurPurpose/Curiosity.png";
import Encouraging from "../../Assets/Images/OurPurpose/Encouraging.png";
import Fostering from "../../Assets/Images/OurPurpose/Fostering.png";
import Sharing from "../../Assets/Images/OurPurpose/Sharing.png";
import OurValuesPoster from "../../Assets/Images/home/Our values.webp";
import OurValuesPhone from "../../Assets/Images/OurValuesPhone.png";
import OurPartners from "../../Assets/Images/OurPurpose/OurPartners.png";
import MobileImage from "../../Assets/Images/MobileImage.png";
import Elliptical from "../../Assets/Images/Elliptical.png";
import About from "../About";
import Footer from "../Footer/Footer";
import TestBack from "../../Assets/Images/TestBack.png";
import TestForward from "../../Assets/Images/TestForward.png";
import EnglishBolo from "../../Assets/Images/Sponsers/EnglishBolo.png";
import pravah from "../../Assets/Images/Sponsers/pravah.png";
import exper from "../../Assets/Images/Sponsers/exper.png";
import Inme from "../../Assets/Images/Sponsers/Inme.png";
import Youreka from "../../Assets/Images/Sponsers/Youreka.png";
import curve_1 from "../../Assets/Images/home/curve1.png";
import circle1 from "../../Assets/Images/home/circle1.png";
import circle2 from "../../Assets/Images/home/circle2.png";
import circle3 from "../../Assets/Images/home/circle3.png";
import element1 from "../../Assets/Images/home/element1.png";
import element2 from "../../Assets/Images/home/element2.png";
import element3 from "../../Assets/Images/home/element3.png";
import element4 from "../../Assets/Images/home/element4.png";
import whyGenwe1 from "../../Assets/Images/home/whyGenwe1.png";
import whyGenwe2 from "../../Assets/Images/home/whyGenwe2.png";
import whyGenwe3 from "../../Assets/Images/home/whyGenwe3.png";
import whyGenwe4 from "../../Assets/Images/home/whyGenwe4.webp";
import whyGenwe5 from "../../Assets/Images/home/whyGenwe5.webp";
import whyGenwe6 from "../../Assets/Images/home/whyGenwe6.png";
import addImg from "../../Assets/Images/home/addImg.png";
import sparkle from "../../Assets/Images/home/sparkle.gif";
import spring from "../../Assets/Images/Orange_spring.png";
import videoThumbnail from "../../Assets/Images/videoThumbnail.png";
import avatar from "../../Assets/Images/tryalavatar.png";
import williamkrawlins from "../../Assets/Images/genwe testimonials/williamkrawlins.jpg";
import nohahefny from "../../Assets/Images/genwe testimonials/nohahefny.png";
import abhilashasingh from "../../Assets/Images/genwe testimonials/abhilashasingh.png";
import Nirmalakrishnan from "../../Assets/Images/genwe testimonials/Nirmalakrishnan.png";
import justinemay from "../../Assets/Images/genwe testimonials/justinemay.jpg";
import Parakh from "../../Assets/Images/genwe testimonials/Parakh.png";
import Riya from "../../Assets/Images/genwe testimonials/Riya.png";
import Laya from "../../Assets/Images/genwe testimonials/Laya.png";
import Vaishnavi from "../../Assets/Images/genwe testimonials/Vaishnavi.png";
import Bhavya from "../../Assets/Images/genwe testimonials/Bhavya.png";
import Saksham from "../../Assets/Images/genwe testimonials/Saksham.png";
import Yashashree from "../../Assets/Images/genwe testimonials/Yashashree.png";
import Varungha from "../../Assets/Images/genwe testimonials/Varunagha.png";
import bgcover from "../../Assets/Images/genwe testimonials/Intersect.png";
import Video from "../../Components/video";
import Header from "../Header/Header";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import video_url from "../../Assets/Images/video.mp4";

import SimpleSlider from "./BannerSlider";
import preloaderimg from "../../Assets/Images/preloader/prevideo.mp4";
import { Helmet } from "react-helmet";
// import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import slider1 from "../../Assets/Images/Group 1590.png";
import slider2 from "../../Assets/Images/Group 1591.png";
import slider3 from "../../Assets/Images/NewModalBanner1.png";
import googlePlay from "../../Assets/Images/download/GooglePlay.svg";
import appStore from "../../Assets/Images/download/appStore.svg";
import Video2 from "../../Components/video2";

import Slider from "react-slick";

import firstBanner from "../../Assets/Images/home/1.webp";
import secondBanner from "../../Assets/Images/home/2.webp";
import thirdBanner from "../../Assets/Images/home/3.webp";
import fourthBanner from "../../Assets/Images/home/4.webp";
import fifthBanner from "../../Assets/Images/home/5.webp";
import sixthBanner from "../../Assets/Images/home/6.webp";
import { Typography } from "@mui/material";

gsap.registerPlugin(ScrollTrigger);

// const PRELOADER_VIDEO_LENGTH = 5000;

// const PRELOADER_VIDEO_LENGTH = 22000;

const Image = [Intersect, SlideOne, SlideTwo, SlideThree];
// const Text = ["hiiiiiiiii", "byyyyyyyyyy", "noooooooooooooo", "yooooooooo"];

const TestimonialObj = [
  {
    Serial: "1",
    Image: bgcover,
    Content: `Here’s what GenWE teens have to say about their experience`,
  },
  {
    Serial: "2",

    Image: Parakh,

    Content: `I am happy to find a true friend.`,

    Name: "- Parakh",
  },
  {
    Serial: "3",

    Image: Riya,

    Content: `GenWE echoes the power of unity that propels us to a more inclusive world.`,

    Name: "- Riya",
  },
  {
    Serial: "4",

    Image: Laya,

    Content: `GenWE shall be cherished forever,For this is where we unsocial together! Burst out of your bubble ,this is what I'll say, A new friend is only a call away!`,

    Name: "- Laya",
  },
  {
    Serial: "5",

    Image: Vaishnavi,

    Content: `Where there is unity, there is victory. That's what GenWE taught me.`,

    Name: "- Vaishnavi",
  },
  {
    Serial: "6",

    Image: Bhavya,

    Content: `I've never had such a great opportunity before. Thanks to GenWE for all the support!`,

    Name: "- Bhavya",
  },
  {
    Serial: "7",

    Image: Saksham,

    Content: `GenWE helped me communicate better and I am enjoying my experience with my peer.`,

    Name: "- Saksham",
  },
  {
    Serial: "8",

    Image: Yashashree,

    Content: `With my WEbuddy, we proved that friendship and shared interest can lead to success.`,

    Name: "- Yashashree",
  },
  {
    Serial: "9",

    Image: Varungha,

    Content: `I met someone new who I instantly clicked with. Learned different opinions on things I'd go through in real life.`,

    Name: "- Varunagha",
  },
  {
    Serial: "10",

    Image: bgcover,

    Content: `Here's what educators and leaders have to say about GenWE`,
  },
  {
    Serial: "11",

    Image: williamkrawlins,

    Content: `GenWE thoughtfully pairs teenagers from different cultural backgrounds to learn leadership skills as they become friends. GenWE facilitates individual well-being, leadership skills, and a more inclusive and affirming world. `,

    Name: "- Dr. William K. Rawlins,Award Winning Author and Educator About Friendship and Communication",
  },

  {
    Serial: "12",
    Image: nohahefny,
    Content: `GenWE™ is unique and powerful in its multifaceted approach to tackling youth leadership, bridging the skills gap, and advancing inclusion through global peer to peer mentorship. A promising program aligned with the UN Sustainable Development Goals (SDGs), driving tolerance and respect for diversity; while giving youth the necessary leadership and soft skills, enabling their future success in life and in their careers.`,

    Name: " - Noha Hefny, Founder and CEO, People of Impact, Dubai",
  },

  {
    Serial: "13",
    Image: abhilashasingh,
    Content: `Social exclusion and discrimination are huge issues that need to be addressed in our society. I saw the intent and program design of GenWE. Their mission to build inclusive and real-life leadership skills in teenagers is very exciting.`,

    Name: "– Abilasha Singh, Principal, Shining Star International School, Abu Dhabi",
  },
  {
    Serial: "14",
    Image: Nirmalakrishnan,
    Content: `GenWE has so appropriately selected DIALOGUE as a key to building important real-life skills for teens to succeed . I fully endorse their mission of developing 1 million global leaders.
    `,
    Name: "– Dr. Nirmala Krishnan, Educator, Bangalore, India ",
  },
  {
    Serial: "15",
    Image: justinemay,
    Content: `I'm hugely inspired and excited by GenWE's vision to build leadership capabilities into the next generation. I can't wait to meet these teenagers and see the impact of their GenWE learning journey!`,
    Name: "- Justine May, Educator, Australia ",
  },
];

const modalObj = [
  {
    Serial: "1",

    Image: slider3,
  },
  // {
  //   Serial: "2",

  //   Image: slider1,
  // },
  // {
  //   Serial: "3",

  //   Image: slider2,
  // },
];

function Home({ showPreVideo }) {
  const [index, setIndex] = useState(0);
  // const videoRef = useRef();
  // const [show, setShow] = useState(true);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  // const [displayPopUp, setDisplayPopUp] = useState(true);
  // const [videoSrc, setVideoSrc] = useState(null);

  var settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 0,
  };

  // const closePopUp = () => {
  //   // setting key "seenPopUp" with value true into localStorage
  //   localStorage.setItem("seenPopUp", true);
  //   // setting state to false to not display pop-up
  //   setDisplayPopUp(false);
  // };

  // const [loading, setLoading] = useState(null);

  // const removeLoader = setTimeout(() => {
  //   setLoading(false);
  // }, PRELOADER_VIDEO_LENGTH);

  function handleTestimonial(slide) {
    if (slide == "prev" && index > 0) {
      let i = index - 1;

      setIndex(i);
    } else if (slide == "next" && index < TestimonialObj.length - 1) {
      let i = index + 1;

      setIndex(i);
    }
    return;
  }
  // const [toggleColor, setToggleColor] = useState(true);

  // function handleModalobj(slide) {
  //   if (slide == "prev" && index > 0) {
  //     let i = index - 1;
  //     // setToggleColor(true);
  //     setIndex(i);
  //   } else if (slide == "next" && index < modalObj.length - 1) {
  //     let i = index + 1;
  //     // setToggleColor(false);
  //     setIndex(i);
  //   }
  //   return;
  // }

  // useEffect((elem) => {
  //   window.scrollTo(0, 0);
  //   // gsap.registerPlugin(ScrollTrigger);

  //   gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
  //     hide(elem); // assure that the element is hidden when scrolled into view
  //     ScrollTrigger.create({
  //       trigger: elem,
  //       once: true,
  //       onEnter: function () {
  //         animateFrom(elem);
  //       },
  //     });
  //   });
  // }, []);

  // useEffect(() => {
  //   let returningUser = localStorage.getItem("seenPopUp");
  //   setDisplayPopUp(!returningUser);
  // }, []);

  // function animateFrom(elem, direction) {
  //   // console.log("Animation Effects");
  //   direction = direction || 1;
  //   var x = 0,
  //     y = direction * 100;
  //   if (elem.classList.contains("gs_reveal_fromLeft")) {
  //     x = -100;
  //     y = 0;
  //   } else if (elem.classList.contains("gs_reveal_fromRight")) {
  //     x = 100;
  //     y = 0;
  //   }
  //   elem.style.transform = "translate(" + x + "px, " + y + "px)";
  //   elem.style.opacity = "0";
  //   gsap.fromTo(
  //     elem,
  //     { x: x, y: y, autoAlpha: 0 },
  //     {
  //       duration: 5,
  //       x: 0,
  //       y: 0,
  //       autoAlpha: 1,
  //       ease: "expo",
  //       overwrite: "auto",
  //     }
  //   );
  // }

  // function hide(elem) {
  //   gsap.set(elem, { autoAlpha: 0 });
  // }

  // const [data, setData] = useState("-Kapil, 16 yrs, India Mumbai");

  // function BackData() {
  //   setData();
  // }

  // function handleNext() {
  //   console.log("Next");
  // }

  // function handlePrev() {
  //   console.log("Prev");
  // }

  return (
    <>
      <Helmet>
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-MZ2V2SV5D7"
        />
        <script>
          {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
         
           gtag('config', 'G-MZ2V2SV5D7');
          `}
        </script> */}
        <title>Home | GenWE</title>
      </Helmet>

      <section className="header">
        <div className="container">
          <Header />
        </div>
      </section>

      <section>
        {/* className="mainslidersec"*/}

        <div className="col-lg-12 col-md-12 col-sm-12 col-12 position-relative">
          <Video2 />
          {/* <SimpleSlider /> */}
        </div>
      </section>

      {/* <SwipeableTextMobileStepper /> */}
      {/* What is Genwe */}
      <section className="">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-sm-12 col-md-12 col-xl-12 order_2"
              id="tab_view_section_4"
            >
              <div className=" text-center-mobile Sub_title display_flex">
                <div>
                  <div className="ampersand">
                    What is <span className="Gen custom_gen"> Gen</span>
                    <span className="we custom_we">WE&nbsp;&nbsp;</span>
                  </div>
                  <div>
                    <Typography
                      style={{
                        fontSize: "1.1rem",
                        letterSpacing: "0",
                        textAlign: "center",
                        margin: 0,
                      }}
                      variant="h6"
                    >
                      {" "}
                      WE are an exclusive platform for teens to develop life
                      skills - personal, people, planet.
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="container-fluid" data-aos="fade-right">
                <div className="row my-2 desktop_cards_home">
                  <div
                    className="col-md-4 col-sm-12 p-3 px-4"
                    style={{ position: "relative", minHeight: "310px" }}
                  >
                    <div className="element3">
                      <img src={element3} alt="" height={50} />
                    </div>
                    <div className="element2">
                      <img src={element2} alt="" height={80} />
                    </div>
                    <div className="what_is_genwe p-4">
                      <div>
                        <img src={circle1} alt="" height={100} />
                      </div>
                      <div className="py-4">
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            letterSpacing: "1px",
                            fontWeight: "600",
                            fontFamily: "Montserrat",
                            width: { lg: "80%" },
                          }}
                        >
                          GenWE pairs you with a teen from a different
                          background to interact, do fun activities and learn
                          from each other.
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-sm-12 p-3 px-4"
                    style={{ position: "relative" }}
                  >
                    <div className="element4">
                      <img src={element4} alt="" height={80} />
                    </div>
                    <div
                      className="what_is_genwe p-4"
                      style={{ background: "#FFF5F1" }}
                    >
                      <div>
                        <img src={circle2} alt="" height={100} />
                      </div>
                      <div className="py-4">
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            letterSpacing: "1px",
                            fontWeight: "600",
                            fontFamily: "Montserrat",
                            width: { lg: "80%" },
                          }}
                        >
                          Helps you build self-awareness and valuable life
                          skills through expert sessions.
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 p-3 px-4">
                    <div className="element1">
                      <img src={element1} alt="" height={50} />
                    </div>
                    <div
                      className="what_is_genwe p-4"
                      style={{ background: "#FFF0F2" }}
                    >
                      <div>
                        <img src={circle3} alt="" height={100} />
                      </div>
                      <div className="py-4">
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            letterSpacing: "1px",
                            fontWeight: "600",
                            fontFamily: "Montserrat",
                            width: { lg: "80%" },
                          }}
                        >
                          Helps you build your “do-good” score and gives you a
                          chance to win certificates, internships and more.
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-2 mobile_cards_home">
                  <Slider {...settings} speed={4000}>
                    <div
                      className="col-md-4 col-sm-12 p-3 px-4 "
                      style={{ position: "relative", width: "250px" }}
                    >
                      {/* <div className="element2">
                        <img src={element2} alt=""  />
                      </div> */}
                      <div className="what_is_genwe p-4">
                        <div className="mobile_cards_home_img">
                          <img src={circle1} alt="" />
                        </div>
                        <div className="py-4">
                          <Typography
                            sx={{
                              fontSize: "0.9rem",
                              letterSpacing: "1px",
                              fontWeight: "600",
                              fontFamily: "Montserrat",
                              width: { lg: "80%" },
                            }}
                          >
                            GenWE pairs you with a teen from a different
                            background to interact, do fun activities and learn
                            from each other.
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-4 col-sm-12 p-3 px-4 mobile_cards_home"
                      style={{ position: "relative", width: "250px" }}
                    >
                      {/* <div className="element4">
                        <img src={element4} alt=""  />
                      </div> */}
                      <div
                        className="what_is_genwe p-4"
                        style={{ background: "#FFF5F1" }}
                      >
                        <div className="mobile_cards_home_img">
                          <img src={circle2} alt="" />
                        </div>
                        <div className="py-4">
                          <Typography
                            sx={{
                              fontSize: "0.9rem",
                              letterSpacing: "1px",
                              fontWeight: "600",
                              fontFamily: "Montserrat",
                              width: { lg: "80%" },
                            }}
                          >
                            Helps you build self-awareness and valuable life
                            skills through expert sessions.
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-4 col-sm-12 p-3 px-4 mobile_cards_home"
                      style={{ position: "relative", width: "250px" }}
                    >
                      {/* <div className="element1">
                        <img src={element1} alt="" height={10}/>
                      </div> */}
                      <div
                        className="what_is_genwe p-4"
                        style={{ background: "#FFF0F2" }}
                      >
                        <div className="mobile_cards_home_img">
                          <img src={circle3} alt="" />
                        </div>
                        <div className="py-4">
                          <Typography
                            sx={{
                              fontSize: "0.9rem",
                              letterSpacing: "1px",
                              fontWeight: "600",
                              fontFamily: "Montserrat",
                              width: { lg: "80%" },
                            }}
                          >
                            Helps you build your “do-good” score and gives you a
                            chance to win certificates, internships and more.
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>

              {/* <section className="mainslidersec">
                <div data-aos="fade-right">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 position-relative">
                    <Video2 />
                    <SimpleSlider />
                  </div>
                </div>
              </section> */}
            </div>
          </div>
        </div>
      </section>

      <section className="my-5">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-sm-12 col-md-12 col-xl-12 order_2"
              id="tab_view_section_4"
            >
              <div className=" text-center-mobile Sub_title display_flex">
                <div>
                  <div className="ampersand">
                    Why <span className="Gen custom_gen"> Gen</span>
                    <span className="we custom_we">WE</span>
                  </div>
                </div>
              </div>
              <div className="container-fluid" data-aos="fade-right">
                <div className="d-flex justify-content-evenly flex-wrap">
                  <div className="first">
                    <div className="whyGenwe-img">
                      <img src={whyGenwe1} alt="" width={"250px"} />
                    </div>
                    <div style={{ fontFamily: "Montserrat" }}>
                      Between school, tuition & extracurriculars, your life is
                      packed.
                    </div>
                  </div>
                  <div className="plus d-flex align-items-start position-relative">
                    <img
                      src={addImg}
                      alt=""
                      width={"50px"}
                      className="position-absolute top-0"
                    />
                  </div>
                  <div className="second">
                    <div className="whyGenwe-img">
                      <img src={whyGenwe2} alt="" width={"250px"} />
                    </div>
                    <div style={{ fontFamily: "Montserrat" }}>
                      The competition is immense, your parents want you to
                      succeed.
                    </div>
                  </div>
                  <div className="plus d-flex align-items-center position-relative">
                    <img
                      src={addImg}
                      alt=""
                      width={"50px"}
                      className="position-absolute bottom-1"
                    />
                  </div>
                  <div className="third">
                    <div className="whyGenwe-img">
                      <img src={whyGenwe3} alt="" width={"250px"} />
                    </div>
                    <div style={{ fontFamily: "Montserrat" }}>
                      How do I become confident? How do I manage time, stress
                      relationships, rejection?
                    </div>
                  </div>
                </div>
              </div>

              <div data-aos="fade-right">
                <div className="whyGenwe_botom_text">
                  Dealing with expectations + Pressure, and finding answers to
                  self-doubts is difficult
                </div>
              </div>

              <div className=" my-5 py-3" data-aos="fade-right">
                <div className="row my-5">
                  <div className="col-lg-6 col-xs-12">
                    <div className="why_genwe_img_text">
                      Do you wish you had a friend who won’t judge you? Whom you
                      could openly share struggles and experience with?
                    </div>
                    <div className="why_genwe_bottom_img d-flex justify-content-end">
                      <img src={whyGenwe4} alt="" height={"100%"} />
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-12  ">
                    <div className="why_genwe_bottom_img1 d-flex justify-content-start">
                      <img src={whyGenwe5} alt="" height={"650px"} />
                    </div>
                    <div
                      className="why_genwe_img_text d-flex justify-content-end"
                      style={{ width: "100%" }}
                    >
                      <span
                        className="why_genwe_img_text"
                        style={{ width: "80%" }}
                      >
                        {" "}
                        Do you want to become more confident, manage your time
                        better and develop life skills?
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative whyGenwe_last_img">
        <div className="container py-5">
          <div className="genwe_  p-0 position-absolute m-0 ">
            <span className="Gen custom_gen m-0  p-0"> &nbsp;Gen</span>
            <span className="we custom_we m-0  p-0">WE&nbsp;&nbsp;</span>
          </div>
          <div className="my-5 px-4">
            <div className="genwe__text my-2" style={{ fontSize: "30px" }}>
              Has your back - WE’re a unique platform that pairs you with a teen
              from a different background, in a non-toxic, non judgemental safe
              space.
            </div>
            <div className="genwe__text" style={{ fontSize: "30px" }}>
              We help you develop valuable life skills and go beyond just good
              grades, to make you self-aware, confident and HAPPY.
            </div>
          </div>
        </div>
        {/* <div className="whyGenwe_last_img">
          <img src={whyGenwe6} width={"100%"} alt="" height={"100%"} />
        </div> */}
      </section>

      <section className="our_purpose_section">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 col-sm-12 col-md-12 col-xl-5 order_2"
              id="tab_view_section_4"
            >
              <div
                className="text_container custom_padding"
                data-aos="fade-right"
              >
                <p className="text_short">
                  To build an open-minded, confident generation of teens by
                  enabling joyful friendships and valuable life skills.
                </p>
              </div>
              <div className="ReadOurStoryDiv" data-aos="fade-right">
                {" "}
                <Link to="/Story">
                  {" "}
                  <button className="ReadOurStory ">Read Our Story</button>
                </Link>
              </div>
            </div>

            <div
              className="col-lg-6 col-sm-12 col-md-12 col-xl-6  ourpurpose_container	order_1"
              id="tab_view_section_3"
            >
              <div data-aos="fade-left">
                <div className="OurPurpose_img">
                  {" "}
                  {/* <video src={video_url} controls  playsInline={true} poster={OurPurpose}></video> */}
                  <Video />
                  {/* <img src={OurPurpose} className="img-fluid purpose_img" /> */}
                </div>
              </div>
              <h1 className="main_title">
                OUR{" "}
                <span
                  style={{
                    fontFamily: "Ampersand",
                    color: "#FFFF",
                  }}
                >
                  <br />
                  PUR
                </span>
                POSE
              </h1>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="our_purpose_section">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-sm-12 col-md-12 col-xl-12 order_2"
              id="tab_view_section_4"
            >
              <div className=" text-center-mobile Sub_title display_flex">
                GLIMPSE OF <span className="Gen custom_gen"> &nbsp;Gen</span>
                <span className="we custom_we">WE&nbsp;&nbsp;&nbsp;</span>
              </div>
              <div className="gs_reveal gs_reveal_fromLeft" id="Circle">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 position-relative">
                  <Video2 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* 
      <section className="Animated_marquee_text">
        <div className="col-lg-12 col-xs-12 col-xl-12 col-md-12 col-sm-12 ">
          <div className="">
            <div className="MarqueesText">
              {" "}
              <marquee behavior="stop" direction="left" scrollamount="0">
                <span className="we custom_stop_margin">WE</span>'RE ON A
                MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we"> WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we">WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we"> WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we">WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we"> WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we">WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we"> WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>
                <span className="we">WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we"> WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we">WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we"> WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we">WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we"> WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we">WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we"> WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>
                <span className="we">WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we"> WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we">WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we"> WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we">WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we"> WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we">WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>{" "}
                <span className="we"> WE</span>'RE ON A MISSION{" "}
                <span>
                  <img width={50} src={Elliptical} />
                </span>
              </marquee>{" "}
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="Frienship_section">
        <div className="">
          <div className="row">
            <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6 gs_reveal gs_reveal_fromLeft">
              <img className="BoysPic img-fluid" src={Boys} alt="Boys" />
              <img className="graphic_1" src={AnimationOne} alt="Boys" />
            </div>

            <div className="col-lg-6 col-lg-6 col-sm-6 col-md-6 col-xl-6 gs_reveal gs_reveal_fromLeft">
              <div className="container">
                <div
                  className="friendShip gs_reveal gs_reveal_fromRight"
                  id="CircleOne"
                >
                  <p className="text_short custom_noPadding">
                    {" "}
                    We want to enable friendship pairs to discover the joy of
                    diversity, learn, share and take small actions that can make
                    a big difference to their community, their lives and to the
                    world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="Our_values_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-sm-2 col-md-2 col-xl-2 ">
              <div className="OurValues title">OUR VALUES</div>
            </div>

            <div className="col-lg-5 col-sm-10 col-md-10 col-xl-5 order_2">
              <div className="OurValueKey">
                <div className="thingsWeLiveBy" data-aos="fade-right">
                  A few important things that we live by
                </div>
                <div className="OurValuesKeyPointsDiv " data-aos="fade-right">
                  <img src={Curiosity} alt="Curiosity" />{" "}
                  <span className="OurValuesKeyPoints  ">
                    Inspiring Curiosity
                  </span>
                </div>
                <div className="OurValuesKeyPointsDiv" data-aos="fade-right">
                  <img src={Encouraging} alt="Curiosity" />{" "}
                  <span className="OurValuesKeyPoints   ">
                    Encouraging Generosity
                  </span>
                </div>
                <div className="OurValuesKeyPointsDiv " data-aos="fade-right">
                  <img src={Fostering} alt="Curiosity" />{" "}
                  <span
                    className="OurValuesKeyPoints"
                    style={{
                      marginLeft: "64px",
                    }}
                  >
                    Fostering Friendships
                  </span>
                </div>
                <div className="OurValuesKeyPointsDiv" data-aos="fade-right">
                  <img src={Sharing} alt="Curiosity" />{" "}
                  <span
                    className="OurValuesKeyPoints   "
                    style={{
                      marginLeft: "50px",
                    }}
                  >
                    Sharing Knowledge
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-xl-5 col-sm-12 col-md-12 order_1 ">
              <div className="OurValuesPoster">
                <img
                  src={OurValuesPoster}
                  className="img-fluid our_values_poster"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Our_partners">
        <img className="OurPartnersGold" src={OurPartners} />
        <div className="">
          <h1 className="title"> OUR PARTNERS</h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
              <div className="flip-card " data-aos="fade-right">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="PartnersCard">
                      <div className="PartnersCard1">
                        <div className="Partners1">
                          <span>
                            {" "}
                            <img src={EnglishBolo} className="img-fluid" />
                          </span>
                        </div>
                        <div className="PartnerText">
                          https://www.englishbolo.com/
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="PartnersCard">
                      <div className="PartnersFlip">
                        <div className="PartnerTextFlip">
                          <div>
                            {" "}
                            EnglishBolo™, a joint initiative by EnglishHelper
                            and Schoolnet, is an AI-powered English learning app
                            to help learners improve their spoken English!
                          </div>
                          <div className="pt-3">
                            EnglishBolo™ has been designed based on research on
                            how languages are learnt. EnglishBolo™ Teachers are
                            trained to provide learners with a safe learning
                            environment to practise.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="PartnersCard">
                      <div className="PartnersCard2">
                        <div className="Partners1">
                          <img src={pravah} className="img-fluid" />
                        </div>
                        <div className="PartnerText">
                          https://www.pravahindia.org/
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="PartnersCard">
                      <div className="PartnersFlip">
                        <div className="PartnerTextFlip">
                          <div>
                            {" "}
                            Pravah was seeded in 1993, with the intent to create
                            safe spaces for adolescents and youth to form their
                            own opinions, worldview, bring about change within
                            themselves and the society around them.
                          </div>
                          <div className="pt-3">
                            Pravah has reached out to 7 Lakh adolescents and
                            youth, strengthened over 1000 youth engaging and
                            youth led organisations across India and impacted 15
                            million lives.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="PartnersCard">
                    <div className="PartnersCard2">
                      <div className="Partners1">
                        <img src={pravah} />
                      </div>
                      <div className="PartnerText">
                        https://www.pravahindia.org/
                      </div>
                    </div>
                  </div> */}
            </div>
            <div
              className="col-sm-12 col-md-12 col-lg-4 col-xl-4 boxSpace"
              data-aos="fade-left"
            >
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="PartnersCard">
                      <div className="PartnersCard3">
                        <div className="Partners1">
                          <img src={exper} className="img-fluid" />
                        </div>
                        <div className="PartnerText">https://www.exper.in/</div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="PartnersCard">
                      <div className="PartnersFlip">
                        <div className="PartnerTextFlip">
                          <div>
                            {" "}
                            One of India's leading Experiential Executive
                            Education organisations, Exper was founded by alumni
                            of XLRI & INSEAD in 2007 and works closely with CXO
                            levels across industries. We use a simple and
                            practical approach to develop leaders and renew
                            organizations. As you will see, it doesn’t have to
                            be complex to be sound.
                          </div>
                          <div className="pt-3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 "></div>
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 ">
              <div className="flip-card" data-aos="fade-right">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="PartnersCard">
                      <div className="PartnersCard4">
                        <div className="Partners1 p-4">
                          <img src={Inme} className="img-fluid" />
                        </div>
                        <div className="PartnerText">https://inme.in/</div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="PartnersCard">
                      <div className="PartnersFlip">
                        <div className="PartnerTextFlip">
                          <div>
                            26 years of engaging children in outdoor and
                            adventure programs has enabled us to create a series
                            of unique and memorable programs that let children
                            discover their true potential. A thrilling mix of
                            adventure, fun and learning—it’s an experience
                            unlike any other that you can get in regular life.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
              <div className="flip-card" data-aos="fade-left">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="PartnersCard">
                      <div className="PartnersCard5">
                        <div className="Partners1">
                          <img src={Youreka} className="img-fluid" />
                        </div>
                        <div className="PartnerText">youreka.in</div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="PartnersCard">
                      <div className="PartnersFlip">
                        <div className="PartnerTextFlip">
                          <div>
                            A happy school is where all stakeholders come
                            together to create joyful experiences In building an
                            empowered school environment that focuses on the
                            students, the school leaders, teachers and perfects.
                            Through our founders’ extensive experience in
                            leadership development across numerous
                            organizations, we can help envision, detail and
                            implement key building blocks of your school’s
                            promise and aspirations.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 "></div>
          </div>
        </div>
      </section>

      <section className="testimonial_sections">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
          <h1 className="title">TESTIMONIALS</h1>
        </div>

        <div className="">
          <div className="row ">
            <div className="col-lg-2 col-sm-12 col-md-12 col-xl-3 testimonial_bg">
              <div className="custom_sideGif_height">
                <img
                  className="GifClass"
                  style={{
                    backgroundColor: "black",
                  }}
                  // height={421}
                  src={TestimonialGif}
                ></img>
              </div>
            </div>
            <div className="col-lg-8 col-sm-12 col-md-12 col-xl-6 testimonial_bg_mobile">
              <div>
                <div className="Testmaindiv">
                  <div className="TestBox">
                    <div className="TestimonialText">
                      <div className="custom_align_center">
                        {console.log(
                          TestimonialObj.length,
                          "---> testimonial obj"
                        )}

                        <div className="TestimonialImage">
                          <img src={TestimonialObj[index].Image} alt="" />
                        </div>
                      </div>

                      <div
                        className={`TestimonialContent ${
                          index == 0 || index == 9
                            ? "header_text"
                            : index > 0 && index < 9
                            ? "content_line"
                            : ""
                        } `}
                      >
                        {TestimonialObj[index].Content}
                      </div>

                      <div className="TestimonialPerson">
                        {TestimonialObj[index].Name}
                      </div>
                    </div>
                  </div>

                  <div className="BackForthBtn">
                    <span
                      onClick={() => {
                        handleTestimonial("prev");
                      }}
                      className="btnBack"
                    >
                      {" "}
                      <img width={82} src={TestBack} />{" "}
                    </span>{" "}
                    <span
                      onClick={() => {
                        handleTestimonial("next");
                      }}
                      className="btnForward"
                    >
                      {" "}
                      <img width={82} src={TestForward} />{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-12 col-md-12 col-xl-3 testimonial_bg">
              <div className="custom_sideGif_height">
                <img
                  className="GifClass"
                  // height={421}
                  src={TestimonialGif}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="app_section">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4  col-sm-12 col-xl-5 py-4 py-0"
              data-aos="fade-right"
            >
              {/* <img src={MobileImage} className="img-fluid" /> */}
              <Slider
                {...settings}
                speed={6000}
                prevArrow={false}
                nextArrow={false}
                dots={false}
              >
                <div className="p-3 mobile_mockUp d-flex justify-content-center">
                  <img src={firstBanner} alt="" height={"210px"} />1
                </div>
                <div className="p-3 mobile_mockUp d-flex justify-content-center">
                  <img src={fifthBanner} alt="" />2
                </div>
                <div className="p-3 mobile_mockUp d-flex justify-content-center">
                  <img src={secondBanner} alt="" />3
                </div>
                <div className="p-3 mobile_mockUp d-flex justify-content-center">
                  <img src={sixthBanner} alt="" />4
                </div>
                <div className="p-3 mobile_mockUp d-flex justify-content-center">
                  <img src={fourthBanner} alt="" />5
                </div>
                <div className="p-3 mobile_mockUp d-flex justify-content-center">
                  <img src={thirdBanner} alt="" />6
                </div>
              </Slider>
            </div>

            <div className="col-lg-8 col-md-12 col-sm-12 col-xl-6">
              <div className="MobileYourTime " data-aos="fade-left">
                <h1 className="title text-md-right no_padding custom_title_font">
                  YOUR TIME IS NOW
                </h1>
                <div className="YourTime1">
                  <p className="text_short ">
                    Have real conversations with your buddy, with no judgement,
                    no filter. Learn valuable life skills from experts. And
                    discover the new you!
                  </p>
                  <br />
                  <p className="text_short">New Friend. New Skills. New You.</p>
                </div>
                <div className="text-right">
                  <p className="App_available"> The app is available on</p>
                </div>
                <div className="AppBtn1">
                  <a
                    className="btnG btn-google"
                    id="play-store-button"
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.genwemobile"
                    title="Google Play"
                  >
                    Google Play
                  </a>
                  <a
                    className="btnA btn-Store"
                    id="app-store-button"
                    target="_blank"
                    href="https://apps.apple.com/in/app/genwe/id1537440686"
                    title="Google Play"
                  >
                    App Store
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* modal test */}
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      {/* Home page Modal */}
      {/* {displayPopUp && (
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          open={true}
          className="custom_new_modal"
        >
          <Modal.Header
            style={{ paddingRight: "20px", borderBottom: "0px" }}
            closeButton
            onClick={closePopUp}
          >
            <Modal.Title className="w-100 custom_model_title">
              <h1 className="title text-center no_padding modal-title">
              </h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="contest_model_container">
            <div className="model_slider_button_img_container">

              <div className="imgs_footer_download_container">
                <Link to="https://forms.gle/f1rkYnhScU1b4tf86" target="_blank">
                  <img
                    src={modalObj[index].Image}
                    className="custom_slider_images"
                    alt=""
                  />
                </Link>
                <div
                  className="custom_modal_footer"
                  style={{
                    backgroundColor:
                      (index === 0 && "#5c9fff") ||
                      (index === 1 && "#004a79") ||
                      (index === 2 && "#fb5e4a"),
                  }}
                >
                  <div className="custom_model_footer_container">
                    <p>Download the GenWE App</p>
                    <div className="custom_download_button_container">
                      <a
                        id="play-store-button"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.genwemobile"
                        title="Google Play"
                      >
                        <img src={googlePlay} alt="" />
                      </a>
                      <a
                        id="app-store-button"
                        target="_blank"
                        href="https://apps.apple.com/in/app/genwe/id1537440686"
                        title="Apple Store"
                      >
                        <img src={appStore} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      
      {/* modal test */}

      <section>
        <Footer />
      </section>

      <div>
        <a
          href="https://link-to.app/genWE"
          target="_blanck"
          id="download_button_click"
        >
          <button className="ReadOurStory custom_download_app">
            Download the App
          </button>
        </a>
      </div>
    </>
  );
}

export default Home;
