import React, { Fragment,useRef } from "react";
import { useFormik } from "formik";
import emailjs from '@emailjs/browser';
import "./contactModel.css";




const ContactModel = (props) => {

  const closeContactModel = () => {
    props.setShowContact(false);
  };

  const validate = values => {
    const errors = {};
  
    if (!values.name) {
    } else if (values.name.length > 30) {
      errors.name = 'Must be 30 characters or less';
    }
  
    if (!values.email) {
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  const trigger = (event) =>{
    formik.handleSubmit(event)
    sendEmail(event)
  }

  const formik = useFormik({
    initialValues: {
        name: '',
        // number: '',
        email: '',
        message: '',
    },
    validate,
    onSubmit: (values) => {
        props.setShowContact(false);
      },
    });
    // alert(JSON.stringify(values, null, 2));

  const form = useRef();
  const sendEmail = (event) => {
    event.preventDefault();
    emailjs.sendForm('service_ebaz2xn', 'template_2debcwn', form.current, 'n-783jatCM1piGQ3J')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  // formik.handleSubmit

  return (
    <Fragment>
      <div className="custom_backdrop">
        <div className="CustomFormContainer">
          <form  ref={form} className="custom_contact_form" id="form" onSubmit={trigger}>

            <button className="custom_close_button" onClick={closeContactModel}>
              X
            </button>

            <div className="form-group">
              <label htmlFor="forname">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Enter Name"
              />
          {formik.errors.name ? <div className="Custom_status">{formik.errors.name}</div> : null}
            </div>

            {/* <div className="form-group">
              <label htmlFor="fornumber">Number</label>
              <input
                type="text"
                className="form-control"
                id="number"
                name="number"
                onChange={formik.handleChange}
                value={formik.values.number}
                placeholder="Enter Number"
                
              />
            { formik.errors.number ? <div className="Custom_status">{formik.errors.number}</div> : null }
            </div> */}

            <div className="form-group">
              <label htmlFor="foremail">
                Email Address <span>*</span>
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder="Enter Email"
                required
              />
             {formik.errors.email ? <div className="Custom_status">{formik.errors.email}</div> : null}
            </div>

            <div className="form-group">
              <label htmlFor="formessage">
                Message <span>*</span>
              </label>
              <textarea
                className="form-control"
                rows="3"
                cols="40"
                id="message"
                name="message"
                onChange={formik.handleChange}
                value={formik.values.message}
                required
              >
                {" "}
              </textarea>
            </div>
           
            
            <button
              type="submit"
              className="btn btn-primary custom_form_subbmit"
            >
              Submit
            </button>

          </form>
        </div>
      </div>
    </Fragment>
  );
};
export default ContactModel;
