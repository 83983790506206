import React from 'react'
import "./Licence.css"

const ParentalConsent = () => {
  return (
    <div>
     <div>
        {/* Hello world */}
     
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <h3><strong>Testimonial</strong></h3>
              <div className="seprator" />
              <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
                {'{'}/* {/* Wrapper for slides */} */{'}'}
                <div className="carousel-inner">
                  <div className="item active">
                    <div className="row" style={{padding: '20px'}}>
                      <button style={{border: 'none'}}><i className="fa fa-quote-left testimonial_fa" aria-hidden="true" /></button>
                      <p className="testimonial_para">Lorem Ipsum ist ein einfacher Demo-Text für die Print- und Schriftindustrie. Lorem Ipsum ist in der Industrie bereits der Standard Demo-Text "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo en.</p><br />
                      <div className="row">
                        <div className="col-sm-2">
                          <img src="http://demos1.showcasedemos.in/jntuicem2017/html/v1/assets/images/jack.jpg" className="img-responsive" style={{width: '80px'}} />
                        </div>
                        <div className="col-sm-10">
                          <h4><strong>Jack Andreson</strong></h4>
                          <p className="testimonial_subtitle"><span>Chlinical Chemistry Technologist</span><br />
                            <span>Officeal All Star Cafe</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="row" style={{padding: '20px'}}>
                      <button style={{border: 'none'}}><i className="fa fa-quote-left testimonial_fa" aria-hidden="true" /></button>
                      <p className="testimonial_para">Lorem Ipsum ist ein einfacher Demo-Text für die Print- und Schriftindustrie. Lorem Ipsum ist in der Industrie bereits der Standard Demo-Text "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo en.</p><br />
                      <div className="row">
                        <div className="col-sm-2">
                          <img src="http://demos1.showcasedemos.in/jntuicem2017/html/v1/assets/images/kiara.jpg" className="img-responsive" style={{width: '80px'}} />
                        </div>
                        <div className="col-sm-10">
                          <h4><strong>Kiara Andreson</strong></h4>
                          <p className="testimonial_subtitle"><span>Chlinical Chemistry Technologist</span><br />
                            <span>Officeal All Star Cafe</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="controls testimonial_control pull-right">
                <a className="left fa fa-chevron-left btn btn-default testimonial_btn" href="#carousel-example-generic" data-slide="prev" />
                <a className="right fa fa-chevron-right btn btn-default testimonial_btn" href="#carousel-example-generic" data-slide="next" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ParentalConsent