import React, { useState, useEffect, useRef } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Licence.css";
import { motion } from "framer-motion";

import "./test.css";

const DexContentPolicy = () => {
  return (
    <div>
      <div className="container MainTagSpace">
        <div>
          <Header />
          <div className="container">
            <div className="row">
              <div className=" col-lg-12 pt-4">
                <div className="pt-4 text-center">
                  {" "}
                  <h2> Content Policy</h2>{" "}
                </div>

                <div className="py-2">
                  <p>
                    The detailed User Content and Conduct Policy has been
                    created to help you understand the best practices as a
                    member of GenWE Inc. Our guidelines play a crucial role thus
                    kindly obey these guidelines to keep GenWE Inc running
                    without any difficulty for all our Users.
                  </p>
                  <p>
                    This application and other allied web portals are in
                    compliance with all applicable laws, rules and guidelines.
                  </p>
                  <p>
                    You agree to obey these guidelines when using our products
                    and services (“Services”).
                  </p>
                  <p>
                    As and when we are alerted of probable guidelines
                    infringement, we may investigate and take necessary action,
                    including restricting or ceasing a user’s permission to use
                    our Services.
                  </p>
                  <p>
                    We may alter these guidelines so kindly refer to these
                    guidelines regularly.
                    <br />
                    Unacceptable Use:
                  </p>
                  <ol>
                    <li className="text-short">
                      Do not indulge in Felonious Activities
                    </li>
                    <br />
                    <p>
                      Do not use our creation to participate in felonious
                      activities including but not limited to account hijacking,
                      scams, gambling or encourage menacing and/or illegal acts
                      which will harm any person/s.
                    </p>
                    <li className="text-short">Do not use Malign Products</li>
                    <br />
                    <p>
                      Do not disseminate viruses, malware, or any other malign
                      or ruinous code that will harm the functionality of the
                      website or mobile application.
                    </p>
                    <li className="text-short">Do not Hijack other accounts</li>
                    <br />
                    <p>
                      Do not access another user’s account without the users’
                      prior consent. Do not use our products for phishing scams.
                    </p>
                    <li className="text-short">
                      Do not circulate other’s Personal Intimate Information
                    </li>
                    <br />
                    <p>
                      Do not circulate other people’s confidential details,
                      including but not limited to age, sexual orientation,
                      address, telephone and/or mobile numbers, credit card
                      numbers, personal national ID numbers, or account
                      passwords, without their prior written assent. Note that
                      information that is already available elsewhere on the
                      Internet or in public records is not considered to be
                      private or confidential under our policies.
                    </p>
                    <li className="text-short">
                      Never impersonate or do any kind of Fraudulent Behaviour
                    </li>
                    <br />
                    <p>
                      The word ‘phishing’ is commonly used to describe the
                      offence of electronically impersonating someone else for
                      financial gain. This is frequently done either by using
                      someone else’s login credentials to gain access to
                      protected systems. Do not use our products to impersonate.
                      You will create an account only for yourself and not on
                      behalf of another individual. You will not impersonate
                      yourself to be someone else.
                    </p>
                    <li className="text-short">Do not use Loathe Speech</li>
                    <br />
                    <p>
                      Do not circulate content that encourages dislike or
                      brutality towards groups of people based on their race or
                      ancestral origin, creed, incapacity, gender, age, veteran
                      status, or sexual orientation/ gender identity.
                    </p>
                    <li className="text-short">Do not Spam</li>
                    <br />
                    <p>
                      Do not send spam, including sending undesirable
                      publicizing or commercial data, or undesirable mass
                      solicitation. You shall not create serial accounts for
                      disruptive or abusive purposes, or with overlapping use
                      cases.
                    </p>
                    <p>
                      Mass account creation may result in suspension of all
                      related accounts. You may not publish or any third-party
                      link which links to malicious content intended to damage
                      or disrupt another user’s browser or computer or to
                      compromise a user’s privacy.
                    </p>
                    <li className="text-short">Never Manipulate Content</li>
                    <br />
                    <p>
                      Do not manipulate ranking or relevancy using strategy like
                      repetitive or deceptive keywords or metadata, offering
                      incentives to review your product, or posting fake
                      reviews.
                    </p>
                    <p>
                      Do not post or encourage others to post comments which are
                      for any reason whatsoever defamatory or do not slander,
                      persecute, stalk, intimidate, or otherwise infringe the
                      legal rights of others.
                    </p>
                    <li className="text-short">Child Protection</li>
                    <br />
                    <p>
                      Do not circulate content that ill-treats children, such as
                      child pornography (including but not limited to cartoon
                      child pornography, child abuse, targets or perpetrators of
                      cyber bullying) or data that represents children in any
                      sexual manner.
                    </p>
                    <li className="text-short">
                      Do not post Overly-Sexually Uncensored Material
                    </li>
                    <br />
                    <p>
                      Do not circulate data that encompasses nudity, graphic sex
                      acts, or sexually uncensored material that are indicated
                      to degrade the allure of human body. Do not operate
                      traffic to handle commercial pornography sites. Your
                      profile cannot include adult or derogatory data
                      inappropriate for minors. For example, do not use pictures
                      that exhibit a person’s sexual organs.
                    </p>
                    <li className="text-short">
                      Do not resort to Aggressive or Bullying Behavior
                    </li>
                    <br />
                    <p>
                      Do not circulate portrayal of unjustified violence.
                      <br />
                      Do not menace or bully other users. Online harassment is
                      also illegal and can have serious offline repercussions.
                    </p>
                    <li className="text-short">Never Gamble</li>
                    <br />
                    <p>
                      Gambling in India is heavily restricted. The Law regulates
                      cyber activities globally and prohibits publication or
                      transmission of information that can corrupt people.
                      <br />
                      Do not give in details that enable online gambling,
                      including sports wager, raffles, or online casinos.
                    </p>
                    <li className="text-short">Misuse of usernames</li>
                    <br />
                    <p>
                      Selling usernames: You may not buy or sell GenWE Inc’s
                      usernames.
                      <br />
                      Username squatting: You may not engage in username
                      squatting. Some of the factors we take into consideration
                      when determining whether conduct is username squatting
                      include:
                      <ul>
                        <li>the number of accounts created;</li>
                        <li>
                          the creation of accounts for the purpose of preventing
                          others from using those account names;
                        </li>
                        <li>
                          the creation of accounts for the purpose of selling
                          those accounts; and
                        </li>
                        <li>
                          the use of third-party content feeds to update and
                          maintain accounts under the names of those third
                          parties.
                        </li>
                      </ul>
                      <br />
                      Please note that GenWE may also remove accounts that are
                      inactive for more than six months.
                    </p>
                    <li className="text-short">Legal Rights</li>
                    <br />
                    <p>
                      Kindly do not slander, persecute, stalk, intimidate, or
                      otherwise infringe the legal rights (such as rights of
                      privacy and publicity) of others.
                    </p>
                    <p>
                      These guidelines are applicable to the content you post on
                      our Service. However, some Services have their own policy,
                      which could be located within those Services and are
                      applicable to your use of them. All our Services are
                      regulated by their applicable terms of Service.
                    </p>
                  </ol>
                  <p>
                    All individuals accessing or using GenWE Inc’s services must
                    adhere to the policies set forth in the Company Policy.
                    Failure to do so may result in GenWE Inc’s taking one or
                    more of the following enforcement actions:
                    <ul>
                      <li>
                        requiring you to delete prohibited content before you
                        can again create new posts and interact with other
                        GenWE’s users;
                      </li>
                      <li>
                        temporarily limiting your ability to create posts or
                        interact with other GenWE’s users;
                      </li>
                      <li>
                        asking you to verify account ownership with a phone
                        number or email address; or
                      </li>
                      <li>permanently suspending your account(s).</li>
                    </ul>
                  </p>
                  <p>
                    If you attempt to evade a permanent suspension by creating
                    new accounts, we will suspend your new accounts. Please note
                    that we may need to change these Rules from time to time and
                    reserve the right to do so. The policies set forth in these
                    Content Policy govern organic content on our platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="FooterMargin">
        {" "}
        <Footer />
      </div>
    </div>
  );
};

export default DexContentPolicy;
