import React, { useEffect } from "react";
import Header from "../Header/Header";
import OurStory from "../../Assets/Images/OurStory/OurStory.png";
import "./Story.css";
import Footer from "../Footer/Footer";
import GenweArchitects from '../GenweArchitects/GenweArchitects'
import Slider from "react-slick";
import { Reveal, Tween, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";
gsap.registerPlugin(ScrollTrigger);

const Story = () => {
  var settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    // autoplay: true,
    // speed:8000,
  };
  var settings1 = {
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // speed: 3000,
  };
  useEffect((elem) => {
    // window.scrollTo(0, 0);
    // gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        once: true,
        onEnter: function () {
          animateFrom(elem);
        },
      });
    });
  }, []);

  function animateFrom(elem, direction) {
    console.log("Animation Effects");
    direction = direction || 1;
    var x = 0,
      y = direction * 100;
    if (elem.classList.contains("gs_reveal_fromLeft")) {
      x = -100;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
      x = 100;
      y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 2.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }

  return (
    <div>
      <Helmet>
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-MZ2V2SV5D7"
        />
        <script>
          {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
         
           gtag('config', 'G-MZ2V2SV5D7');
          `}
        </script> */}
        <title>Our Story | GenWE</title>
      </Helmet>
      <div className="container MainTagSpace">
        <Header />
      </div>
      <div>
        <div className="title gs_reveal gs_reveal_fromLeft custom_nopadding">
          Our Story
        </div>
        <div className="OurStoryPic text-center d-none d-sm-block gs_reveal gs_reveal_fromLeft">
          <img className="img-fluid" src={OurStory} />
        </div>
        <div className="OurStoryPic text-center d-block d-sm-none gs_reveal gs_reveal_fromLeft">
          <img className="img-fluid" width={380} src={OurStory} />
        </div>
      </div>
      <div
        className="container"
        style={{
          marginTop: "60px",
        }}
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="text_short text-center  gs_reveal gs_reveal_fromLeft">
              <strong className="text_pink">
                {" "}
                This is a true story of how two teenage girls inspired the
                creation of GenWE.
              </strong>
            </div>
            <div className="text_short text-center gs_reveal gs_reveal_fromLeft">
              Meet Tarana and Bubbly. They live in different countries and come
              from different socio-economic backgrounds.
            </div>

            {/* <Slider {...settings}>
              <div style={{ width: 100 }}>
                <p>100</p>
              </div>
              <div style={{ width: 200 }}>
                <p>200</p>
              </div>
              <div style={{ width: 75 }}>
                <p>75</p>
              </div>
              <div style={{ width: 300 }}>
                <p>300</p>
              </div>
              <div style={{ width: 225 }}>
                <p>225</p>
              </div>
              <div style={{ width: 175 }}>
                <p>175</p>
              </div>
            </Slider> */}
            <div className="lg_story_slider mt-5">
              <Slider {...settings}>
                <div style={{ width: "45vw" }}>
                  <div
                    className="text_short story_slider"
                    style={{ background: "#F6F4FF" }}
                  >
                    Flashback to 2019 Our daughter, Tarana was growing up in a
                    social bubble in Dubai with a privileged view of the world -
                    private school, iPhone, shopping at the mall, judgmental
                    about others etc. Her friends were similar.
                  </div>
                </div>
                <div style={{ width: "65vw" }}>
                  <div
                    className="text_short story_slider"
                    style={{ background: "#FFF5F1" }}
                  >
                    Three years ago, Tarana was paired with Bubbly, a
                    14-year-old girl in Delhi who studies in a government
                    School. Today, Tarana and Bubbly are buddies. They connect
                    through video, audio calls and texting to talk about
                    YouTube, movies, hobbies and studies. And sometimes about
                    their parents😊. Bubbly practices her English with Tarana
                    and Tarana practice’s her Hindi.
                  </div>
                </div>
                <div style={{ width: "45vw" }}>
                  <div
                    className="text_short story_slider"
                    style={{ background: "#FFF0F2" }}
                  >
                    It’s beautiful to see their relationship evolve and its
                    impact on Tarana who is now less judgemental and more
                    caring. Equally, Bubbly is more confident and has a
                    perspective on what’s happening in the world.
                  </div>
                </div>
                <div style={{ width: "45vw" }}>
                  <div
                    className="text_short story_slider"
                    style={{ background: "#E8FEF6" }}
                  >
                    Like Tarana and Bubbly, many young people are growing up in
                    their social bubble and their view is limited only to what’s
                    happening inside it. They face unbelievable pressure trying
                    to fit in. And have to deal with a lot of stress.
                  </div>
                </div>
                <div style={{ width: "65vw" }}>
                  <div
                    className="text_short story_slider"
                    style={{ background: "#FFF3D5" }}
                  >
                    Imagine the power of a real friend, real dialogue with no
                    filters. Just two young people from different corners of the
                    country or world helping each other deal with pressure of
                    teenage years, sharing skills and ideas, learning from each
                    other, solving problems together and growing as future
                    inclusive leaders. Leaders who will build a more inclusive
                    world.
                  </div>
                </div>
              </Slider>
            </div>

            <div className="sm_story_slider mt-4">
              <Slider {...settings1} >
                <div >
                  <div
                    className=" story_slider"
                    style={{ background: "#F6F4FF" }}
                  >
                    Flashback to 2019 Our daughter, Tarana was growing up in a
                    social bubble in Dubai with a privileged view of the world -
                    private school, iPhone, shopping at the mall, judgmental
                    about others etc. Her friends were similar.
                  </div>
                </div>
                <div >
                  <div
                    className=" story_slider"
                    style={{ background: "#FFF5F1" }}
                  >
                    Three years ago, Tarana was paired with Bubbly, a
                    14-year-old girl in Delhi who studies in a government
                    School. Today, Tarana and Bubbly are buddies. They connect
                    through video, audio calls and texting to talk about
                    YouTube, movies, hobbies and studies. And sometimes about
                    their parents😊. Bubbly practices her English with Tarana
                    and Tarana practice’s her Hindi.
                  </div>
                </div>
                <div>
                  <div
                    className=" story_slider"
                    style={{ background: "#FFF0F2" }}
                  >
                    It’s beautiful to see their relationship evolve and its
                    impact on Tarana who is now less judgemental and more
                    caring. Equally, Bubbly is more confident and has a
                    perspective on what’s happening in the world.
                  </div>
                </div>
                <div>
                  <div
                    className=" story_slider"
                    style={{ background: "#E8FEF6" }}
                  >
                    Like Tarana and Bubbly, many young people are growing up in
                    their social bubble and their view is limited only to what’s
                    happening inside it. They face unbelievable pressure trying
                    to fit in. And have to deal with a lot of stress.
                  </div>
                </div>
                <div>
                  <div
                    className=" story_slider"
                    style={{ background: "#FFF3D5" }}
                  >
                    Imagine the power of a real friend, real dialogue with no
                    filters. Just two young people from different corners of the
                    country or world helping each other deal with pressure of
                    teenage years, sharing skills and ideas, learning from each
                    other, solving problems together and growing as future
                    inclusive leaders. Leaders who will build a more inclusive
                    world.
                  </div>
                </div>
              </Slider>
            </div>

            
          </div>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="paddingButtom"></div>
      </div>
      <GenweArchitects/>
      <Footer />

      <div>
        <a
          href="https://link-to.app/genWE"
          target="_blanck"
          id="download_button_click"
        >
          <button className="ReadOurStory custom_download_app">
            Download the App
          </button>
        </a>
      </div>
    </div>
  );
};

export default Story;
