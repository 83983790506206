// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Assets/fonts/ampersand/FHAmpersandDisplay-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Assets/fonts/Impact/impact.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mdi-36px {\n    font-size: 39px;\n    margin-right:10px;\n  }\n  \n  html,body {\n    max-width: 100vw;\n    overflow-x: hidden;\n  \n  }\n  \n  * {\n    padding: 0;\n    margin: 0;\n  \n  }\n  \n  body {\n    /* background-image: url(./Assets/Images/RecBackground.png); */\n  /* background-color: rgb(253,241,223) !important; */\n  min-height: 100vh;\n  /* background-repeat: repeat; */\n  }\n\n  @font-face {\n    font-family: \"Ampersand\";\n    src: local(\"Ampersand\") \n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  }\n  @font-face {\n    font-family: \"Impact\";\n    src: local(\"Impact\") \n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    font-weight: 400;\n  }", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;;EAEpB;;EAEA;IACE,UAAU;IACV,SAAS;;EAEX;;EAEA;IACE,8DAA8D;EAChE,mDAAmD;EACnD,iBAAiB;EACjB,+BAA+B;EAC/B;;EAEA;IACE,wBAAwB;IACxB;2CAC4D;EAC9D;EACA;IACE,qBAAqB;IACrB;2CACuC;IACvC,gBAAgB;EAClB","sourcesContent":[".mdi-36px {\n    font-size: 39px;\n    margin-right:10px;\n  }\n  \n  html,body {\n    max-width: 100vw;\n    overflow-x: hidden;\n  \n  }\n  \n  * {\n    padding: 0;\n    margin: 0;\n  \n  }\n  \n  body {\n    /* background-image: url(./Assets/Images/RecBackground.png); */\n  /* background-color: rgb(253,241,223) !important; */\n  min-height: 100vh;\n  /* background-repeat: repeat; */\n  }\n\n  @font-face {\n    font-family: \"Ampersand\";\n    src: local(\"Ampersand\") \n    url(\"./Assets/fonts/ampersand/FHAmpersandDisplay-Black.ttf\");\n  }\n  @font-face {\n    font-family: \"Impact\";\n    src: local(\"Impact\") \n    url(\"./Assets/fonts/Impact/impact.ttf\");\n    font-weight: 400;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
