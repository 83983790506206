import React,{useEffect,useState} from 'react'
import Home from "../Home/Home2";
import preloaderimg from "../../Assets/Images/preloader/prevideo.mp4";
import {isBrowser,isMobile} from 'react-device-detect';
const PRELOADER_VIDEO_LENGTH = 22000;


function HomeWithPreVideo({showPreVideo}) {

    const [loading, setLoading] = useState(null);

    const removeLoader = setTimeout(() => {
      setLoading(false);
    }, PRELOADER_VIDEO_LENGTH);
  
  useEffect(() => {
    const storedPreloadder = localStorage.getItem("isPreloader");
    setLoading(showPreVideo)
    if (+storedPreloadder) {
        // dev 
    } else {
    //   localStorage.setItem("isPreloader", 1);
    }
    return () => {
      clearTimeout(removeLoader);
    //   localStorage.removeItem("isPreloader");
    };
  }, [showPreVideo])
  // console.log("isbrowser val",isBrowser);
  if (loading && !isBrowser && !isMobile) {
    console.log("Inside loading");
    return <div
      style={{
        backgroundColor: "white",
        height: "100vh",
        width: "100vw",
        display: "grid",
        placeItems: "center",
      }}
    
    >
      <video
        autoPlay={true}
        layout="fill"
        src={preloaderimg}
        muted={true}
        style={{ height: "100vh", width: "100vw" }}
       
      />
    </div>
  }
    return (
        <>
            <Home />

        </>
    )
}

export default HomeWithPreVideo