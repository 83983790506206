import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import "./Partner.css";
import PartnerHand from "../../Assets/Images/MakeFriend/PartnerHand.png";
import CompanyPartner from "../../Assets/Images/MakeFriend/CompanyPartner.png";
import Line from "../../Assets/Images/MakeFriend/Line.png";
import SchoolPartner from "../../Assets/Images/MakeFriend/SchoolPartner.png";
import Footer from "../Footer/Footer";
import "../responsive.css";
import { Reveal, Tween, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";
import ContactModel from "../../Components/ContactModel";
gsap.registerPlugin(ScrollTrigger);

function Partner() {
  useEffect((elem) => {
    // gsap.registerPlugin(ScrollTrigger);
    // window.scrollTo(0, 0);

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        once: true,
        onEnter: function () {
          animateFrom(elem);
        },
      });
    });
  }, []);

  function animateFrom(elem, direction) {
    console.log("Animation Effects");
    direction = direction || 1;
    var x = 0,
      y = direction * 100;
    if (elem.classList.contains("gs_reveal_fromLeft")) {
      x = -100;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
      x = 100;
      y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 2.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }

  const [showContact, setShowContact] = useState(false);

  const openpopup = () => {
    setShowContact(true)
  }

  return (
    <>
      <Helmet>
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-MZ2V2SV5D7"
        />
        <script>
          {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
         
           gtag('config', 'G-MZ2V2SV5D7');
          `}
        </script> */}
        <title>Partner with us | GenWE</title>
      </Helmet>
      <section className="header">
        <div className="container">
          <Header />
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="PartnerWitUs gs_reveal gs_reveal_fromLeft">
                <h1 className="title " style={{ paddingBottom: 0 }}>Partner With Us</h1>
              </div>
              <div className="PartnerHand  text-center">
                <img className="img-fluid" src={PartnerHand} />
              </div>

              <div className="col-lg-12">
                <div className="PartnerHandText gs_reveal gs_reveal_fromLeft">
                  <h2 className="home_intro">
                    We are proud to partner with progressive schools, youth
                    organizations and companies who care about nurturing the
                    future generation and help them in creating a more inclusive
                    world.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="HowInterectText ">
                <div className="HowInterectTextMin  text_shortgs_reveal gs_reveal_fromLeft">
                  <div className="dotDiv">
                    {" "}
                    <div className="dot"></div>
                  </div>
                  <div className="  text_short">
                    There are many platforms available for students to improve academic, co-curricular, language and vocation skills like coding, design etc.{" "}
                  </div>
                </div>

                <div className="HowInterectTextMin  text_shortgs_reveal gs_reveal_fromLeft">
                  <div className="dotDiv">
                    {" "}
                    <div className="dot"></div>
                  </div>
                  <div className="  text_short">
                    Life skills are equally important in future success - it is the magic sauce of what makes some people more successful than others.
                  </div>
                </div>

                <div className="HowInterectTextMin  text_shortgs_reveal gs_reveal_fromLeft">
                  <div className="dotDiv">
                    {" "}
                    <div className="dot"></div>
                  </div>
                  <div className="  text_short">
                    Today, there is no safe space or platform that upskills teenagers in critical life and people skills.
                  </div>
                </div>

                <div className="HowInterectTextMin  text_shortgs_reveal gs_reveal_fromLeft">
                  <div className="dotDiv">
                    {" "}
                    <div className="dot"></div>
                  </div>
                  <div className="  text_short">
                    Teens are expected to imbibe these skills from the environment they grow up in, through parents, friends, family, media etc.
                  </div>
                </div>
                <div className="HowInterectTextMin  text_shortgs_reveal gs_reveal_fromLeft">
                  <div className="dotDiv">
                    {" "}
                    <div className="dot"></div>
                  </div>
                  <div className="  text_short">
                    GenWE provides a structured, intentional way to teach teens important life skills, on how to be more open minded, confident, self-aware and non-judgmental. And become global leaders in their chosen field.
                  </div>
                </div>
                <div className="HowInterectTextMin  text_shortgs_reveal gs_reveal_fromLeft">
                  <div className="dotDiv">
                    {" "}
                    <div className="dot"></div>
                  </div>
                  <div className="  text_short">
                    Teenagers get an opportunity to build their do-good score and earn certificates, internships and much more.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section_wrapper">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 gs_reveal gs_reveal_fromLeft order_2 para_padding moba_para_1">
                <div>
                  <h1 className="Sub_title text_orange text-left custom_rsponsive_title">
                    Company partners
                  </h1>
                </div>
                <div className=" para_padding text_short  ">
                  Win hearts and minds of your employees by partnering with us.
                  The biggest worry of employees is the growth and development
                  of their children. GenWE helps companies with a engagement
                  model by helping employees teenage children become future
                  leaders.
                </div>
                <div className="para_padding my-3">
                  <div className="FooterEmail ">
                    <button className="custom_reach_button px-5" style={{ background: '#f16b4d' }} onClick={openpopup}>Reach Out</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xs-12 gs_reveal gs_reveal_fromRight order_1 display-flex moba_img_1 ">
                <div className="">
                  <img className="img-fluid" src={CompanyPartner} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="Line d-sm-block custom_display_option">
                  <img
                    className="img-fluid custom_line_responsive"
                    src={Line}
                  />
                </div>
              </div>
              {showContact ? <ContactModel setShowContact={setShowContact} /> : ""}
              <div className="col-lg-4 moba_img_2">
                <div className="SchoolPartner gs_reveal gs_reveal_fromLeft">
                  <img className="img-fluid" src={SchoolPartner} />
                </div>
              </div>

              <div className="col-lg-8 gs_reveal gs_reveal_fromRight para_padding moba_para_2">
                <div className="">
                  <div className="text_right para_padding">
                    <h1 className="Sub_title text_orange no_padding custom_rsponsive_title">
                      School partners
                    </h1>
                  </div>
                  <div className=" para_padding  text_right">
                    <p className="text_short para_padding custom_responsive_start">
                      In Schools, your initiative can enable students to become
                      future inclusive leaders and problem solvers in line with
                      UN Sustainable Development Goals. Your students will
                      develop an{" "}
                      <strong>
                        open minded, inclusive and respectful thought process.
                      </strong>
                    </p>
                  </div>
                  <div className=" my-3 d-flex justify-content-end">
                    <div className="FooterEmail ">
                      <button className="custom_reach_button px-5 " style={{ background: '#f16b4d', width: '100%' }} onClick={openpopup}>Reach Out</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section>
        <Footer />
      </section>
      <div>
        <a
          href="https://link-to.app/genWE"
          target="_blanck"
          id="download_button_click"
        >
          <button className="ReadOurStory custom_download_app">
            Download the App
          </button>
        </a>
      </div>
    </>
  );
}

export default Partner;
