import React, { useEffect } from "react";
import Header from "../Header/Header";
import "./Faq.css";
import { Accordion } from "react-bootstrap";
import Footer from "../Footer/Footer";
import FaqOne from "../../Assets/Images/Faq/FAqOne.png";
import FaqTwo from "../../Assets/Images/Faq/FaqTwo.png";

import { Reveal, Tween, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";
gsap.registerPlugin(ScrollTrigger);

const Faq = () => {
  useEffect((elem) => {
    // window.scrollTo(0, 0);
    // gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () {
          animateFrom(elem);
        },
        once: true,
      });
    });
  }, []);

  function animateFrom(elem, direction) {
    console.log("Animation Effects");
    direction = direction || 1;
    var x = 0,
      y = direction * 100;
    if (elem.classList.contains("gs_reveal_fromLeft")) {
      x = -100;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
      x = 100;
      y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 2.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }
  return (
    <>
      <Helmet>
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-MZ2V2SV5D7"
        />
        <script>
          {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
         
           gtag('config', 'G-MZ2V2SV5D7');
          `}
        </script> */}
        <title> FAQs | GenWE</title>
      </Helmet>
      <section className="header">
        <div className="container">
          <Header />
        </div>
      </section>
      <section className="para_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 gs_reveal gs_reveal_fromLeft">
              <div className=" title no_padding">
                Frequently Asked Questions
              </div>
              <div className="App_available text_purple text-center">
                Have a question? We are here to help!
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="para_padding">
        <div className="container">
          <div className="row">
            <div className="row">
              <div className="col-lg-1 d-none d-sm-block">
                <div className="FaqOne">
                  <img className="img-fluid" src={FaqOne} />
                </div>
              </div>
              <div className="col-lg-10 gs_reveal gs_reveal_fromLeft">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span className="PhoneQues">
                        {" "}
                        Who will I get Paired with?{" "}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {" "}
                      <p className="text_short">
                        <span className="PhoneAns">
                          {" "}
                          After you download the GenWE app and fill in your
                          details, you will automatically be paired with someone
                          from a different background. This will be done using a
                          matching algorithm such as similar age, gender,
                          interests, hobbies. A very important feature of GenWE
                          is to pair on the basis of opposite strengths, so you
                          both benefit from each other.
                        </span>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span className="PhoneQues"> Can I change my pair? </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text_short">
                        <ul className="text_short">
                          <li className="PhoneAns text_short">
                            Yes, you can though we would urge you to first give
                            it your best to get to know your new friend. It
                            takes time to build a meaningful relationship. It is
                            important to keep an open mind and hear each other
                            out and know about each other.
                          </li>
                          <li className="PhoneAns text_short">
                            <strong className="PhoneAns">
                              In case there is a problem, you can always contact
                              us through the app and we will pair you with
                              someone else. This process could take up to 2
                              weeks .
                            </strong>
                            <strong className="PhoneAns">
                              {" "}
                              Also do remember{" "}
                            </strong>
                            take any breach of our rules very seriously. Any
                            signs of misbehaviour or cyber bullying must be
                            reported to our active back-office through the chat
                            facility available on the app.
                          </li>
                        </ul>

                        {/* <span className="PhoneAns">
                          {" "}
                          • Yes, you can though we would urge you to first give it
                          your best to get to know your new friend. It takes time
                          to build a meaningful relationship. It is important to
                          keep an open mind and hear each other out and know about
                          each other.
                          <div className="Text_short">
                            <strong> • In case there is a problem, you can always contact
                              us through the app and we will pair you with someone
                              else. This process could take up to 2 weeks{" "}.</strong>
                          </div>
                          <div className="text_short">
                            {" "}
                            <strong> Also do remember</strong> - We
                            take any breach of our rules very seriously. Any signs
                            of misbehaviour or cyber bullying must be reported to
                            our active back-office through the chat facility
                            available on the app.
                          </div>
                        </span> */}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <span className="PhoneQues">How do the points help?</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text_short">
                        {/* The feature of points redemption is currently being worked on.
                        The points you accumulate will be redeemable when this feature is active.
                        Through reward points you will be able to: <br /> */}
                        <ul>
                          <li className="text_short">
                            Every quarter, the pairs with the highest points can
                            get internships.{" "}
                          </li>
                          <li className="text_short">
                            Top 100 pairs get certificates.
                          </li>
                          <li className="text_short">
                            You get invited for exclusive offers like special
                            guest speaker sessions, personal coaching and more
                            if you continue to increase your score.
                          </li>
                          <li className="text_short">
                            You can also show your participation in GenWE on
                            your resume.
                          </li>
                        </ul>
                        {/* • Enrich yourself – Download books, apps, sign up for special programs. <br />
                        • Enrich the world – You will be able to donate your points to educate a child. <br /> */}
                        {/* Your participation in GenWE is about making a difference to the world. GenWE score and
                        contributions also help in building your profile for college admission. */}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>{" "}
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <span className="PhoneQues">
                        {" "}
                        Can I suggest topics for guest speakers?{" "}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {" "}
                      <p className="text_short">
                        <span className="PhoneAns">
                          Yes! We love to hear back from our community to know
                          what's on their mind and what topic they want to know
                          more about. <br />
                          Drop a line to the support team (on the app) and we
                          will find the best guest speakers based on your
                          suggestions.
                        </span>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>{" "}
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      {" "}
                      <span className="PhoneQues">
                        Is there a support team 24/7 ?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {" "}
                      <p className="text_short">
                        <span className="PhoneAns">
                          Yes, our active back-office is available to answer
                          your questions 24/7 through the chat facility on the
                          app.
                        </span>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>{" "}
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <span className="PhoneQues"> Is GenWE safe ?</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {" "}
                      <p className="text_short">
                        <strong> How secure is the app?</strong>
                        <ul>
                          <li className="text_short">
                            Security is a key area of focus. GenWE Inc is a US
                            company and we follow global standards.
                          </li>
                          <li className="text_short">
                            As the app is only for teens, every person who
                            downloads the GenWE app has to go through a ID check
                            process.
                          </li>
                          <li className="text_short">
                            The app uses SSL for all communication with servers
                            - the global standard in secure and encrypted data
                            transmission.
                          </li>
                          <li className="text_short">
                            As a responsible GenWE community member, you should
                            report any misuse to the help desk immediately.
                          </li>
                        </ul>
                        {/* <div className="text_short">
                        • Security is a key area of focus. GenWE Inc is a US
                        company and we follow global standards.
                      </div>
                      <div className="text_short">
                        • We have implemented some of the highest global
                        standards (European GDPR standards) and also limited
                        access of users to ensure the privacy of each user.
                      </div>
                      <div className="text_short">
                        • The app uses SSL for all communication with servers -
                        the global standard in secure and encrypted data
                        transmission.
                      </div>
                      <div className="text_short">
                        • As a responsible GenWE community member, you should
                        report any misuse to the help desk immediately.
                      </div> */}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>{" "}
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      {" "}
                      <span className="PhoneQues"> Can I refer a friend? </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {" "}
                      <p className="text_short">
                        <span className="PhoneAns">
                          Yes of course! We would love to meet your friends.
                          Your friend will get paired with a different WEbuddy
                          and get a chance to learn life skills too.
                        </span>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      <span className="PhoneQues">
                        {" "}
                        Will it be difficult to start talking to a person I
                        don’t know?{" "}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {" "}
                      <p className="text_short">
                        <ul>
                          <li className="text_short">
                            Don’t expect it to be a walk in the park. You will
                            have to push yourself out of your comfort zones and
                            break barriers to get to know the other teenager.
                          </li>
                          <li className="text_short">
                            However, the matching algorithm will ensure that you
                            are matched with someone you are likely to bond
                            with. Since you will be matched based on common
                            areas of interest, age, and things you want to
                            learn, interactions will be easier.
                          </li>
                          <li className="text_short">
                            We will also provide you with discussion tips to
                            help you interact.
                          </li>
                        </ul>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      <span className="PhoneQues">
                        {" "}
                        How does the ID verification work?{" "}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {" "}
                      <p className="text_short">
                      ID verification is very important because we want GenWE to be a safe space where your privacy is protected. <br />
                      To complete ID verification:
                        <ul>
                          <li className="text_short">
                          You will have to keep a school photo ID card card.
                          </li>
                          <li className="text_short">
                          Record a 10 second selfie video with school ID card and your face visible.
                          </li>
                          <li className="text_short">
                          In the video, you have to state your name, grade, school and age.
                          </li>
                          <li className="text_short">
                          Upload the video and our support team will get back within 12 hours.
                          </li>
                          <li className="text_short">
                          We don’t store your data or share your data with anyone.
                          </li>
                          <li className="text_short">
                          In case you have a problem, please write to friends@genwe.today.
                          </li>
                        </ul>

                        {/* <div className="text_short">
                        • Don’t expect it to be a walk in the park. You will
                        have to push yourself out of your comfort zones and
                        break barriers to get to know the other teenager.
                        <div className="text_short">
                          • However, the matching algorithm will ensure that you
                          are matched with someone you are likely to bond with.
                          Since you will be matched based on common areas of
                          interest, age, and things you want to learn,
                          interactions will be easier.
                        </div>
                        <div className="text_short">
                          • We will also provide you with discussion tips to
                          help you interact.
                        </div>
                      </div> */}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      <span className="PhoneQues">
                        {" "}
                        How to delete your GenWE app account?{" "}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {" "}
                      <p className="text_short">
                        <ul>
                          <li className="text_short">
                            Login in to the GenWE app
                          </li>
                          <li className="text_short">
                            Navigate to Settings on top left of your screen
                          </li>
                          <li className="text_short">
                            From 'User Management' section, click on Request to
                            delete account
                          </li>
                          <li className="text_short">
                            Request email will be sent to the GenWE Admin and
                            within 48 hours your account will get deleted.
                          </li>
                          <p className="text_short">
                            For any further support, you can contact at
                            friends@genwe.today
                          </p>
                        </ul>

                        {/* <div className="text_short">
                        • Don’t expect it to be a walk in the park. You will
                        have to push yourself out of your comfort zones and
                        break barriers to get to know the other teenager.
                        <div className="text_short">
                          • However, the matching algorithm will ensure that you
                          are matched with someone you are likely to bond with.
                          Since you will be matched based on common areas of
                          interest, age, and things you want to learn,
                          interactions will be easier.
                        </div>
                        <div className="text_short">
                          • We will also provide you with discussion tips to
                          help you interact.
                        </div>
                      </div> */}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-lg-1 d-none d-sm-block">
                <div className="FaqOne">
                  <img className="img-fluid" src={FaqOne} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <div>
        <a
          href="https://link-to.app/genWE"
          target="_blanck"
          id="download_button_click"
        >
          <button className="ReadOurStory custom_download_app">
            Download the App
          </button>
        </a>
      </div>
    </>
  );
};

export default Faq;
