import React, { useState, useEffect, useRef } from "react";
// import "./Animation.css";
import { Reveal, Tween, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../Home/Home.css";
import "./Contest.css";
import "../responsive.css";
import "../../Assets/css/style.css";
import Footer from "../Footer/Footer";
import TestBack from "../../Assets/Images/TestBack.png";
import TestForward from "../../Assets/Images/TestForward.png";
import slider1 from "../../Assets/Images/Group 1590.png";
import slider2 from "../../Assets/Images/Group 1591.png";
import slider3 from "../../Assets/Images/Group 1592.jpg";
import Header from "../Header/Header";
import "react-awesome-slider/dist/styles.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Accordion } from "react-bootstrap";
import googlePlay from "../../Assets/Images/download/GooglePlay.svg";
import appStore from "../../Assets/Images/download/appStore.svg";
import { Helmet } from "react-helmet";
gsap.registerPlugin(ScrollTrigger);

const TestimonialObj = [
  {
    Serial: "1",

    Image: slider1,
  },
  {
    Serial: "2",

    Image: slider2,
  },
];
const modalObj = [
  {
    Serial: "1",

    Image: slider3,
  },
  {
    Serial: "2",

    Image: slider1,
  },
  {
    Serial: "3",

    Image: slider2,
  },
];
function Contest({ showPreVideo }) {
  const [index, setIndex] = useState(0);

  function handleTestimonial(slide) {
    if (slide == "prev" && index > 0) {
      let i = index - 1;

      setIndex(i);
    } else if (slide == "next" && index < TestimonialObj.length - 1) {
      let i = index + 1;

      setIndex(i);
    }
    return;
  }

  useEffect((elem) => {
    // window.scrollTo(0, 0);
    // gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        once: true,
        onEnter: function () {
          animateFrom(elem);
        },
      });
    });
  }, []);

  function animateFrom(elem, direction) {
    // console.log("Animation Effects");
    direction = direction || 1;
    var x = 0,
      y = direction * 100;
    if (elem.classList.contains("gs_reveal_fromLeft")) {
      x = -100;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
      x = 100;
      y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 5,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }

  // document.addEventListener("DOMContentLoaded", function() {

  // });

  const [data, setData] = useState("-Kapil, 16 yrs, India Mumbai");

  function BackData() {
    setData();
  }

  function handleNext() {
    console.log("Next");
  }

  function handlePrev() {
    console.log("Prev");
  }

  const [toggleShow, setToggleShow] = useState(true);

  const handleToggle = () => {
    setToggleShow(!toggleShow);
  };

  // if (loading) {

  //   return <div
  //     style={{
  //       backgroundColor: "white",
  //       height: "100vh",
  //       width: "100vw",
  //       display: "grid",
  //       placeItems: "center",
  //     }}
  //   >
  //     <video
  //       autoPlay={true}
  //       layout="fill"
  //       src={preloaderimg}
  //       muted={true}
  //       style={{ height: "100vh", width: "100vw" }}
  //     />
  //   </div>

  // }
  const [toggleColor, setToggleColor] = useState(true);

  function handleModalobj(slide) {
    if (slide == "prev" && index > 0) {
      let i = index - 1;
      // setToggleColor(true);
      setIndex(i);
    } else if (slide == "next" && index < modalObj.length - 1) {
      let i = index + 1;
      // setToggleColor(false);
      setIndex(i);
    }
    return;
  }
  return (
    <>
      <Helmet>
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-MZ2V2SV5D7"
        />
        <script>
          {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
         
           gtag('config', 'G-MZ2V2SV5D7');
          `}
        </script> */}
        <title>Home | Contest</title>
      </Helmet>

      <section className="header">
        <div className="container">
          <Header />
        </div>
      </section>

      <section className="contest_section ">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-sm-12 col-md-12 col-xl-12 order_2"
              id="tab_view_section_4"
            >
              <div className="gs_reveal gs_reveal_fromLeft" id="Circle">
                <h1 className="title text-center no_padding custom_title_font">
                  Join the GenWE Contest and Win Exciting Prizes
                </h1>

                <div className="custom_new_modal">
                  <div className="contest_model_container">
                    <div className="model_slider_button_img_container">
                      <span
                        onClick={() => {
                          handleModalobj("prev");
                        }}
                      >
                        {" "}
                        <img width={70} src={TestBack} />{" "}
                      </span>

                      <div className="imgs_footer_download_container">
                        <img
                          src={modalObj[index].Image}
                          className="custom_slider_images"
                          alt=""
                        />
                        <div
                          className="custom_modal_footer"
                          style={{
                            backgroundColor: (index == 0 && "#5c9fff") || (index == 1 && "#004a79") || (index == 2 && "#fb5e4a"),
                          }}
                        >
                          <div className="custom_model_footer_container">
                            <p>Download the GenWE App</p>
                            <div className="custom_download_button_container">
                              <a
                                id="play-store-button"
                                target="_blank"
                                href="https://play.google.com/store/apps/details?id=com.genwemobile"
                                title="Google Play"
                              >
                                <img src={googlePlay} alt="" />
                              </a>
                              <a
                                id="app-store-button"
                                target="_blank"
                                href="https://apps.apple.com/in/app/genwe/id1537440686"
                                title="Apple Store"
                              >
                                <img src={appStore} alt="" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <span
                        onClick={() => {
                          handleModalobj("next");
                        }}
                      >
                        <img width={70} src={TestForward} />{" "}
                      </span>
                    </div>

                    <div className="custom_responsive_next_buttons">
                      <span
                        onClick={() => {
                          handleModalobj("prev");
                        }}
                      >
                        {" "}
                        <img width={70} src={TestBack} />{" "}
                      </span>

                      <span
                        onClick={() => {
                          handleModalobj("next");
                        }}
                      >
                        <img width={70} src={TestForward} />{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* card section */}
      <section className="">
        <div className="container custom_mainraining_row">
          <div className="row">
            <div className="d-flex justify-content-center col-10 col-xl-3 col-lg-3 col-md-3 col-sm-5 mb-4 ">
              <Card className="card1">
                <CardContent className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                  <div>
                    <h1 className="title text-center custom_title_font card-title">
                      Register as a<br /> Student
                    </h1>
                    <p className="text_short text-center card-des">
                      If you are between 13 to 19 years
                    </p>
                  </div>


                  {/* //for mobile view start  */}
                  <div className="text-center">
                    <a
                      href="https://link-to.app/genWE"
                      target="_blank"
                    >
                      <Button
                        variant="primary"
                        type="button"
                        className="card-submit"
                      >
                        Interested? Click here
                      </Button>
                    </a>
                  </div>
                  {/* Download button start */}
                  {/* <div className="AppBtn2 custom_contest_download_button">
                    <a
                      id="play-store-button"
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.genwemobile"
                      title="Google Play"
                    >
                      <img className="mb-1" src={googlePlay} alt="" />
                    </a>
                    <a
                      id="app-store-button"
                      target="_blank"
                      href="https://apps.apple.com/in/app/genwe/id1537440686"
                      title="Apple Store"
                    >
                      <img style={{ width: "93%" }} src={appStore} alt="" />
                    </a>
                  </div> */}
                  {/* download button end */}
                  {/* //for mobile view end  */}
                </CardContent>
              </Card>
            </div>
            <div className="d-flex justify-content-center col-10 col-xl-3 col-lg-3 col-md-3 col-sm-5 mb-4 ">
              <Card className="card1">
                <CardContent className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                  <div>
                    <h1 className="title text-center card-title custom_title_font">
                      Register as a<br /> School
                    </h1>
                    <p className="text_short text-center card-des">
                      To register your school, please fill the enclosd google form
                    </p>
                  </div>
                  <Form>
                    {/* <Form.Group
                      className="mb-3 card-field"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter Name of School/ Organization"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 card-field"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Enter E-mail ID"
                      />
                    </Form.Group> */}
                    <div className="text-center">
                      <a
                        href="https://forms.gle/wrA7HLEJgHBa3vZq9"
                        target="_blank"
                      >
                        <Button
                          variant="primary"
                          type="button"
                          className="card-submit"
                        >
                          Interested? Click here
                        </Button>
                      </a>
                    </div>
                  </Form>
                </CardContent>
              </Card>
            </div>
            <div className="d-flex justify-content-center col-10 col-xl-3 col-lg-3 col-md-3 col-sm-5 mb-4 ">
              <Card className="card1">
                <CardContent className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                  <div>
                    <h1 className="title text-center card-title custom_title_font">
                      Register as a Youth Organization
                    </h1>
                    <p className="text_short text-center card-des">
                      To register as a youth organization, please fill the enclosd google form
                    </p>
                  </div>
                  <Form>
                    {/* <Form.Group
                      className="mb-3 card-field"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter Name of School/ Organization"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 card-field"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Enter E-mail ID"
                      />
                    </Form.Group> */}
                    <div className="text-center">
                      <a
                        href="https://forms.gle/GUFuWaiUsRLPXP197"
                        target="_blank"
                      >
                        <Button
                          variant="primary"
                          type="button"
                          className="card-submit"
                        >
                          Interested? Click here
                        </Button>
                      </a>
                    </div>
                  </Form>
                </CardContent>
              </Card>
            </div>
            <div className="d-flex justify-content-center col-10 col-xl-3 col-lg-3 col-md-3 col-sm-5 mb-4 ">
              <Card className="card1">
                <CardContent className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                  <div>
                    <h1 className="title text-center card-title custom_title_font">
                      Register as a Company
                    </h1>
                    <p className="text_short text-center card-des">
                      If you are a company, please get in touch at<br /> <a href="friends@genwe.today">friends@genwe.today</a><br /> to launch this contest for your employees children.
                    </p>
                  </div>
                  <Form>
                    {/* <Form.Group
                      className="mb-3 card-field"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter Name of School/ Organization"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 card-field"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Enter E-mail ID"
                      />
                    </Form.Group> */}
                    {/* <div className="text-center">
                      <a
                        href="https://forms.gle/wrA7HLEJgHBa3vZq9"
                        target="_blank"
                      >
                        <Button
                          variant="primary"
                          type="button"
                          className="card-submit"
                        >
                          Google Form
                        </Button>
                      </a>
                    </div> */}
                  </Form>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section*/}
      <section>
        <div className="container faq-padding">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="title text-left no_padding custom_title_font">
                Frequently Asked Questions
              </h1>
              <Tabs
                defaultActiveKey="forteens"
                id="fill-tab-example"
                className="mb-3 mt-4 mx-3 adjust-pills custom-nav-pills"
                variant="pills"
                style={{ borderRadius: "42px" }}
              >
                {/* for teens starts */}
                <Tab eventKey="forteens" title="For Teens">
                  <div className="container">
                    <div className="row">
                      <div className="row">
                        <div className="col-lg-12 gs_reveal gs_reveal_fromLeft">
                          <Accordion defaultActiveKey="0">
                            {toggleShow ? (
                              <>
                                {" "}
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What is the contest for ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        {" "}
                                        GenWE’s mission is to bring the world
                                        closer through the younger generation.
                                        We pair young people from different
                                        backgrounds. This GenWE Unite the world
                                        challenge will help students step out of
                                        their social bubble and appreciate a new
                                        perspective. We believe each pair; each
                                        conversation can make a difference to
                                        making the world more inclusive.
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How long will it run ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      The contest will be open for{" "}
                                      <b>
                                        6 weeks from July 01 2023 to August 15

                                        2023.
                                      </b>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      What if I join late ?
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      You are welcome to join any time. The
                                      later you join, lesser the chance of
                                      getting paired and you could miss out on
                                      winning a global contest.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="3">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      I will be 13 in 3 months – can I
                                      participate ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        Unfortunately, no. We will wait for you
                                        to turn 13 and you can participate next
                                        year.
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="4">
                                  <Accordion.Header>
                                    {" "}
                                    <span className="PhoneQues">
                                      I am 20 can I participate ?
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        Unfortunately, no. This contest is for
                                        teenagers between the age of 13 to 19
                                        years old
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="5">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How will it help me ?
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      It will help strengthen key skills like
                                      inclusion, empathy, relationship skills by
                                      pairing you with someone outside your
                                      social bubble. You will make a new friend
                                      from a different background and you could
                                      win attractive prizes!
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="6">
                                  <Accordion.Header>
                                    {" "}
                                    <span className="PhoneQues">
                                      {" "}
                                      How much time will I have to spend ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        This is entirely up to you. The more you
                                        talk to your new friend, the more points
                                        you earn. We do not have a minimum or
                                        maximum time prescribed. We expect that
                                        1 hour a week will help you be
                                        competitive with other pairs.
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How will I earn points ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      You will earn points in the following ways
                                      –
                                      <ul>
                                        <li className="text_short">
                                          Every connect through the GenWE app
                                          has points
                                          <ul>
                                            <li className="text_short">
                                              Text – 5 points for every text
                                            </li>
                                            <li className="text_short">
                                              Phone call – 10 points for every
                                              second
                                            </li>
                                            <li className="text_short">
                                              Video call – 20 points for every
                                              second
                                            </li>
                                          </ul>
                                        </li>
                                        <li className="text_short">
                                          You will also earn bonus points for
                                          every activity you complete.
                                          Activities will be announced every
                                          week. You could win bonus points if
                                          the activity is a competition. This
                                          will be part of the announcement.
                                        </li>
                                      </ul>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </>
                            ) : (
                              <>
                                <Accordion.Item eventKey="8">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Where can I see my points ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Points can be seen in top right side of
                                      the home screen
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How will I get paired ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We pair teenagers through a unique
                                      automated pairing process. The pairing is
                                      based on differences (country, culture,
                                      race, religion, socio-economic) and
                                      similarities (gender, age hobbies).
                                      Another criterion for pairing that we try
                                      and match is opposite strengths so that
                                      the teens mutually benefit from each
                                      other.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Can I pair with my friend ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Unfortunately, no. GenWE is about building
                                      relationships with people outside your
                                      social bubble.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What do I do if my interaction with my
                                      GenWE buddy does not go well ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      <ul>
                                        <li className="text_short">
                                          There is always some awkwardness and
                                          it may take up to 2-3 connects to
                                          break the ice. It’s always advisable
                                          to discuss with your peer to see how
                                          you can make progress.
                                        </li>
                                        <li className="text_short">
                                          We will also provide you with
                                          discussion cues before every session -
                                          you can use these discussion cues to
                                          guide the conversation.
                                        </li>
                                      </ul>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What do I do if my GenWE buddy doesn’t
                                      open up as much, or wants to interact less
                                      ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Learning to interact with people different
                                      than you is the biggest learning.
                                      Remember, building trust takes time. Here
                                      are some tips –
                                      <ul>
                                        <li className="text_short">
                                          Look for common areas of Interest and
                                          make your buddy comfortable and
                                          leverage the discussion cues provided
                                          for each Session{" "}
                                        </li>
                                        <li className="text_short">
                                          Listen more than you talk. Ask
                                          questions.
                                        </li>
                                        <li className="text_short">
                                          Ask for help.
                                        </li>
                                      </ul>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Can I change my pair ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Ideally, we would like you to try and
                                      build a relationship with your new friend.
                                      Change of pairs is rare and will depend if
                                      there are other participants waiting to be
                                      paired
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="14">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What if I don’t get paired ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      As mentioned, the pairing is automated and
                                      there may be cases that someone does not
                                      get paired despite registration. This will
                                      happen if the criteria mentioned above is
                                      not met. Unfortunately, you will not be
                                      able to participate in the contest this
                                      year.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="15">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What if my buddy uses abusive language or
                                      bullies me ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We take misconduct very seriously. Any
                                      signs of abuse, cyberbullying, or
                                      inappropriate conduct should be reported
                                      to our team through the chat facility
                                      available on the app.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="16">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What are the prizes ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We have exciting prizes lined up for you.
                                      You could win
                                      <ul>
                                        <li className="text_short">
                                          Gift coupons of $ 200 of brand of your
                                          choice
                                        </li>
                                        <li className="text_short">
                                          Recommendation letter
                                        </li>
                                        <li className="text_short">
                                          Internships
                                        </li>
                                        <li className="text_short">
                                          Exclusive virtual meeting Indra Nooyi
                                        </li>
                                        <li className="text_short">
                                          Name / photo on social media feeds
                                        </li>
                                      </ul>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="17">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What if I don’t receive my prize ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Please send your complaints to our team
                                      through the chat facility available on the
                                      app.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="18">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Who will decide if there is a tie break ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      There will be a panel of judges along with
                                      the founder
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="19">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What happens after the contest ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We hope you continue interacting with your
                                      new friend on the app. Remember, you are
                                      playing your role in building an inclusive
                                      world.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="20">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What if there are technical issues ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Please send your complaints to our team
                                      through the chat facility available on the
                                      app.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="21">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Will you share my data with anyone else ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      No, your data is safe with us. We only use
                                      the data for the purpose of sending
                                      reminders, updates and better service.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="22">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What if I want to drop out ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We hope you stay with us. In case you do
                                      want to drop out send us an email at
                                      friends@genwe.today and we will deactivate
                                      your account. All your personal
                                      information will be erased within 48
                                      hours.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                              </>
                            )}
                            <div className="d-flex justify-content-center align-items-center">
                              <button
                                className="custom_reach_button custom_viewMore"
                                onClick={() => {
                                  handleToggle();
                                }}
                              >
                                {toggleShow ? "View More" : "View Less"}
                              </button>
                            </div>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                {/* for teen ends */}
                {/* for parents starts */}
                <Tab eventKey="forparents" title="For Parents">
                  <div className="container">
                    <div className="row">
                      <div className="row">
                        <div className="col-lg-12 gs_reveal gs_reveal_fromLeft">
                          <Accordion defaultActiveKey="0">
                            {toggleShow ? (
                              <>
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Is GenWE safe ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        {" "}
                                        Yes, GenWE is safe because –
                                        <ul>
                                          <li className="text_short">
                                            GenWE follows all the online data
                                            protection guidelines laid under
                                            GPDR, CCPA, IT Act, 2000, etc and
                                            has several safety measures built in
                                            like a 24/7 help feature, weekly
                                            check ins with pairs.
                                          </li>
                                          <li className="text_short">
                                            GenWE is not an open community.
                                            Every person who gets onto the GenWE
                                            platform is validated.{" "}
                                          </li>
                                          <li className="text_short">
                                            Each person ONLY interacts with
                                            their peer.
                                          </li>
                                          <li className="text_short">
                                            We collect the parent's email to
                                            report any misbehaviour.
                                          </li>
                                        </ul>
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Will you be sharing my child’s data with
                                      anyone else ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      No, your child’s data is safe with us. We
                                      only use the data for the purpose of
                                      sending reminders, updates and better
                                      service.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      What if my child is bullied ?
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We take bullying or any misconduct very
                                      seriously. Any signs of abuse,
                                      cyberbullying, or inappropriate conduct
                                      should be reported to our team through the
                                      chat facility available on the app.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="3">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      My child will be 13 in 3 months – can he /
                                      she participate ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        Unfortunately, no. We will wait for them
                                        to turn 13 and they can participate next
                                        year.
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="4">
                                  <Accordion.Header>
                                    {" "}
                                    <span className="PhoneQues">
                                      My child is 20 - can he / she participate
                                      ?
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        Unfortunately, no. This contest is for
                                        teenagers between the age of 13 to 19
                                        years old
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="5">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How much time does my child need to spend
                                      ?
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      This is entirely up to your child. The
                                      more they talk to their new friend, the
                                      more points they earn. We do not have a
                                      minimum or maximum time prescribed. We
                                      expect that 1 hour a week will help them
                                      stay competitive with other pairs.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="6">
                                  <Accordion.Header>
                                    {" "}
                                    <span className="PhoneQues">
                                      {" "}
                                      How will it benefit my child ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        It will help strengthen key skills like
                                        inclusion, empathy, relationship skills
                                        by pairing them with someone outside
                                        their social bubble. They will make a
                                        new friend from a different background.
                                        They will be making a difference to
                                        making the world more inclusive and they
                                        could win attractive prizes.
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What is the contest for ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      GenWE’s mission is to bring the world
                                      closer through the younger generation. We
                                      pair young people from different
                                      backgrounds. This GenWE Unite the world
                                      challenge will help students step out of
                                      their social bubble and appreciate a new
                                      perspective. We believe each pair; each
                                      conversation can make a difference to
                                      making the world more inclusive.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </>
                            ) : (
                              <>
                                <Accordion.Item eventKey="8">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How long will it run ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      The contest will be open for{" "}
                                      <b>
                                        6 weeks from July 01 2023 to August 15

                                        2023.
                                      </b>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How can points can be earned ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Your child will earn points in the
                                      following ways –
                                      <ul>
                                        <li className="text_short">
                                          Every connect through the GenWE app
                                          has points
                                          <ul>
                                            <li className="text_short">
                                              Text – 5 points for every text
                                            </li>
                                            <li className="text_short">
                                              Phone call – 10 points for every
                                              second
                                            </li>
                                            <li className="text_short">
                                              Video call – 20 points for every
                                              second
                                            </li>
                                          </ul>
                                        </li>
                                        <li className="text_short">
                                          They will also earn bonus points for
                                          every activity they complete.
                                          Activities will be announced every
                                          week. They could win bonus points if
                                          the activity is a competition. This
                                          will be part of the announcement.
                                        </li>
                                      </ul>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Where can my child see their points ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Points can be seen in top right side of
                                      the home screen
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How will they get paired ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We pair teenagers through a unique
                                      automated pairing process. The pairing is
                                      based on differences (country, culture,
                                      race, religion, socio-economic) and
                                      similarities (age, gender, hobbies).
                                      Another criterion for pairing that we try
                                      and match is opposite strengths so that
                                      the teens mutually benefit from each
                                      other.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What if my child does not get paired ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      As mentioned, the pairing is automated and
                                      there may be cases that someone does not
                                      get paired despite registration. This will
                                      happen if the criteria mentioned above is
                                      not met. Unfortunately, they will not be
                                      able to participate in the contest this
                                      year.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Can my children get paired with their
                                      friends / cousins ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Unfortunately, no. GenWE is about building
                                      relationships with people outside the
                                      social bubble.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="14">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What should my child do if his interaction
                                      with his GenWE buddy does not go well ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We would request you not to intervene. We
                                      provide the following advice to the
                                      participants
                                      <ul>
                                        <li className="text_short">
                                          There is always some awkwardness and
                                          it may take up to 2-3 connects to
                                          break the ice. It’s always advisable
                                          to discuss with their buddy to see how
                                          they can make progress.
                                        </li>
                                        <li className="text_short">
                                          We will also provide participants with
                                          discussion cues before Every session -
                                          they can use these discussion cues to
                                          guide the conversation.
                                        </li>
                                      </ul>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="15">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What do I do if my child’s GenWE buddy
                                      doesn’t open up as much, or wants to
                                      interact less ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We would request you not to intervene. We
                                      provide the following advice to the
                                      participants
                                      <ul>
                                        <li className="text_short">
                                          Learning to interact with people
                                          different than you is the biggest
                                          learning. Remember, building trust
                                          takes time. Look for common areas of
                                          Interest and make your buddy
                                          comfortable and leverage the
                                          discussion cues provided for each
                                          Session
                                        </li>
                                        <li className="text_short">
                                          Listen more than you talk. Ask
                                          questions.
                                        </li>
                                        <li className="text_short">
                                          Ask for help.
                                        </li>
                                      </ul>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="16">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What are the prizes ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We have exciting prizes lined up for you.
                                      You could win
                                      <ul>
                                        <li className="text_short">
                                          Gift coupons of $ 200 of brand of your
                                          choice
                                        </li>
                                        <li className="text_short">
                                          Recommendation letter
                                        </li>
                                        <li className="text_short">
                                          Internships
                                        </li>
                                        <li className="text_short">
                                          Exclusive virtual meeting Indra Nooyi
                                        </li>
                                        <li className="text_short">
                                          Name / photo on social media feeds
                                        </li>
                                      </ul>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="17">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What if my child does not receive his
                                      prize ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Your child can send their complaint to our
                                      team through the chat facility available
                                      on the app.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="18">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Who will decide if there is a tie break ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      There will be a panel of judges along with
                                      the founder
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="19">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What happens after the contest ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Participants can continue interacting with
                                      their new friend on the app. Remember,
                                      they are playing a role in building an
                                      inclusive world.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="20">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What if there are technical issues ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Participants can send their complaints to
                                      our team through the chat facility
                                      available on the app.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="21">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What if my child wants to drop out ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We hope your child stays with us. In case
                                      you do want to drop out send us an email
                                      at friends@genwe.today and we will
                                      deactivate your account. All your personal
                                      information will be erased within 48
                                      hours.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </>
                            )}
                            <div className="d-flex justify-content-center align-items-center">
                              <button
                                className="custom_reach_button custom_viewMore"
                                onClick={() => {
                                  handleToggle();
                                }}
                              >
                                {toggleShow ? "View More" : "View Less"}
                              </button>
                            </div>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                {/* for parents ends */}
                {/* for school starts */}
                <Tab eventKey="forschool" title="For School">
                  <div className="container">
                    <div className="row">
                      <div className="row">
                        <div className="col-lg-12 gs_reveal gs_reveal_fromLeft">
                          <Accordion defaultActiveKey="0">
                            {toggleShow ? (
                              <>
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Is GenWE safe ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        {" "}
                                        Yes, GenWE is safe because –
                                        <ul>
                                          <li className="text_short">
                                            GenWE follows all the online data
                                            protection guidelines laid under
                                            GPDR, CCPA, IT Act, 2000, etc and
                                            has several safety measures built in
                                            like a 24/7 help feature, weekly
                                            check ins with pairs.
                                          </li>
                                          <li className="text_short">
                                            GenWE is not an open community.
                                            Every person who gets onto the GenWE
                                            platform is validated.{" "}
                                          </li>
                                          <li className="text_short">
                                            Each person ONLY interacts with
                                            their peer.
                                          </li>
                                          <li className="text_short">
                                            Net, GenWE is safer than any social
                                            media platform or gaming platform
                                            that young people use today.
                                          </li>
                                        </ul>
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Will you be sharing students’ data with
                                      anyone else ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      No, your students’ data is safe with us.
                                      We only use the data for the purpose of
                                      sending reminders, updates and better
                                      service..
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      What if my student is bullied ?
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We take misconduct very seriously. Any
                                      signs of abuse, cyberbullying, or
                                      inappropriate conduct should be reported
                                      to our team through the chat facility
                                      available on the app.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="3">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Some students will be 13 in 3 months – can
                                      he / she participate ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        Unfortunately, no. We will wait for them
                                        to turn 13 and they can participate next
                                        year.
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="4">
                                  <Accordion.Header>
                                    {" "}
                                    <span className="PhoneQues">
                                      Some students are 20 - can he / she
                                      participate ?
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        Unfortunately, no. This contest is for
                                        teenagers between the age of 13 to 19
                                        years old
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="5">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How will it benefit the students ?
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      It will help strengthen key skills like
                                      inclusion, empathy, relationship skills by
                                      pairing them with someone outside their
                                      social bubble. They will make a new friend
                                      from a different background, help build a
                                      more inclusive world and win prizes.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="6">
                                  <Accordion.Header>
                                    {" "}
                                    <span className="PhoneQues">
                                      {" "}
                                      How will the school benefit ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        <ul>
                                          <li>
                                            GenWE enables practicing and
                                            developing “leadership muscles’ by
                                            pairing students who come from
                                            different backgrounds. Students
                                            learn 6 leadership traits.{" "}
                                          </li>
                                          <li>
                                            GenWE supplements the school
                                            academic curriculum with an easy to
                                            implement unique model.{" "}
                                          </li>
                                          <li>
                                            Builds school reputation as a leader
                                            in UN SDG goals by developing future
                                            young inclusive leaders.
                                          </li>
                                          <li>
                                            <b>
                                              Top 20 school principals globally
                                              will interact with ex-PepsiCo CEO
                                              Indra Nooyi
                                            </b>{" "}
                                            through an exclusive zoom meeting
                                            and we will celebrate leaders like
                                            you on our website and social media
                                            handles.
                                          </li>
                                        </ul>
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How long will it run ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      The contest will be open for{" "}
                                      <b>
                                        6 weeks from July 01 2023 to August 15

                                        2023.
                                      </b>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </>
                            ) : (
                              <>
                                <Accordion.Item eventKey="8">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What is the contest for ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      GenWE’s mission is to bring the world
                                      closer through the younger generation. We
                                      pair young people from different
                                      backgrounds. This GenWE Unite the world
                                      challenge will help students step out of
                                      their social bubble and appreciate a new
                                      perspective. We believe each pair; each
                                      conversation can make a difference to
                                      making the world more inclusive.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How will students earn points ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Your student will earn points in the
                                      following ways –
                                      <ul>
                                        <li className="text_short">
                                          Every connect through the GenWE app
                                          has points
                                          <ul>
                                            <li className="text_short">
                                              Text – 5 points for every text
                                            </li>
                                            <li className="text_short">
                                              Phone call – 10 points for every
                                              second
                                            </li>
                                            <li className="text_short">
                                              Video call – 20 points for every
                                              second
                                            </li>
                                          </ul>
                                        </li>
                                        <li className="text_short">
                                          They will also earn bonus points for
                                          every activity they complete.
                                          Activities will be announced every
                                          week. They could win bonus points if
                                          the activity is a competition. This
                                          will be part of the announcement.
                                        </li>
                                      </ul>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Where can my student see their points ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Points can be seen in top right side of
                                      the home screen
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How will the studnets get paired ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We pair teenagers through a unique
                                      automated pairing process. The pairing is
                                      based on differences (country, culture,
                                      race, religion, socio-economic) and
                                      similarities (age, gender, hobbies).
                                      Another criterion for pairing that we try
                                      and match is opposite strengths so that
                                      the teens mutually benefit from each
                                      other.We have it covered.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How much time will the student have to
                                      spend ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      This is entirely up to each participant.
                                      The more they talk to their new friend,
                                      the more points they earn. We do not have
                                      a minimum or maximum time prescribed. We
                                      expect that 1 hour a week will help them
                                      be competitive with other pairs.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Can I pair students within the school ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Unfortunately, no. GenWE is about building
                                      relationships with people outside the
                                      social bubble.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="14">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What if a student does not get paired ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      As mentioned, the pairing is automated and
                                      there may be cases that someone does not
                                      get paired despite registration. This will
                                      happen if the criteria mentioned above is
                                      not met. Unfortunately, they will not be
                                      able to participate in the contest this
                                      year.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="15">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What do I do if my student’s interaction
                                      with their GenWE buddy does not go well ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We would encourage you to let your student
                                      work it out with their GenWE peer. We give
                                      the following guidance to the participants
                                      <ul>
                                        <li className="text_short">
                                          There is always some awkwardness and
                                          it may take up to 2-3 connects to
                                          break the ice. It’s always advisable
                                          for them to discuss with their peer to
                                          see how you they make progress.
                                        </li>
                                        <li className="text_short">
                                          We will also provide them with
                                          discussion cues before Every session -
                                          they can use these discussion cues to
                                          guide the conversation.
                                        </li>
                                      </ul>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="16">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What do I do if my students GenWE buddy
                                      doesn’t open up as much, or wants to
                                      interact less ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      It is important for students to learn this
                                      skill. We would encourage you to tell your
                                      students the following -
                                      <ul>
                                        <li className="text_short">
                                          Learning to interact with people
                                          different than you is the biggest
                                          learning. Remember, building trust
                                          takes time.
                                        </li>
                                        <li className="text_short">
                                          Look for common areas of Interest and
                                          make your buddy comfortable and
                                          leverage the discussion cues provided
                                          for each Session{" "}
                                        </li>
                                        <li className="text_short">
                                          Listen more than you talk. Ask
                                          questions.{" "}
                                        </li>
                                        <li className="text_short">
                                          Ask for help.{" "}
                                        </li>
                                      </ul>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="17">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What are the prizes ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We have exciting prizes lined up for you.
                                      You could win
                                      <ul>
                                        <li className="text_short">
                                          Gift coupons of $ 200 of brand of your
                                          choice
                                        </li>
                                        <li className="text_short">
                                          Recommendation letter
                                        </li>
                                        <li className="text_short">
                                          Internships
                                        </li>
                                        <li className="text_short">
                                          Exclusive virtual meeting Indra Nooyi
                                        </li>
                                        <li className="text_short">
                                          Name / photo on social media feeds
                                        </li>
                                      </ul>
                                      As a school the prizes are
                                      <ul>
                                        <li className="text_short">
                                          The top 20 school leaders will have an
                                          exclusive virtual meeting with Indra
                                          Nooyi
                                        </li>
                                        <li className="text_short">
                                          The top 50 school leaders will have
                                          their name / photo on social media
                                          feeds
                                        </li>
                                      </ul>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="18">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What happens after the contest ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Participants can continue interacting with
                                      their new friend on the app. Remember,
                                      they are playing a role in building an
                                      inclusive world.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="19">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What if there are technical issues ?
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Participants can send their complaints to
                                      our team through the chat facility
                                      available on the app.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="20">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What if the student wants to drop out ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We hope you stay with us. In case you do
                                      want to drop out send us an email at
                                      friends@genwe.today and we will deactivate
                                      your account. All your personal
                                      information will be erased within 48
                                      hours.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </>
                            )}
                            <div className="d-flex justify-content-center align-items-center">
                              <button
                                className="custom_reach_button custom_viewMore"
                                onClick={() => {
                                  handleToggle();
                                }}
                              >
                                {toggleShow ? "View More" : "View Less"}
                              </button>
                            </div>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                {/* for school ends */}
                {/* for companies starts */}
                <Tab eventKey="forcompanies" title="For Companies">
                  <div className="container">
                    <div className="row">
                      <div className="row">
                        <div className="col-lg-12 gs_reveal gs_reveal_fromLeft">
                          <Accordion defaultActiveKey="0">
                            {toggleShow ? (
                              <>
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Is GenWE safe ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        {" "}
                                        Yes, GenWE is safe because –
                                        <ul>
                                          <li className="text_short">
                                            GenWE follows all the online data
                                            protection guidelines laid under
                                            GPDR, CCPA, IT Act, 2000, etc and
                                            has several safety measures built in
                                            like a 24/7 help feature, weekly
                                            check ins with pairs.
                                          </li>
                                          <li className="text_short">
                                            GenWE is not an open community.
                                            Every person who gets onto the GenWE
                                            platform is validated.{" "}
                                          </li>
                                          <li className="text_short">
                                            Each person ONLY interacts with
                                            their peer.
                                          </li>
                                          <li className="text_short">
                                            Net, GenWE is safer than any social
                                            media platform or gaming platform
                                            that young people use today.
                                          </li>
                                        </ul>
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      Will you be sharing participant data with
                                      anyone else ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We only use the data for the purpose of
                                      sending reminders, updates and better
                                      service.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      What if participant is bullied ?
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      We take misconduct very seriously. Any
                                      signs of abuse, cyberbullying, or
                                      inappropriate conduct should be reported
                                      to our team through the chat facility
                                      available on the app.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="3">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How much time does the participant need to
                                      spend ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        This is entirely up to the participant.
                                        The more they talk to their new friend,
                                        the more points they earn. We do not
                                        have a minimum or maximum time
                                        prescribed. We expect that 1 hour a week
                                        will help them stay competitive with
                                        other pairs.
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="4">
                                  <Accordion.Header>
                                    {" "}
                                    <span className="PhoneQues">
                                      How will it benefit the employee’s child ?
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        They will make a new friend from a
                                        different background. It will help
                                        strengthen key skills like inclusion,
                                        empathy, relationship skills by pairing
                                        them with someone outside their social
                                        bubble. They will play a role in
                                        building a more inclusive world. And
                                        they could win attractive prizes
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="5">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How will the company benefit ?
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <ul>
                                        <li className="text_short">
                                          Employees are human beings and as a
                                          parent the biggest worry is the growth
                                          and development of our children.
                                        </li>
                                        <li className="text_short">
                                          GenWE helps companies with a new
                                          inclusion and engagement model by
                                          helping employees’ children become
                                          future leaders.
                                        </li>
                                        <li className="text_short">
                                          GenWE builds organizations reputation
                                          as a responsible leader by developing
                                          future young inclusive leaders and
                                          strengthens the “S” of ESG.
                                        </li>
                                        <li className="text_short">
                                          You will be supporting a global
                                          competition with a mission of doing
                                          good
                                        </li>
                                      </ul>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>{" "}
                                <Accordion.Item eventKey="6">
                                  <Accordion.Header>
                                    {" "}
                                    <span className="PhoneQues">
                                      {" "}
                                      What is the contest for ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      <span className="PhoneAns">
                                        GenWE’s mission is to bring the world
                                        closer through the younger generation.
                                        We pair young people from different
                                        backgrounds. This GenWE Unite the world
                                        challenge will help students step out of
                                        their social bubble and appreciate a new
                                        perspective. We believe each pair; each
                                        conversation can make a difference to
                                        making the world more inclusive.
                                      </span>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How long will it run ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      The contest will be open for{" "}
                                      <b>
                                        6 weeks from July 01 2023 to August 15

                                        2023.
                                      </b>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </>
                            ) : (
                              <>
                                <Accordion.Item eventKey="8">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      How will the employee’s child get paired ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {" "}
                                    <p className="text_short">
                                      We pair teenagers through a unique
                                      automated pairing process. The pairing is
                                      on the basis of differences (country,
                                      culture, race, religion, socio-economic)
                                      and similarities (age, gender, hobbies).
                                      Another criterion for pairing that we try
                                      and match is opposite strengths so that
                                      the teens mutually benefit from each
                                      other. Don’t worry about safety. We have
                                      it covered.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What if an employee’s child does not get
                                      paired ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      As mentioned, the pairing is automated and
                                      there may be cases that someone does not
                                      get paired despite registration. This will
                                      happen if the criteria mentioned above is
                                      not met. Unfortunately, they will not be
                                      able to participate in the contest this
                                      year.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What are the prizes ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      The learnings and the impact is the big
                                      prize. We also have exciting prizes lined
                                      up for the students -
                                      <ul>
                                        <li className="text_short">
                                          Gift coupons of $ 200 of brand of
                                          their choice{" "}
                                        </li>
                                        <li className="text_short">
                                          Recommendation letter{" "}
                                        </li>
                                        <li className="text_short">
                                          Internships
                                        </li>
                                        <li className="text_short">
                                          Exclusive virtual meeting with Indra
                                          Nooyi
                                        </li>
                                        <li className="text_short">
                                          Name / photo on social media feed
                                        </li>
                                      </ul>
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What happens after the contest ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Participants can continue interacting with
                                      their new friend on the app. Remember,
                                      they are playing a role in building an
                                      inclusive world.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                  <Accordion.Header>
                                    <span className="PhoneQues">
                                      {" "}
                                      What if there are any technical issues ?{" "}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text_short">
                                      Participants can send their complaints to
                                      our team through the chat facility
                                      available on the app.
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </>
                            )}
                            <div className="d-flex justify-content-center align-items-center">
                              <button
                                className="custom_reach_button custom_viewMore"
                                onClick={() => {
                                  handleToggle();
                                }}
                              >
                                {toggleShow ? "View More" : "View Less"}
                              </button>
                            </div>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                {/* for comapanies ends */}
              </Tabs>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Footer />
      </section>

      <div>
        <a href="https://link-to.app/genWE" target="_blanck" id="download_button_click">
          <button
            className="ReadOurStory custom_download_app"

          >
            Download the App
          </button>
        </a>
      </div>
    </>
  );
}

export default Contest;
