import React, { useState } from "react";
import "./Footer.css";
import FooterEmail from "../../Assets/Images/FooterEmail.png";
import Facebook from "../../Assets/Images/Footer/Facebook.png";
import Instagram from "../../Assets/Images/Footer/Instagram.png";
import Twitter from "../../Assets/Images/Footer/Twitter.png";
import mail from "../../Assets/Images/Footer/mail.png";

import Tiktok from "../../Assets/Images/Footer/Tiktok.png";
import LinkedIn from "../../Assets/Images/Footer/Linkedin.png";

import { Link } from "react-router-dom";
import pdf from "../../Assets/Images/Footer/parental-consent-form.pdf";
import ContactModel from "../../Components/ContactModel";

const Footer = () => {
  const [showContact, setShowContact] = useState(false);

  const openpopup = () => {
    setShowContact(true);
  };

  return (
    <div className="footer">
      {showContact ? <ContactModel setShowContact={setShowContact} /> : ""}
      <div className="container">
        <div className="FooterWraper">
          <div className="row">
            <div className="col-lg-4  col-sm-12 col-md-12 col-xl-4 col-xs-12">
              <div className="HelpSomething2 ">
                Want Our Help With Something?
              </div>

              <div className="col-xs-8 col-lg-8  col-sm-8 col-md-8 col-xl-8">
                <div className="FooterEmail ">
                  {/* <input type="text" className="form-control" placeholder="Reach Out" /> */}
                  <button
                    className="custom_reach_button d-flex"
                    onClick={openpopup}
                  >
                    Reach Out
                    {/* <span style={{background:'#fff',padding:'8px', borderRadius:'50%'}}>
                      <img
                        src={mail}
                        fill="white"
                        alt=""
                        className=""
                        height={20}
                      />
                    </span> */}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-2 col-sm-6 col-md-6 col-xs-6 d-none d-sm-block">
              <div className="PagesDiv">Pages</div>
              <div>
                <Link to="/Home">
                  {" "}
                  <div className="PagesDivList">Home</div>
                </Link>
                <Link to="/HowWeDo">
                  {" "}
                  <div className="PagesDivList">How WE do it?</div>
                </Link>
                <Link to="/MakeFriend">
                  {" "}
                  <div className="PagesDivList">New Friend</div>{" "}
                </Link>
                <Link to="/More">
                  {" "}
                  <div className="PagesDivList">New Skills</div>
                </Link>
                <Link to="/NewYou">
                  {" "}
                  <div className="PagesDivList">New You</div>
                </Link>
                <Link to="/TeensFeedback">
                  {" "}
                  <div className="PagesDivList">Teens Feedback</div>
                </Link>
                <Link to="/Partner">
                  {" "}
                  <div className="PagesDivList">Partner with us</div>
                </Link>
                {/* <Link to="/GenweArchitects">
                  {" "}
                  <div className="PagesDivList">GenWE Architects</div>
                </Link> */}
              </div>
              <Link to="/Story">
                {" "}
                <div className="PagesDivList">Our Story</div>
              </Link>
              <a href="https://blog.thegenwe.com/" target="_blank">
                {" "}
                <div className="PagesDivList">Blogs</div>
              </a>
              <Link to="/Faq">
                {" "}
                <div className="PagesDivList">FAQs</div>
              </Link>
            </div>
            <div className="col-lg-3 col-xs-6  col-sm-6 col-md-6 d-none d-sm-block">
              <div className="PagesDiv">Quick Links</div>
              <div>
                <Link to="/DeskTopLicenceAgreement" target="_blank">
                  {" "}
                  <div className="PagesDivList">
                    End User License Agreement
                  </div>{" "}
                </Link>
                <Link to="/DexTopPrivacyPolicy" target="_blank">
                  {" "}
                  <div className="PagesDivList">Privacy Policy</div>{" "}
                </Link>
                <Link to="/DexDisclaimer" target="_blank">
                  <div className="PagesDivList">Disclaimer</div>
                </Link>
                <Link to={pdf} target="_blank" rel="noreferrer">
                  <div className="PagesDivList">
                    Parental consent for Under 13
                  </div>
                </Link>
                <Link to="/DexContentPolicy" target="_blank">
                  <div className="PagesDivList">Content Policy</div>
                </Link>
                <Link to="/DeleteAccount" target="_blank">
                  <div className="PagesDivList">Delete Account</div>
                </Link>
                {/* <Link to="/DexContestTermsAndCondition" target="_blank"><div className="PagesDivList">Contest Terms & Conditions</div></Link> */}
              </div>
            </div>
            <div className="col-lg-2 d-none d-sm-block">
              <div className="PagesDiv">Contact Us</div>
              <div className="SocialNetwork">
                <span className="SocialNetworkone">
                  <a
                    href="https://www.tiktok.com/@genweapp?_t=8XEXfaek8ll&_r=1"
                    target="_blank"
                  >
                    <img src={Tiktok} />
                  </a>
                </span>
                <span className="SocialNetworkone">
                  <a
                    href="https://www.linkedin.com/company/genwe-inc/"
                    target="_blank"
                  >
                    <img src={LinkedIn} />
                  </a>
                </span>
                <span className="SocialNetworkone">
                  <a href="https://www.instagram.com/genweapp/" target="_blank">
                    <img src={Instagram} />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-block d-sm-none">
          <div className="row">
            <div className="col-5">
              <div className="PagesDiv">Pages</div>
              <div>
                <Link to="/Home">
                  {" "}
                  <div className="PagesDivList">Home</div>
                </Link>
                <Link to="/HowWeDo">
                  {" "}
                  <div className="PagesDivList">How WE do it?</div>
                </Link>
                <Link to="/MakeFriend">
                  {" "}
                  <div className="PagesDivList">New Friend</div>{" "}
                </Link>
                <Link to="/More">
                  {" "}
                  <div className="PagesDivList">New Skills</div>
                </Link>
                <Link to="/NewYou">
                  {" "}
                  <div className="PagesDivList">New You</div>
                </Link>
                <Link to="/TeensFeedback">
                  {" "}
                  <div className="PagesDivList">Teens Feedback</div>
                </Link>
                <Link to="/Partner">
                  {" "}
                  <div className="PagesDivList">Partner with us</div>
                </Link>
                {/* <Link to="/GenweArchitects">
                  {" "}
                  <div className="PagesDivList">GenWE Architects</div>
                </Link> */}

                <Link to="/Story">
                  {" "}
                  <div className="PagesDivList">Our Story</div>
                </Link>
                <Link to="/Faq">
                  {" "}
                  <div className="PagesDivList">FAQs</div>
                </Link>
              </div>
            </div>
            <div className="col-7">
              <div className="PagesDiv">Quick Links</div>
              <div>
                <Link to="/LicenceAgreement" target="_blank">
                  {" "}
                  <div className="PagesDivList">
                    End User License Agreement
                  </div>{" "}
                </Link>
                <Link to="/PrivacyPolicy" target="_blank">
                  {" "}
                  <div className="PagesDivList">Privacy Policy</div>{" "}
                </Link>
                <Link to="/Disclaimer" target="_blank">
                  <div className="PagesDivList">Disclaimer</div>
                </Link>
                <Link to={pdf} target="_blank" rel="noreferrer">
                  <div className="PagesDivList">
                    Parental consent for Under 13
                  </div>
                </Link>
                <Link to="/ContentPolicy" target="_blank">
                  <div className="PagesDivList">Content Policy</div>
                </Link>
                {/* <Link to="/ContestTermsAndCondition" target="_blank"><div className="PagesDivList">Contest Terms & Conditions</div></Link> */}
              </div>
            </div>
            <div className="col-12">
              <div className="PagesDiv">Contact Us</div>
              <div className="SocialNetwork">
                {/* <span className="SocialNetworkone">
                  <a href="https://www.instagram.com/genweapp/" target="_blank">
                    <img src={Instagram} />
                  </a>
                </span>
                <span className="SocialNetworkone">
                  <img src={Facebook} />
                </span>
                <span className="SocialNetworkone">
                  <img src={Twitter} />
                </span> */}

                <span className="SocialNetworkone">
                  <a
                    href="https://www.tiktok.com/@genweapp?_t=8XEXfaek8ll&_r=1"
                    target="_blank"
                  >
                    <img src={Tiktok} />
                  </a>
                </span>
                <span className="SocialNetworkone">
                  <a
                    href="https://www.linkedin.com/company/genwe-inc/"
                    target="_blank"
                  >
                    <img src={LinkedIn} />
                  </a>
                </span>
                <span className="SocialNetworkone">
                  <a href="https://www.instagram.com/genweapp/" target="_blank">
                    <img src={Instagram} />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="Licence"> GenWE © 2020 | All Rights Reserved</div>
      </div>
    </div>
  );
};

export default Footer;
