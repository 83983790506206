import React, { useState, useEffect, useRef } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Licence.css";
import { useFormik } from "formik";
import emailjs from "@emailjs/browser";

import "./test.css";

const DeleteAccount = (props) => {
  const [loading, setLoading] = useState(false);
  const closeContactModel = () => {
    props.setShowContact(false);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
    } else if (values.name.length > 30) {
      errors.name = "Must be 30 characters or less";
    }
    if (!values.number) {
    } else if (values.number.length != 10) {
      errors.number = "Invalid Phone Number";
    }

    if (!values.email) {
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    return errors;
  };

  const trigger = (event) => {
    formik.handleSubmit(event);
    sendEmail(event);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      number: "",
      email: "",
      message: "",
    },
    validate,
    onSubmit: (values) => {
      setLoading(true);

      // props.setShowContact(false);
    },
  });
  // alert(JSON.stringify(values, null, 2));

  const form = useRef();
  const sendEmail = (event) => {
    setLoading(true);
    event.preventDefault();
    emailjs
      .sendForm(
        "service_ebaz2xn",
        "template_2debcwn",
        form.current,
        "n-783jatCM1piGQ3J"
      )
      .then(
        (result) => {
          alert("Your request submitted successfully!");
          setLoading(false);
          console.log(result.text);
          formik.resetForm();
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
          formik.resetForm();
        }
      );
  };

  return (
    <div>
      <div className="container MainTagSpace">
        <div>
          <Header />
          <div className="container">
            <div className="row">
              <div className=" col-lg-12 pt-4">
                <div className="pt-4 text-center">
                  {" "}
                  <h2> Delete Account</h2>{" "}
                </div>

                <div className="py-2">
                  <form
                    ref={form}
                    className="custom_contact_form"
                    id="form"
                    onSubmit={trigger}
                  >
                    <div className="form-group">
                      <label htmlFor="forname">
                        Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        placeholder="Enter Name"
                        required
                        max="10"
                      />
                      {formik.errors.name ? (
                        <div className="Custom_status">
                          {formik.errors.name}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label htmlFor="fornumber">
                        Number <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="number"
                        name="number"
                        onChange={formik.handleChange}
                        value={formik.values.number}
                        placeholder="Enter Number"
                        required
                      />
                      {formik.errors.number ? (
                        <div className="Custom_status">
                          {formik.errors.number}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label htmlFor="foremail">
                        Email Address <span>*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        placeholder="Enter Email"
                        required
                      />
                      {formik.errors.email ? (
                        <div className="Custom_status">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label htmlFor="formessage">
                        Message <span>*</span>
                      </label>
                      <textarea
                        className="form-control"
                        rows="3"
                        cols="40"
                        id="message"
                        name="message"
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        required
                      >
                        {" "}
                      </textarea>
                    </div>

                    <button
                      style={{ width: "45%" }}
                      type="submit"
                      className="btn btn-primary custom_form_subbmit"
                    >
                      {loading ? "Loading...." : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="FooterMargin">
        {" "}
        <Footer />
      </div>
    </div>
  );
};

export default DeleteAccount;
