import React, { useState, useEffect, useRef } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import HowWeone from "../../Assets/Images/HowWeDo/HowWeone.png";
import HowWeTwo from "../../Assets/Images/HowWeDo/HowWeTwo.png";
import perspective from "../../Assets/Images/HowWeDo/perspective.png";
import skill from "../../Assets/Images/HowWeDo/skill.png";
import awarness from "../../Assets/Images/HowWeDo/awarness.png";
import Download from "../../Assets/Images/HowWeDo/download1.png";
import Account from "../../Assets/Images/HowWeDo/Account.png";
import Friend from "../../Assets/Images/HowWeDo/Friend.png";
import EnterWorld from "../../Assets/Images/HowWeDo/EnterWorld.png";
import Chat from "../../Assets/Images/HowWeDo/Chat.png";
import "./HowWeDo.css";
// import genWeVideo from '../../Assets/Video/GenWE-Explainer-Short-Version-4K.mp4'

import "../../Assets/css/style.css";
import "../responsive.css";
import HowWeMob from "../../Assets/Images/HowWeDo/HowWeMob.png";
import Spring from "../../Assets/Images/HowWeDo/spring.png";
import Paperplane from "../../Assets/Images/HowWeDo/paperplane.png";
import Waves from "../../Assets/Images/HowWeDo/waves.png";
import DownloadJourneyString from "../../Assets/Images/HowWeDo/DownloadJourneyString.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Reveal, Tween, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";
import Video2 from "../../Components/video2";
import { Typography } from "@material-ui/core";
gsap.registerPlugin(ScrollTrigger);

function HowWeDo() {
  useEffect((elem) => {
    // window.scrollTo(0, 0);
    // gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        once: true,
        onEnter: function () {
          animateFrom(elem);
        },
        // onEnterBack: function () {
        //   animateFrom(elem, -1);
        // },
        // onLeave: function () {
        //   hide(elem);
        // }, // assure that the element is hidden when scrolled into view
      });
    });
  }, []);

  function animateFrom(elem, direction) {
    console.log("Animation Effects");
    direction = direction || 1;
    var x = 0,
      y = direction * 100;
    if (elem.classList.contains("gs_reveal_fromLeft")) {
      x = -100;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
      x = 100;
      y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 2.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Helmet>
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-MZ2V2SV5D7"
        />
        <script>
          {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
         
           gtag('config', 'G-MZ2V2SV5D7');
          `}
        </script> */}
        <title>How WE Do It | GenWE</title>
      </Helmet>
      <section className="header">
        <div className="container">
          <Header />
        </div>
      </section>

      <section className="GenWe_way_section">
        <div className="row custom_ralative_moba">
          <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-xs-4">
            <div className="howWeOne">
              <img src={HowWeone} className="img-fluid" />
            </div>
          </div>
          <div className="col-lg-5 col-xl-5 col-md-4 col-sm-4 ">
            <div className="HowWeOneText">
              <div className="title_container title gs_reveal gs_reveal_fromLeft custom_left_spacing">
                <span className="How">How</span> <span className="we">WE</span>{" "}
                Do It ?
              </div>
              <img
                src={Spring}
                alt=""
                className="SpringElement img-fluid d-none d-xs-block d-lg-block"
              />
              <img
                src={Spring}
                alt=""
                className="csutom_reponsive_image img-fluid display_All_none display_block"
              />

              <div className="HowWeOneText2 gs_reveal gs_reveal_fromRight">
                The Gen<span className="we">WE</span> Way!
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 d-none d-sm-block ">
            <div>
              <img src={HowWeTwo} className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3 col-xs-12">
              <div className="  gs_reveal gs_reveal_fromLeft custom_align_center">
                <img src={perspective} className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-9 col-sm-9 col-md-9 col-xs-12">
              <div className="perspective gs_reveal gs_reveal_fromRight">
                <div>
                  <h2 className="small_title text_pink">
                    {" "}
                    1. Building perspective
                  </h2>
                </div>
                <div className="text_short">
                  We pair you online with another young person from a different
                  background so you get to know and help each other in hobbies
                  and life skills.{" "}
                  <strong>
                    {" "}
                    You will both learn inclusion, empathy and relationship
                    skills.
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-9 col-sm-9 col-xl-9 order_2 text-right">
              <div className="perspective gs_reveal gs_reveal_fromLeft">
                <div>
                  <h2 className="small_title text_blue custom_text_left">
                    {" "}
                    2. Building skills
                  </h2>
                </div>
                <div className="text_short custom_text_left">
                  We invite expert guest speakers on key topics like{" "}
                  <strong>self-confidence, problem solving, values etc.</strong>{" "}
                  This will help you to learn{" "}
                  <strong> leadership skills and life skills.</strong>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-xl-3 col-md-3 col-sm-3 order_1">
              <div className=" gs_reveal gs_reveal_fromLeft custom_align_center">
                <img className="img-fluid" src={skill} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Building_awareness">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
              <div className=" gs_reveal gs_reveal_fromLeft custom_align_center">
                <img src={awarness} className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-9 col-sm-9 col-md-9 col-xl-9">
              <div className="perspective gs_reveal gs_reveal_fromRight">
                <div>
                  <h2 className="small_title text_pink">
                    3. Building awareness
                  </h2>
                </div>
                <div>
                  <p className="text_short">
                    We incentivize GenWE pairs to brainstorm about real world
                    issues through mini projects based on{" "}
                    <strong> UN Sustainable Development Goals.</strong>
                    You will become more{" "}
                    <strong>
                      {" "}
                      socially aware, develop a broader perspective and a
                      problem solver.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="App_Section text-center-mobile">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-xs-12">
              <div className=" text-center-mobile Sub_title display_flex">
                <img
                  src={Paperplane}
                  className="img-fluid d-none d-lg-block"
                  alt=""
                />
                <div>
                  <div className="ampersand">
                    Start Your Journey With{" "}
                    <span className="Gen custom_gen"> Gen</span>
                    <span className="we custom_we">WE&nbsp;&nbsp;</span>
                  </div>
                  <div>
                    <Typography
                      style={{
                        fontSize: "1.3rem",
                        letterSpacing: "0",
                        textAlign: "left",
                        margin: 0,
                      }}
                      variant="h6"
                    >
                      {" "}
                      Earn GenWE points, get access to internships,
                      certificates, build your resume and a whole lot more.
                    </Typography>
                  </div>
                </div>
                <img
                  src={Waves}
                  className="img-fluid d-none d-lg-block"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-xs-12 text-center-mobile">
              <div className="Download">
                <div className="col-xs-12">
                  <div className="stepsMainDiv">
                    <div className="Download  gs_reveal gs_reveal_fromLeft">
                      <img src={Download} className="img-fluid" />
                    </div>
                  </div>
                  <div className="DownloadText gs_reveal gs_reveal_fromLeft text-center-mobile">
                    <div className="DownText1">Step 1: Download The App</div>
                    <div className="DownText2 ">
                      Download the app for free. Complete ID check process.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-xs-12">
              <div className="col-xs-12">
                <div className="stepsMainDiv">
                  <div className="Account  gs_reveal gs_reveal_fromLeft">
                    <img src={Account} className="img-fluid" />
                  </div>
                </div>
              </div>

              <div className="DownloadText gs_reveal gs_reveal_fromLeft">
                <div className="DownText1 PhoneMarge">
                  Step 2: Create your account
                </div>
                <div className="DownText2 ">
                  Fill your name, age, gender, interests and social impact areas
                  you are passionate about
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="Friends">
                <div className="col-xs-12">
                  <div className="stepsMainDiv">
                    <div className="Friend  gs_reveal gs_reveal_fromLeft">
                      <img src={Friend} className="img-fluid" />
                    </div>
                  </div>

                  <div className="DownloadText gs_reveal gs_reveal_fromLeft">
                    <div className="DownText1 PhoneMarge">
                      Step 3: Make a friend!
                    </div>
                    <div className="DownText2  d-none d-sm-block">
                      The algorithm will automatically pair you with a same
                      gender match to fit your profile
                    </div>
                  </div>
                </div>
                <div className="DownText2 d-block d-sm-none gs_reveal gs_reveal_fromLeft">
                  The algorithm will automatically pair you with a same gender
                  match to fit your profile
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="Friends">
                <div className="col-xs-12">
                  <div className="stepsMainDiv">
                    <div className=" Friend1  gs_reveal gs_reveal_fromLeft">
                      <img src={EnterWorld} className="img-fluid" />
                    </div>
                  </div>

                  <div className="DownloadText gs_reveal gs_reveal_fromLeft">
                    <div className="DownText1 PhoneMarge">
                      Step 4: Enter the world of GenWE!
                    </div>
                    <div className="DownText2  d-block ">
                      Have real conversations with your buddy, with no
                      judgement, no filter. Learn valuable life skills from
                      experts. Discover the new you!
                    </div>
                  </div>
                </div>
                {/* <div className="DownText2 d-block d-sm-none gs_reveal gs_reveal_fromLeft">
                  The algorithm will automatically pair you with a same gender
                  match to fit your profile
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3 my-5">
          <div className="text-center">
            <Button className="ViewJourney" onClick={() => setModalShow(true)}>
              View full journey
            </Button>
          </div>
        </div>
      </section>

      <Modal
        // {...props}
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Video2 />
        </Modal.Body>
      </Modal>
      <section>
        <Footer />
      </section>

      <div>
        <a
          href="https://link-to.app/genWE"
          target="_blanck"
          id="download_button_click"
        >
          <button className="ReadOurStory custom_download_app">
            Download the App
          </button>
        </a>
      </div>
    </>
  );
}

export default HowWeDo;
