import "./App.css";
import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Pages/Home/Home2";

import About from "./Pages/About";
import Users from "./Pages/Users";
import HowWeDo from "./Pages/HowWeDo/HowWeDo2";
import MakeFriend from "./Pages/MakeFriend/MakeFriend.js";
import Partner from "./Pages/Partner/Partner.js";
import Story from "./Pages/Story/Story.js";
import Faq from "./Pages/Faq/Faq";
import More from "./Pages/More/More";
import Menu from "./Pages/Menu/Menu";
import Disclaimer from "./Pages/QuickLinks/Disclaimer";
import LicenceAgreement from "./Pages/QuickLinks/LicenceAgreement";
import ParentalConsent from "./Pages/QuickLinks/ParentalConsent";
import PrivacyPolicy from "./Pages/QuickLinks/PrivacyPolicy";
import GenweArchitects from "./Pages/GenweArchitects/GenweArchitects";
import HomeWithPreVideo from "./Pages/HomeWithPreVideo/HomeWithPreVideo";

import ContactModel from "./Components/ContactModel";
import DeskTopLicenceAgreement from "./Pages/QuickLinks/DeskTopLicenceAgreement";
import DexTopPrivacyPolicy from "./Pages/QuickLinks/DexTopPrivacyPolicy";
// import preloaderimg from "./Assets/Images/preloader/preloaderfikaa22.gif";
import Contest from "./Pages/Contest/Contest";
import ContestTermsAndCondition from "./Pages/QuickLinks/ContestTermsAndCondition";
import ContestPolicy from "./Pages/QuickLinks/ContentPolicy";
import ContentPolicy from "./Pages/QuickLinks/ContentPolicy";
import DexContentPolicy from "./Pages/QuickLinks/DexContentPolicy";
import DexDisclaimer from "./Pages/QuickLinks/DexDisclaimer";
import DexContestTermsAndCondition from "./Pages/QuickLinks/DexContestTermsAndCondition";
import NewYou from "./Pages/NewYou/NewYou.js";
import TeensFeedback from "./Pages/TeensFeedback/TeensFeedback.js";
// importing aos
import Aos from "aos";
import "aos/dist/aos.css";
import DeleteAccount from "./Pages/QuickLinks/DeleteAccount.js";
const PreloaderContext = React.createContext(false);

function App() {
  const [showPreVideo, setShowPreVideo] = useState(false);

  useEffect(() => {
    Aos.init({
      offset: window.innerWidth <= 767 ? 120 : 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 150,
      once: true,
    });
  }, []);

  useEffect(() => {
    setShowPreVideo(true);
  }, []);

  return (
    // <PreloaderContext.Provider value={true}>
    <Router>
      <div className="App">
        <Routes>
          <Route
            exact
            path="/"
            element={<HomeWithPreVideo showPreVideo={showPreVideo} />}
          ></Route>
          <Route
            exact
            path="/Home"
            element={<Home showPreVideo={showPreVideo} />}
          ></Route>
          <Route exact path="/about" element={<About />}></Route>
          <Route exact path="/Users" element={<Users />}></Route>
          <Route exact path="/HowWeDo" element={<HowWeDo />}></Route>
          <Route exact path="/MakeFriend" element={<MakeFriend />}></Route>
          <Route exact path="/More" element={<More />}></Route>
          <Route exact path="/Partner" element={<Partner />}></Route>
          <Route exact path="/Story" element={<Story />}></Route>
          <Route exact path="/Faq" element={<Faq />}></Route>
          <Route exact path="Menu/" element={<Menu />}></Route>
          <Route exact path="/Disclaimer" element={<Disclaimer />}></Route>
          <Route
            exact
            path="/GenweArchitects"
            element={<GenweArchitects />}
          ></Route>
          <Route exact path="/ContactModel" element={<ContactModel />}></Route>
          <Route exact path="/NewYou" element={<NewYou />}></Route>
          <Route
            exact
            path="/TeensFeedback"
            element={<TeensFeedback />}
          ></Route>
          {/* <Route exact path="/Contest" element={<Contest />}></Route> */}

          <Route
            exact
            path="/LicenceAgreement"
            element={<LicenceAgreement />}
          ></Route>
          <Route
            exact
            path="/DeskTopLicenceAgreement"
            element={<DeskTopLicenceAgreement />}
          ></Route>
          <Route
            exact
            path="/ParentalConsent"
            element={<ParentalConsent />}
          ></Route>
          <Route
            exact
            path="/PrivacyPolicy"
            element={<PrivacyPolicy />}
          ></Route>
          <Route
            exact
            path="/DexTopPrivacyPolicy"
            element={<DexTopPrivacyPolicy />}
          ></Route>
          <Route
            exact
            path="/ContestTermsAndCondition"
            element={<ContestTermsAndCondition />}
          ></Route>
          <Route
            exact
            path="/ContentPolicy"
            element={<ContentPolicy />}
          ></Route>
          <Route
            exact
            path="/DexContentPolicy"
            element={<DexContentPolicy />}
          ></Route>
          <Route
            exact
            path="/DexDisclaimer"
            element={<DexDisclaimer />}
          ></Route>
          <Route
            exact
            path="/DexContestTermsAndCondition"
            element={<DexContestTermsAndCondition />}
          ></Route>
          <Route
            exact
            path="/Deleteaccount"
            element={<DeleteAccount />}
          ></Route>
        </Routes>
      </div>
    </Router>
    // </PreloaderContext.Provider>
  );
}

export default App;
