import React, { useEffect } from "react";
import Logo from "../../Assets/Images/Logo1.png";
import HeadPhone from "../../Assets/Images/HeadPhone.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import "./Header.css"

const Header = () => {

  const location = useLocation();
  console.log(location, "---> Location")

  useEffect(() => {
    console.log('here')
    scrollToTop()
  }, [location.pathname]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div>
      <div className="d-flex justify-content-between HidePosition">

        <Link to="/Home">
          {" "}
          <div className="d-none d-sm-block" >
            <img height={100} width={120} src={Logo} />
          </div>
          <div className="d-block d-sm-none">
            <img width={90} height={60} src={Logo} />
          </div>
        </Link>

        <div className="d-block d-sm-none">
          <Link to="/Menu">
            <div className="pt-3">
              <img width={35} height={30} src={HeadPhone}></img>
            </div>
          </Link>
        </div>

        <div className="contest_manu_button">
          {/* <div className="d-none d-sm-block custom_header_position mx-4">
            <Link to="/Contest">
              {" "}
              <div className="ContestMenu">
               <a href="#" id="contest_button"><button className="joincontest"  >Contest Alert!</button></a> 
              </div>{" "}
            </Link>
          </div> */}
          <div className="d-none d-sm-block custom_header_position">
            <Link to="/Menu">
              {" "}
              <div className="HeadMenu">
                <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faBars} />
                <span >MENU</span>
              </div>{" "}
            </Link>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Header;
