import React, { useState } from "react";
import "./Menu.css";
import Close from "../../Assets/Images/Menu/Close.png";
import bigPicture from "../../Assets/Images/Menu/bigPicture.png";
import { Link } from "react-router-dom";

import MenuItem from "@mui/material/MenuItem";
import { Dropdown } from "react-bootstrap";
import { Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import { ListItemButton } from "@mui/material";

const Menu = () => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  return (
    <div className="Background">
      <div
        className="container"
        style={{
          paddingTop: "60px",
        }}
      >
        <div className="row">
          <div className="col-lg-1 col-lg-1  col-md-1  custom_verticle_manu_word">
            <div className="custom_verticle_line"></div>
            <div className=" custom_manu_verticle">MENU</div>
            <div className="custom_verticle_line"></div>
          </div>
          <div className="col-lg-11 col-md-11">
            <div className="row custom_position_relative">
              <div className="col-lg-10"></div>
              <div className="col-lg-2">
                <Link to="/Home">
                  {" "}
                  <div className="CloseBtn">
                    <img src={Close} />{" "}
                  </div>{" "}
                </Link>
              </div>
              <div className="col-lg-12 menu_background">
                <Link to="/Home">
                  {" "}
                  <div className="Items">Home</div>
                </Link>
                <Link to="/HowWeDo">
                  {" "}
                  <div className="Items">How WE do it?</div>{" "}
                </Link>
                <Link to="/MakeFriend">
                  <div className="Items">
                    {/* <ListItemText primary={"New Friends"}/> */}
                    New Friend
                  </div>
                </Link>
                <Link to="/More">
                  <div className="Items">
                    {/* <ListItemText primary={"New Skills"}/> */}
                    New Skills
                  </div>
                </Link>
                <Link to="/NewYou">
                  <div className="Items">
                    {/* <ListItemText primary={"New You"}/> */}
                    New You
                  </div>
                </Link>
                {/* <div className="Items">
                  <span
                    style={{ cursor: "pointer", fontFamily: "Ampersand" }}
                    onClick={() => {
                      setOpenSubMenu(!openSubMenu);
                    }}
                  >
                    How do teens benefit?
                  </span>
                  <Collapse in={openSubMenu}>
                    <List
                      style={{
                        color: "white",
                        fontFamily: "Ampersand",
                        fontSize: "1.7rem",
                      }}
                    >
                      
                      
                      
                    </List>
                  </Collapse>
                </div> */}
                <Link to="/TeensFeedback">
                  {" "}
                  <div className="Items">Teens Feedback</div>{" "}
                </Link>
                <Link to="/Partner">
                  {" "}
                  <div className="Items">Partner with us</div>{" "}
                </Link>
                {/* <Link to="/GenweArchitects">
                  {" "}
                  <div className="Items">GenWE Architects</div>{" "}
                </Link> */}
                <Link to="/Story">
                  {" "}
                  <div className="Items">Our Story</div>{" "}
                </Link>

                <a href="https://blog.thegenwe.com/" target="_blank">
                  {" "}
                  <div className="Items">Blogs</div>
                </a>

                <Link to="/Faq">
                  {" "}
                  <div className="Items">FAQs</div>{" "}
                </Link>

                {/* <Link
                  to="/Contest"
                  className="d-xl-none d-lg-none d-md-none d-sm-none"
                >
                  {" "}
                  <div className="ContestMenu ">
                  <a href="#" id="contest_button"> <button className="joincontest custom_responsive_contest_button">Contest Alert!</button></a>
                  </div>{" "}
                </Link> */}
              </div>

              <div className="col-lg-8">
                <div className="MenuPad"></div>
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
