import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./NewYou.css";
import MakeFriendPic from "../../Assets/Images/MakeFriend/MakeFriendPic.png";
import ThreeDot from "../../Assets/Images/MakeFriend/ThreeDot.png";
import IntrectPhoto from "../../Assets/Images/MakeFriend/IntrectPhoto.png";
import BenefitsImages from "../../Assets/Images/MakeFriend/BenefitsImage.png";
import BenImg from "../../Assets/Images/MakeFriend/BenImg.png";

import { Reveal, Tween, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";
gsap.registerPlugin(ScrollTrigger);

function NewYou() {
  useEffect((elem) => {
    // window.scrollTo(0, 0);
    // gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        once: true,
        onEnter: function () {
          animateFrom(elem);
        },

        // assure that the element is hidden when scrolled into view
      });
    });
  }, []);

  function animateFrom(elem, direction) {
    console.log("Animation Effects");
    direction = direction || 1;
    var x = 0,
      y = direction * 100;
    if (elem.classList.contains("gs_reveal_fromLeft")) {
      x = -100;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
      x = 100;
      y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 2.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }
  return (
    <>
      <Helmet>
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-MZ2V2SV5D7"
        />
        <script>
          {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
         
           gtag('config', 'G-MZ2V2SV5D7');
          `}
        </script> */}
        <title>New You | GenWE</title>

      </Helmet>
      <section className="header">
        <div className="container">
          <Header />
        </div>
      </section>
      {/* <section>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 gs_reveal gs_reveal_fromLeft"
              id="tab_view_friend_1"
            >
              <div className="MakeFriend csutom_noPadding">
                <h1 className="title csutom_noPadding">Make a new Friend</h1>
              </div>
              <div className="MakeFriendPic">
                <img src={MakeFriendPic} className="img-fluid" />
              </div>

              <div className="home_intro text-center">
                We believe similarities are the glue and differences create fun
                and dialogue that adds to your learning. We pair you based on
                both.
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section>
        <div className="container">
          <div className="row">
            <div className="IntrectHeading text-left">
              <div className=" gs_reveal gs_reveal_fromLeft">
                <span className="HowTo">
                  <h1 className="title">
                    {" "}
                    <img src={ThreeDot} className="img-fluid three_dot_img" /> &nbsp;Connect, Share & Learn New Perspectives
                  </h1>
                </span>
              </div>
            </div>
            <div
              className="col-lg-8 col-sm-12 col-md-12 col-xl-8 order_2 "
              id="tab_view_friend_3"
            >

              <div className="HowInterectTextX">
                <div className="HowInterectTextMin gs_reveal gs_reveal_fromLeft">
                  <div className="dotDiv">
                    <div className="dot"></div>
                  </div>
                  <div className="  text_short">
                    <strong className="custom_color_pink_text"> Connect once a week </strong>for an hour with your buddy from a different city, country or background. Via video calling, call or text.

                  </div>
                </div>
                <div className="HowInterectTextMin gs_reveal gs_reveal_fromLeft">
                  <div className="dotDiv">
                    {" "}
                    <div className="dot"></div>
                  </div>
                  <div className="  text_short">
                    <strong className="custom_color_orange_text"> Participate in a new activity </strong>every two weeks - guest speakers and team games. Keep a lookout on your home screen



                  </div>
                </div>
                <div className="HowInterectTextMin gs_reveal gs_reveal_fromLeft">
                  <div className="dotDiv">
                    {" "}
                    <div className="dot"></div>
                  </div>
                  <div className="  text_short">
                    <strong className="custom_color_blue_text"> Get points for every text, video or call</strong>. And additional points for attending guest speaker sessions and answering questions.
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4  gs_reveal gs_reveal_fromRight interact_section_img order_1"
              id="tab_view_friend_2"
            >
              <div className="">
                <img src={IntrectPhoto} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      
      <section className="" id="tab_view_friend_4">
        <div className="col-lg-12 ">
          <div className="animation_benefits">
            <marquee behavior="scroll" direction="left" scrollamount="8">
              <h1 className="Benefits_animation"> Benefits of GenWE Benefits of GenWE Benefits of GenWE Benefits of GenWE Benefits of GenWE</h1>
            </marquee>
          </div>
          <div className="title  Benefits_title">
            Benefits of <span className="Gen">Gen</span>
            <span className="we custom_we">WE</span>
          </div>
        </div>
        <div className="container">
          <div className="row">

            <div
              className="col-lg-5 BenefitImagesection"
              id="tab_view_friend_5"
            >
              <div className="BenefitImageDiv  gs_reveal gs_reveal_fromRight">
                <img src={BenefitsImages} className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6" id="tab_view_friend_6">
              <div className="BenefitsBold text_short gs_reveal gs_reveal_fromLeft">
              As a GenWE community member, you will help build a more inclusive world by making a new friend from a different community, culture, country or background.
              </div>
              <div className="benPoints">
                <div className="benPointsMain gs_reveal gs_reveal_fromLeft">
                  <div className="benPointOne">01</div>
                  <div className=" text_short">
                  You will develop new perspective through a deep meaningful friendship, free from views and judgement.
                  </div>
                </div>
                <div className="benPointsMain gs_reveal gs_reveal_fromLeft">
                  <div className="benPointOne">02</div>
                  <div className="text_short">
                  You will learn real-life skills like relationship building, handling pressure, confidence building.
                  </div>
                </div>
                <div className="benPointsMain gs_reveal gs_reveal_fromLeft">
                  <div className="benPointOne">03</div>
                  <div className="text_short">
                  You will earn GenWE points and get access to internships, certificates, expert sessions, build your resume and a whole lot more.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Genwe_points_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-9" id="tab_view_friend_7">
              <div className="title gs_reveal gs_reveal_fromLeft text-left">
                Earn <span className="Gen">Gen</span>
                <span className="we custom_we">WE</span> points.
              </div>
              <div className="App_available gs_reveal gs_reveal_fromLeft">
              Get access to internships, certificates, exclusive expert sessions and much more !
              </div>
              <div className="genWePointsTwo">
                <div className="genWePointsThree gs_reveal gs_reveal_fromLeft">
                  <span className=" text_short csutom_display_flex">
                    {/* <span className="genWePointsPoints">● </span>  */}
                    <div className="dotDiv">
                      {" "}
                      <div className="dot custom_dots_orange"></div>
                    </div>

                    <div className="text_short">
                      GenWE measures
                      how you learn and contribute in 3 areas –
                      <span className=" genWeBold text_short App_available">
                        {" "}
                        Building perspective, building skills and building
                        awareness.{" "}
                      </span>
                    </div>

                  </span>
                </div>

                <div className="genWePointsThree gs_reveal gs_reveal_fromLeft">
                  <span className=" text_short text_short csutom_display_flex">
                    <div className="dotDiv">
                      {" "}
                      <div className="dot custom_dots_orange"></div>
                    </div>
                    <div className="text_short"> 
                      You
                      earn points for VDO calls with your new friend. You also get
                      points when you call or text each other.
                      <span className=" genWeBold text_short">
                        {" "}
                        The more often you connect with your friend, exchange
                        ideas and learn from them the more GenWE points you get.{" "}
                      </span>
                      Isn’t that awesome! We will check in, on how you two are
                      connecting.
                    </div>
                  </span>

                </div>

                <div className="genWePointsThree gs_reveal gs_reveal_fromLeft">
                  <span className=" text_short csutom_display_flex">
                    <div className="dotDiv">
                      {" "}
                      <div className="dot custom_dots_orange"></div>
                    </div>
                    <div className="text_short">
                      <span className=" genWeBold text_short">
                        {" "}
                        We also have points for the guest speaker sessions.{" "}
                      </span>{" "}
                      All you have to do is attend the session and participate. We
                      will ask you questions at the end of the session and you get
                      points for both participation and getting the right answers.
                    </div>
                  </span>
                </div>

                <div className="genWePointsThree gs_reveal gs_reveal_fromLeft">
                  <span className=" text_short csutom_display_flex">
                    <div className="dotDiv">
                      {" "}
                      <div className="dot custom_dots_orange"></div>
                    </div>
                    <div>
                      <span className=" genWeBold  text_short">
                        You will also earn bonus points when you complete a
                        project, participate in a poll, refer a friend, win a game
                        and for many more surprises.
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-none d-sm-block " id="tab_view_friend_8">
              <div className="BenImg">
                <img src={BenImg} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
      <div>
        <a href="https://link-to.app/genWE" target="_blanck" id="download_button_click">
          <button className="ReadOurStory custom_download_app" >
            Download the App
          </button>
        </a>
      </div>
    </>
  );
}

export default NewYou;
